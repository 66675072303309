import React, { useEffect, useState } from 'react'
import { I18n } from '@aws-amplify/core';
import { find, get } from 'lodash';
import { useSelector } from 'react-redux';
import { BigNumber } from "bignumber.js";

import { decimalPrecision, country as countrySelector, isActiveNoIvaDay, isTaxesResponsable as isTaxesResponsableSelector, companySelector, decimalSeparator as decimalSeparatorSelector } from '../../../selectors/company'
import { calculateSingleItemValues, calculateSingleItemValuesWithDecimals } from '../../../utils';
import { allProductUnits } from '../../../selectors/units';
import taxTypeByPercentage from '../../countriesData/republicaDominicana/taxes'
import { assingITBISTaxesToLetter, getColombiaTaxCode, getDefaultCurrencyValue, getSpainTaxCode } from './utilities';
import { useCheckTaxesSummaryAvailability } from '../../../hooks/featureAvailability/useCheckTaxesSummaryAvailability';
import useDecimalsVersionsGroup from '../../../hooks/useDecimalsVersionsGroup/hook';
import { getItem } from '../../../database/itemsDB';

const renderNoIvaDayMessage = item => {
  return get(item, 'applyNoIvaDays', '') === 'yes'
    ? `- ${I18n.get('wellCovered', 'bien cubierto')} -`
    : null
}

const Items = ({ invoice, setting: { unitPrice, description, template, printDemo = false, unitOfMeasure, totalLines, printItemFullLine, taxesIncludedInTotal }, isMulticurrencySell, currentDefaultCurrency }) => {
  const decimal = useSelector(decimalPrecision);
  const decimalSeparator = useSelector(decimalSeparatorSelector);
  const country = useSelector(countrySelector)
  const isIvaDay = useSelector(isActiveNoIvaDay)
  const company = useSelector(companySelector);
  const allUnits = useSelector(allProductUnits);
  const isElectronic = get(invoice, 'numberTemplate.isElectronic', false) || get(invoice, 'numeration.isElectronic', false);
  const { items, currency, stamp } = invoice;
  const isTaxesResponsable = useSelector(isTaxesResponsableSelector);
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const isTaxesSummaryAvailable = useCheckTaxesSummaryAvailability();

  const [localItems, setLocalItems] = useState([]);

  useEffect(() => {
    const fetchLocalItems = async () => {
      const fetchedItems = await Promise.all(
        items.map(async (item) => {
          if (!item?.id) {
            return item;
          }

          try {
            const localItem = await getItem(parseInt(item.id));
            if (localItem) {
              return {
                ...item,
                productKey: get(localItem, 'productKey', null),
              };
            }
          } catch (error) {
            console.log(error);
          }

          return item;
        })
      );
      setLocalItems(fetchedItems);
    };

    fetchLocalItems();
  }, [items]);

  const originalPriceItems = items.map(item => {
    const originalPrice = getDefaultCurrencyValue(invoice.currency, item.price);
    const originalTotal = getDefaultCurrencyValue(invoice.currency, item.total);

    return {
      ...item,
      price: originalPrice,
      total: originalTotal,
    }
  });

  const prefix = !!get(currency, 'symbol')
    ? get(currency, 'symbol') : null;

  const fmt = prefix ? {
    prefix,
    decimalSeparator,
    groupSeparator: decimalSeparator === "," ? "." : ",",
    groupSize: 3,
  } : {
    decimalSeparator,
    groupSeparator: decimalSeparator === "," ? "." : ",",
    groupSize: 3,
  };

  const customTaxTypeByPercentage = country === 'republicaDominicana' ? assingITBISTaxesToLetter(items) : {};

  if (!items) return null;
  
  if (country === 'republicaDominicana' && isElectronic) return republicaDominicanaItemsSection({ items, template, decimal, fmt, printDemo, description, taxesIncludedInTotal, customTaxTypeByPercentage, allUnits, country, company, isDecimalActive})
  if (printItemFullLine) return newItemsTemplateDesing({ items, decimal, fmt, printDemo, country, template, unitPrice, unitOfMeasure, totalLines, description, taxesIncludedInTotal, isElectronic, isTaxesSummaryAvailable, isTaxesResponsable, customTaxTypeByPercentage, allUnits, company, localItems, isDecimalActive })

  return (
    <div className={`w-100 py-2 d-flex flex-column justify-content-center align-items-center ${template !== 'simple' ? 'border-top' : ''}`}>

      {items.map((item, index) => {
        const localItem = localItems.find(localItem => localItem?.id == item?.id);
        const { price, discount, discountValue, total, taxValue, quantity } =
          isDecimalActive
            ? calculateSingleItemValuesWithDecimals(item, decimal, true)
            : calculateSingleItemValues(item, decimal, true);
        const { total: originalTotal, taxValue: originalTaxValue } =
          isDecimalActive
            ? calculateSingleItemValuesWithDecimals(
              originalPriceItems[index],
              decimal,
              true
            )
            : calculateSingleItemValues(
              originalPriceItems[index],
              decimal,
              true
            );

        const currentUnitItem = allUnits.find((i) => i?.key === get(item, 'unit', get(item, 'inventory.unit', '')));
        const hasITBISTax = country === 'republicaDominicana' && !!find(get(item, 'tax', []), tax => tax.type === 'ITBIS');
        const hasNoTaxes = get(item, 'tax', []).length === 0;
        const taxType = find(get(item, 'tax', []), tax => tax.percentage === 0) || hasNoTaxes
          ? "E"
          : hasITBISTax && ![18, 16, 0].includes(Number(get(find(get(item, 'tax', []), tax => tax.type === 'ITBIS'), 'percentage', 1)))
            ? get(customTaxTypeByPercentage, Number(get(find(get(item, 'tax', []), tax => tax.type === 'ITBIS'), 'percentage', 18)))
            : get(taxTypeByPercentage, Number(get(find(get(item, 'tax', []), tax => tax.type === 'ITBIS'), 'percentage', 18)));
        const productUnitOfMeasure = get(item, 'unit', get(item, 'inventory.unit', null)) ? get(item, 'unit', get(item, 'inventory.unit', null)) : 'UND';

        const getUnitPrice = () => {
          if (country === 'spain') {
            let result = price;

            if (!taxValue.isZero()) {
              result = result.plus(taxValue.div(quantity));
            }

            if (!discountValue.isZero()) {
              result = result.minus(discountValue.div(quantity));
            }

            return result.toFormat(decimal, fmt);
          }
          return price.toFormat(decimal, fmt);
        };

        return (
          <div key={index} className="w-100 d-flex flex-wrap form-row items-table mb-2">
            {country === 'colombia' && !isMulticurrencySell && totalLines && <p className="col-1 break-all">{index + 1}</p>}
            <p className={`${(!!unitPrice && !isMulticurrencySell) ? 'col-3' : 'col-5'} text-left break-all`}>
              {get(item, 'name', '')}{hasITBISTax || taxType === 'E' ? ` (${taxType})` : ''}
              {!printDemo && (
                <>
                  {country !== 'colombia' && (!!item && !!currentUnitItem && unitOfMeasure && !!get(currentUnitItem, 'value', '')) && (
                    <>
                      <br />
                      <span className="col-1 break-all">{`(${get(currentUnitItem, 'value', '')})`}</span >
                    </>
                  )}
                  {country === 'colombia' && (isElectronic || unitOfMeasure) && (!!item && !!currentUnitItem && !!get(currentUnitItem, 'value', '')) && (
                    <>
                      <br />
                      <span className="col-1 break-all">{`(${get(currentUnitItem, 'value', '')})`}</span >
                    </>
                  )}
                  {country === 'colombia' && !isMulticurrencySell && !!item && (!!get(item, 'productKey', '') || !!get(localItem, 'productKey', null)) && (
                    <>
                      <br />
                      <span className="col-1 break-all">{(get(item, 'productKey', '') || get(localItem, 'productKey', null))}</span >
                    </>
                  )}
                </>
              )}
              {
                printDemo && (
                  <>
                    {!!item && unitOfMeasure && !!get(item, 'unit', '') && (
                      <>
                        <br />
                        <span className="col-1 break-all">{`(${I18n.get(productUnitOfMeasure)})`}</span >
                      </>
                    )}
                    {country === 'colombia' && !!item && (!!get(item, 'productKey', '') || !!get(localItem, 'productKey', null)) && (
                      <>
                        <br />
                        <span className="col-1 break-all">{(get(item, 'productKey', '') || get(localItem, 'productKey', null))}</span >
                      </>
                    )}
                  </>
                )
              }
            </p>

            <p className={`text-center break-all ${(!!unitPrice && !isMulticurrencySell) ? "col-1" : "col-2"}`}>{+get(item, 'quantity', 0)}</p>
            {!!unitPrice && !isMulticurrencySell && (
              <p className="col-3 text-center break-all">
                {country === 'republicaDominicana' && taxesIncludedInTotal
                  ? price.plus(taxValue.toNumber() / +get(item, 'quantity', 0)).toFormat(decimal, fmt)
                  : (country === 'colombia' && isTaxesSummaryAvailable)
                    ? price.plus(taxValue.toNumber() / +get(item, 'quantity', 0)).toFormat(decimal, fmt)
                    : getUnitPrice()}
              </p>
            )}
            <div className={`flex-column ${(country === 'colombia' && isTaxesSummaryAvailable && !isMulticurrencySell) ? 'col-3' : 'col-4'} text-center break-all`}>
              {isMulticurrencySell && (
                <p>
                  {taxesIncludedInTotal
                    ? `${originalTotal.toFormat(decimal, fmt)} ${currentDefaultCurrency?.code}`
                    : `${originalTotal.minus(originalTaxValue.toNumber()).toFormat(decimal, fmt)} ${currentDefaultCurrency?.code}`}
                </p>
              )}
              <p>
                {taxesIncludedInTotal
                  ? `${total.toFormat(decimal, fmt)} ${invoice?.currency?.code ?? ''}`
                  : `${total.minus(taxValue.toNumber()).toFormat(decimal, fmt)} ${invoice?.currency?.code ?? ''}`}
              </p>
            </div>

            {(country === 'colombia' && isTaxesSummaryAvailable && isTaxesResponsable) && (
              <p className={`col-1 text-center break-all`}>
                {get(item, 'tax', []).length === 0
                  ? <span>{getColombiaTaxCode().itemTaxCode}</span>
                  : get(item, 'tax').map((tax, index) => <span key={index}>{getColombiaTaxCode(tax).itemTaxCode}</span>)
                }
              </p>
            )}

            {(country === 'spain') && (
              <p className={`col-1 text-center break-all`}>
                {get(item, 'tax', []).length === 0
                  ? <span>{getSpainTaxCode().itemTaxCode}</span>
                  : get(item, 'tax').map((tax, index) => <span key={index} className='mr-1'>{getSpainTaxCode(tax).itemTaxCode}</span>)
                }
              </p>
            )}

            {!!unitPrice && country === 'panama' && !!stamp && taxValue > 0 && (
              <>
                <p className="col-5 text-center break-all" />
                <p className="col-3 text-center break-all">
                  {taxValue.toFormat(decimal, fmt)}
                </p>
                <p className="col-3 text-center break-all" />
              </>
            )}


            {description && (!isMulticurrencySell) && !!get(item, 'description', '') && <p className="text-left break-all col-12">{get(item, 'description')}</p>}

            {isIvaDay && !!renderNoIvaDayMessage(item) && (
              <p className="text-left break-all col-12">{renderNoIvaDayMessage(item)}</p>
            )}

            {discount > 0 && (
              <>
                {country === 'colombia' && <p className="col-1 break-all" />}
                <p className={`${!!unitPrice ? 'col-3' : 'col-5'} text-left text-nowrap text-capitalize-first`}>
                  {`${I18n.get('discount', 'descuento')} ${discount}%`}
                </p>
                <p className="col-2" />
                <p className={`${!!unitPrice ? 'col-3' : 'd-none'}`} />
                <p className={`${!!unitPrice ? 'col-3' : 'col-4'} text-center break-all`}>
                  {`-${new BigNumber(discountValue).toFormat(decimal, fmt)}`}
                </p>
              </>
            )}
          </div>
        )
      }
      )}
    </div>
  )
};

const newItemsTemplateDesing = ({ items, decimal, fmt, printDemo, country, template, unitPrice, unitOfMeasure, totalLines, description, taxesIncludedInTotal, isElectronic, isTaxesSummaryAvailable, isTaxesResponsable, customTaxTypeByPercentage, allUnits, company, localItems, isDecimalActive }) => (
  <div className={`w-100 py-2 d-flex flex-column justify-content-center align-items-center ${template !== 'simple' ? 'border-top' : ''}`}>
    <div className="w-100 d-flex flex-wrap form-row items-table mb-2">
      <p className="text-left break-all col-8 font-weight-bold">Item</p>
      <p className="text-center break-all col-3 font-weight-bold">Total</p>
      <p className="col-1" />
    </div>

    {items.map((item, index) => {
      const { quantity, discount, discountValue, price, taxValue, discSubtotal } = isDecimalActive ? calculateSingleItemValuesWithDecimals(item, decimal, true) : calculateSingleItemValues(item, decimal, true);
      const hasITBISTax = country === 'republicaDominicana' && !!find(get(item, 'tax', []), tax => tax.type === 'ITBIS');
      const taxType = find(get(item, 'tax', []), tax => tax.type === 'EXENTO')
        ? "E"
        : hasITBISTax && ![18, 16, 0].includes(Number(get(find(get(item, 'tax', []), tax => tax.type === 'ITBIS'), 'percentage', 1)))
          ? get(customTaxTypeByPercentage, Number(get(find(get(item, 'tax', []), tax => tax.type === 'ITBIS'), 'percentage', 18)))
          : get(taxTypeByPercentage, Number(get(find(get(item, 'tax', []), tax => tax.type === 'ITBIS'), 'percentage', 18)));
      const productUnitOfMeasure = get(item, 'unit', get(item, 'inventory.unit', null)) ? get(item, 'unit', get(item, 'inventory.unit', '')) : 'UND';
      const currentUnitItem = allUnits.find((i) => i?.key === get(item, 'unit', get(item, 'inventory.unit', '')));
      const localItem = localItems.find(localItem => localItem.id == get(item, 'id'));
      return (
        <div key={index} className="w-100 d-flex flex-wrap form-row items-table mb-2">
          {country === 'colombia' && !totalLines && <b className="col-1 break-all">{index + 1}.</b>}
          <div className={`col-${country === 'colombia' && !totalLines ? '7' : '8'}`}>
            <p className="m-0">{get(item, 'name', '')} {country === 'colombia' && (!!get(item, 'productKey') || !!get(localItem, 'productKey', null)) && `(${(get(item, 'productKey', '') || get(localItem, 'productKey', ''))})`}</p>
            <p className="m-0">
              {!printDemo && (
                <b>{quantity} {(isElectronic && country === 'republicaDominicana') || unitOfMeasure
                  ? !!get(currentUnitItem, 'value', '') && currentUnitItem.value
                  : ''}</b>
              )}
              {printDemo && (
                <b>{quantity} {(isElectronic && country === 'republicaDominicana') || unitOfMeasure
                  ? productUnitOfMeasure
                  : ''}</b>
              )}

              {(unitPrice || (isElectronic && country === 'republicaDominicana')) && <span>{" "}x {country === 'republicaDominicana' && taxesIncludedInTotal
                ? price.plus(taxValue.toNumber() / +get(item, 'quantity', 0)).toFormat(decimal, fmt)
                : price.toFormat(decimal, fmt)}</span>}
              {discount !== 0 && (
                <span>{" "}(-{discount}%)</span>
              )}
            </p>
            {country === 'republicaDominicana' && (
              <p className="m-0">{get(item, 'tax[0].type') === "EXENTO" ? <>EXENTO (E)</>
                : hasITBISTax ? <>ITBIS {get(item, 'tax[0].percentage')}% {taxType === "E" ? "E" : `(${taxType})`}</> : ''}</p>
            )}
          </div>
          <p className="text-center break-all col-3">
            <p className="m-0">
              {country === 'republicaDominicana' && taxesIncludedInTotal
                ? discSubtotal.plus(taxValue.toNumber()).toFormat(decimal, fmt)
                : discSubtotal.toFormat(decimal, fmt)}
            </p>
            <p className="m-0">
              {discountValue.toNumber() !== 0 ? (
                <>-{discountValue.toFormat(decimal, fmt)}</>
              ) : <br />}
            </p>
            {country === 'republicaDominicana' && (
              <p className="m-0">
                {hasITBISTax ? taxValue.toFormat(decimal, fmt) : ''}
              </p>
            )}
          </p>
          {(country === 'colombia' && isTaxesSummaryAvailable && isTaxesResponsable) && (
            <p className={`col-1 text-center break-all`}>
              {get(item, 'tax', []).length === 0
                ? <span>{getColombiaTaxCode().itemTaxCode}</span>
                : get(item, 'tax').map((tax, index) => <span key={index}>{getColombiaTaxCode(tax).itemTaxCode}</span>)
              }
            </p>
          )}
          {(country === 'spain') && (
            <p className={`col-1 text-center break-all`}>
              {get(item, 'tax', []).length === 0
                ? <span>{getSpainTaxCode().itemTaxCode}</span>
                : get(item, 'tax').map((tax, index) => <span key={index} className='mr-1'>{getSpainTaxCode(tax).itemTaxCode}</span>)
              }
            </p>
          )}
          {description && !!get(item, 'description', '') && <p className="text-left break-all col-12">{get(item, 'description')}</p>}
        </div>
      )
    })}
  </div >
)

const republicaDominicanaItemsSection = ({ items, template, decimal, fmt, printDemo, description, taxesIncludedInTotal, customTaxTypeByPercentage, allUnits, country, company, isDecimalActive }) => (
  <div className={`w-100 py-2 d-flex flex-column justify-content-center align-items-center ${template !== 'simple' ? 'border-top-dashed' : ''}`}>
    <div className="w-100 d-flex flex-wrap form-row DOM-items-table mb-3">
      <p className="col-4 text-left break-all font-weight-bold">
        {I18n.get('name', 'Nombre')} <br />
        {I18n.get('price', 'Precio')}
      </p>
      <p className="col-5 text-left break-all font-weight-bold pl-4">
        {I18n.get('value', 'Valor')} <br />
        ITBIS
      </p>
      <p className="col-3 text-left font-weight-bold">
        {I18n.get('quantity', 'Cantidad')} <br />
        ud. medida
      </p>
    </div>

    <div className="w-100 border-top-dashed mb-2" />

    {items.map((item, index) => {
      const { discount, discountValue, price, quantity, taxValue, subtotal } = isDecimalActive ? calculateSingleItemValuesWithDecimals(item, decimal, true) : calculateSingleItemValues(item, decimal, true);
      const productUnitOfMeasure = get(item, 'unit', get(item, 'inventory.unit', null)) ? get(item, 'unit', get(item, 'inventory.unit', '')) : 'UND';
      const currentUnitItem = allUnits.find((i) => i?.key === get(item, 'unit', get(item, 'inventory.unit', '')));
      const taxType = find(get(item, 'tax', []), tax => tax.percentage === 0)
        ? "E"
        : ![18, 16, 0].includes(Number(get(find(get(item, 'tax', []), tax => tax.type === 'ITBIS'), 'percentage', 1)))
          ? get(customTaxTypeByPercentage, Number(get(find(get(item, 'tax', []), tax => tax.type === 'ITBIS'), 'percentage', 18)))
          : get(taxTypeByPercentage, Number(get(find(get(item, 'tax', []), tax => tax.type === 'ITBIS'), 'percentage', 18)));


      return (
        <div key={index} className="w-100 d-flex flex-wrap form-row DOM-items-table mb-2">
          <p className="col-4 text-left break-all text-wrap">
            {get(item, 'name')} <br />
            {taxesIncludedInTotal ? price.plus(taxValue.toNumber() / quantity).toFormat(decimal, fmt) : price.toFormat(decimal, fmt)}
          </p>
          <p className="text-left break-all col-5 pl-4">
            {taxesIncludedInTotal ? subtotal.plus(taxValue.toNumber()).minus(discountValue).toFormat(decimal, fmt) : subtotal.minus(discountValue).toFormat(decimal, fmt)} <br />
            {taxType === "E" ? "E" : `${taxValue.toFormat(decimal, fmt)} (${taxType})`}
          </p>
          <p className="text-left break-all col-3">
            {quantity} <br />
            {!printDemo && !!get(currentUnitItem, 'value') && currentUnitItem.value}
            {printDemo && productUnitOfMeasure}
          </p>
          {discount > 0 && (
            <>
              <p className="col-4 text-left break-all text-capitalize-first">
                {`${I18n.get('discount', 'descuento')} ${discount}%`}
              </p>
              <p className="col-2" />
              <p className="col-2" />
              <p className="col-4 text-center break-all">
                {`-${new BigNumber(discountValue).toFormat(decimal, fmt)}`}
              </p>
            </>
          )}
          {description && !!get(item, 'description', '') && <p className="text-left break-all col-12">{get(item, 'description')}</p>}
        </div>
      )
    })}
  </div >
)

export default Items;
