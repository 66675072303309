import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { get, isArray, some } from 'lodash';

import { closeSideModal } from '../../../reducers/sideModals';
import {
  updateItem,
  itemReachedMinQuantity,
  singleItemReachedMinQuantityFromValues,
  getKitMaximumQuantity,
} from '../../../reducers/activeInvoice';
import {
  hasPermissionTo,
  permissionValue,
  languageSelector,
} from '../../../selectors/auth';
import {
  itemByIndex,
  currency as currencySelector,
} from '../../../selectors/activeInvoice';
import {
  decimalPrecision,
  country as countrySelector,
} from '../../../selectors/company';
import { toast, replaceAndParse } from '../../../utils';
import SideModal from '../../common/SideModal';
import Body from '../../forms/editItem/NewEditItem';
import { transform, validate } from '../../forms/editItem/utils';
import Header from '../common/NewHeader';
import Notification from '../common/Notification';
import Bottom from '../common/Bottom';
import Summary from './NewSummary';
import initialValues from './InitialValues';
import ItemInfo from './ItemInfo';
import { isItemFromStation } from '../../../utils/items';
import { station as stationSelector } from '../../../selectors/app';
import { refresh } from '../../../reducers/items';
import * as itemsDB from '../../../database/itemsDB';
import alegraAPI from '../../../reducers/alegraAPI';
import { handleError } from '../../../utils/errors';

const checkPermissions = (form, can, permission) => {
  const priceState = form.getFieldState('price');
  const discountState = form.getFieldState('discount');

  if (!!priceState && !get(priceState, 'pristine')) {
    if (!can('edit-items-prices', 'invoices'))
      return I18n.get(
        'userNotAllowed.invoices.edit-items-prices',
        'no tienes permisos para editar el precio de los productos'
      );
  }
  if (!!discountState && !get(discountState, 'pristine')) {
    if (!can('edit-discount', 'invoices'))
      return I18n.get(
        'userNotAllowed.invoices.edit-discount',
        'no tienes permisos para editar el descuento de los productos'
      );

    if (
      +permission('max-discount-allow', 'invoices') <
      +get(discountState, 'value', 0)
    )
      return (
        I18n.get(
          'userNotAllowed.invoices.max-discount-allow',
          'el porcentaje máximo de descuento permitido para tu usuario es',
          true
        ) + ` ${permission('max-discount-allow', 'invoices')}`
      );
  }
  return null;
};

const NewEditItem = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const permission = useSelector(permissionValue);
  const isOpen = useSelector((state) =>
    get(state, 'sideModals.editItem.isOpen', false)
  );
  const itemIndex = useSelector((state) =>
    get(state, 'sideModals.editItem.params.index', false)
  );
  const item = useSelector(itemByIndex(itemIndex));
  const decimal = useSelector(decimalPrecision);
  const country = useSelector(countrySelector);
  const currency = useSelector(currencySelector);
  const language = useSelector(languageSelector);
  const station = useSelector(stationSelector);

  const updateProductKey = async (values) => {
    try {
      const response = await alegraAPI.put(`/items/${item.id}`, {
        productKey: get(values, 'newProductKey.key', ''),
      });
      values.productKey = get(values, 'newProductKey.key', '');
      const newItem = get(response, 'data', null);

      const { idWarehouse } = station;
      if (isItemFromStation(newItem, idWarehouse)) {
        await itemsDB.update(newItem.id, newItem);
        dispatch(refresh());
      }

      toast.success({
        title: `${I18n.get(
          'itemUpdatedSuccessfully',
          'producto actualizado con éxito'
        )} 🎯`,
      });
      return values;
    } catch (error) {
      toast.error({
        subtitle: handleError(
          error,
          I18n.get('updateItemError', 'hubo un error actualizando el producto')
        ),
      });
      return;
    }
  };

  const submit = async (values) => {
    if (country === 'costaRica' && get(values, 'newProductKey'))
      values = await updateProductKey(values);
    const modifiedValues = transform(values, {
      country,
      initialValues: initialValues(item, decimal),
      currency,
      decimal,
      newProductKey: get(values, 'newProductKey', null),
    });

    if (
      await dispatch(
        itemReachedMinQuantity({...values, previousQuantity: item?.quantity}, singleItemReachedMinQuantityFromValues)
      )
    ) {
      switch (get(values, 'type', 'product')) {
        case 'product':
          toast.warning({
            title: I18n.get(
              'itemLimitWarningTitle',
              'Ya vendiste todas las unidades. 🏁'
            ),
            subtitle: I18n.get(
              'itemLimitWarningSubtitle',
              'Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo'
            ),
          });
          break;
        case 'kit':
          const maxQuantity = await dispatch(getKitMaximumQuantity(values));
          toast.warning({
            title: replaceAndParse(
              I18n.get(
                'itemLimitWarningTitle.kitAvailable',
                '¡Puedes vender máximo {}! ✋'
              ),
              [
                `${maxQuantity} ${
                  maxQuantity > 1
                    ? I18n.get('kits', 'combosT')
                    : I18n.get('kit', 'combo')
                }`,
              ]
            ),
            subtitle: replaceAndParse(
              I18n.get(
                'itemLimitWarningSubtitle.kitAvailable',
                'Ten en cuenta que tu Combo "{}" contiene productos con ese número de unidades disponibles.'
              ),
              [
                `<span class="font-weight-bold">${get(
                  values,
                  'name',
                  ''
                )}</span>`,
              ]
            ),
          });
          break;
        default:
          toast.warning({
            title: I18n.get(
              'itemLimitWarningTitle',
              'Ya vendiste todas las unidades. 🏁'
            ),
            subtitle: I18n.get(
              'itemLimitWarningSubtitle',
              'Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo'
            ),
          });
          break;
      }
      return;
    }

    try {
      dispatch(updateItem({ index: itemIndex, values: modifiedValues }));
      dispatch(closeSideModal({ sideModal: 'editItem' }));
    } catch {
      return {
        [FORM_ERROR]: I18n.get(
          'editItemError',
          'hubo un error en la edición del producto'
        ),
      };
    }
  };

  const handleClose = () => {
    dispatch(closeSideModal({ sideModal: 'editItem' }));
  };

  return (
    <SideModal open={isOpen} onRequestClose={handleClose} destroyOnClose newest>
      <Form
        onSubmit={submit}
        initialValues={initialValues(item, decimal, language)}
        validate={(values) => validate(values, country)}
      >
        {({ handleSubmit, values, form, submitting, submitError }) => {
          values.tax = isArray(values.tax) ? values.tax : [values.tax];

          return (
            <form
              noValidate
              onSubmit={handleSubmit}
              className='new-edit-item-form w-100 h-100 bg-white d-flex flex-column'
            >
              <Header
                title={I18n.get('editSell', 'editar venta')}
                onClose={handleClose}
              />

              <ItemInfo
                item={{...values, previousQuantity: item?.quantity }}
                index={itemIndex}
                handleClose={handleClose}
              />

              <Body values={{...values, previousQuantity: item?.quantity }} form={form} />

              <Summary values={values} />

              <Notification isOpen={!!submitError} text={submitError} />

              <Bottom
                onClose={handleClose}
                disabled={
                  some(values?.decimalsError, (value) => value) || submitting || !!checkPermissions(form, can, permission)
                }
                submitting={submitting}
                hideRequired
              />
            </form>
          );
        }}
      </Form>
    </SideModal>
  );
};

export default NewEditItem;
