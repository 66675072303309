import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash'
import { Link } from 'react-router-dom';

import { setTypeToPrint } from '../../../reducers/print';
import { country as countrySelector, currency } from '../../../selectors/company'
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';

import { Icon } from '@alegradev/smile-ui-react';
import Button from '../../common/Button';
import Typography from '../../common/Typography';
import useConnectionStatus from '../../../hooks/useConnectionStatus/hook';
import { useApiAvailable } from '../../../hooks/useApiAvailable';
import useOfflineStatusAvailable from '../../../hooks/useOfflineStatusAvailable/hook';
import { IconPrinter } from '@tabler/icons-react';

const BottomInvoiceSaved = ({ handleClose }) => {
  const dispatch = useDispatch();
  const { isVersionAvailable } = useOfflineStatusAvailable()
  const isOnline = useConnectionStatus();
  const isApiAvailable = useApiAvailable();
  const invoiceCreated = useSelector(state => get(state, 'print.invoice', null));
  const country = useSelector(countrySelector);
  const companyCurrency = useSelector(currency);

  const panamaCondition = !!invoiceCreated && country === 'panama' && !!get(invoiceCreated, 'numberTemplate.isElectronic') && !get(invoiceCreated, 'stamp');

  if (panamaCondition)
    return (
      <div className="modal__bottom w-100 p-3 d-flex justify-content-between align-items-center shadow">
        <Link
          to="/invoices"
          className="btn btn-cancel text-capitalize-first px-4  d-flex align-items-center justify-content-center"
          onClick={handleClose}
        >
          {I18n.get('goToSales', 'Ir a ventas')}
        </Link>

        <button
          type="button"
          data-testid="new-invoice-btn"
          className="btn btn-submit text-capitalize-first"
          onClick={handleClose}
        >
          {I18n.get('newSale', 'nueva venta')}
        </button>
      </div>
    )

  const handlePrint = () => {
    dispatch(setTypeToPrint('invoice'))
    dispatch(sendGTMEvent('invce-printed', {
      isElectronicInvoice: get(invoiceCreated, 'numberTemplate.isElectronic', false),
      customerPaymentMethod: get(invoiceCreated, 'paymentMethod'),
      currency: get(invoiceCreated, 'currency.code', get(companyCurrency, 'code')),
      invoiceTotal: get(invoiceCreated, 'total'),
      totalItems: get(invoiceCreated, 'items').length,
    }))

    dispatch(
      sendNewGTMEvent('pos-invoice-shared', {
        medium: 'print',
      })
    );
  }

  if(isVersionAvailable && (!isOnline || !isApiAvailable)) {
    return (
      <div className='d-flex justify-content-end gap-3 mt-3'>
        {!panamaCondition && (
          <Button
            size='md'
            buttonType='default'
            variant='outline'
            onClick={() => handlePrint()}
          >
            <Icon icon={IconPrinter} className='icon-medium' />
            <Typography type='label-1' text={I18n.get('print', 'Imprimir')} />
          </Button>
        )}
        <Button
          size='md'
          buttonType='default'
          variant='filled'
          data-testid="new-invoice-btn"
          onClick={() => handleClose()}
        >
          <Typography
            type='label-1'
            text={I18n.get('newSale', 'Nueva venta')}
          />
        </Button>
      </div>
    );
  }

  return (
    <div className="modal__bottom w-100 p-3 d-flex justify-content-between align-items-center shadow">
      <button
        type="button"
        className="btn btn-cancel text-capitalize-first"
        onClick={() => handlePrint()}
      >
        <Icon icon={IconPrinter} extraClass="icon-secondary" />
        <span className="text-capitalize">{I18n.get('print', 'imprimir')}</span>
      </button>

      <button
        type="button"
        data-testid="new-invoice-btn"
        className="btn btn-submit text-capitalize-first"
        onClick={handleClose}
      >
        {I18n.get('newSale', 'nueva venta')}
      </button>
    </div>
  )
}

export default BottomInvoiceSaved;