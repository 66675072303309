import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash'

import { openModal } from '../../reducers/modals';
import { hasPermissionTo } from '../../selectors/auth';
import { companySelector, country as countrySelector } from '../../selectors/company';
import { isUserElegibleForNewRefund } from './utils';
import { Tooltip } from '@alegradev/smile-ui-react';
import { modalParamsByCountry } from './hooks/useRefundActions';

const NoSelectedElement = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo)
  const country = useSelector(countrySelector)
  const company = useSelector(companySelector)

  const openModalRefunds = () => {
    const params = modalParamsByCountry[country] || {};
    let modal = 'refunds'
    if (isUserElegibleForNewRefund(country, company))
      modal = 'newRefunds'
    dispatch(openModal({ modal, params }))
  }

  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <div className="p-4 text-center">
        <p className="text-capitalize-first">
          {I18n.get('noSelectedRefund', 'selecciona devoluciones de la lista para ver los detalles. O crea una nueva')}{' '}
          <a className="text-capitalize-first btn-link" href="https://ayuda.alegra.com/crea-devoluciones-de-tus-productos-desde-el-pos-de-alegra" target="_blank" rel="noreferrer">
            {capitalize(I18n.get('seeMore', 'ver más'))}
          </a>
        </p>

        <Tooltip
          visible={!can('add', 'credit-notes')}
          overlay={I18n.get('userNotAllowed.credit-notes.add', 'no tienes permisos para agregar devoluciones')}
        >
          <button
            type="button"
            disabled={!can('add', 'credit-notes')}
            className="btn btn-submit"
            onClick={openModalRefunds}
          >
            {I18n.get('newRefund', 'nueva devolución')}
          </button>
        </Tooltip>
      </div>
    </div>
  )
}

export default NoSelectedElement;