import React, { useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get, capitalize, isEmpty } from 'lodash';

import * as mutations from '../../../graphql/mutations';
import { toast } from '../../../utils';
import { formError } from '../../../utils/errors';
import { stationTransformer } from '../../../utils/transformers';
import { updateStation } from '../../../reducers/app';
import * as stationSelectors from '../../../selectors/app';
import { hasPermissionTo } from '../../../selectors/auth';
import { isOnlyInvoicingPlan } from '../../../selectors/company';
import {
  isCashReceiptNumerationActive,
  isRefundNumerationActive,
} from '../../../selectors/numerations';
import { validate, transform } from '../../forms/station/utils';
import Header from '../common/Header';
import Bottom from '../common/Bottom';
import Body from './FormBody';

const Station = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan);
  const history = useHistory();
  const cashReceiptActive = useSelector(isCashReceiptNumerationActive);
  const refundActive = useSelector(isRefundNumerationActive);

  const station = useSelector(stationSelectors.station);
  const numberTemplate = useSelector(stationSelectors.stationInvoiceNumeration);
  const feNumberTemplate = useSelector(
    stationSelectors.stationFENumberTemplate
  );
  const invoiceNumberTemplate = useSelector(
    stationSelectors.stationInvoiceNumberNumeration
  );
  const receiptNumberTemplate = useSelector(
    stationSelectors.stationCashReceiptNumeration
  );
  const refundNumberTemplate = useSelector(
    stationSelectors.stationRefundNumeration
  );
  const debit = useSelector(stationSelectors.stationDebitBank);
  const credit = useSelector(stationSelectors.stationCreditBank);
  const transfer = useSelector(stationSelectors.stationTransferBank);
  const cash = useSelector(stationSelectors.stationCashBank);
  const baseBank = useSelector(stationSelectors.stationBaseBank);
  const closeBank = useSelector(stationSelectors.stationCloseBank);
  const warehouse = useSelector(stationSelectors.stationWarehouse);
  const costCenter = useSelector(stationSelectors.stationCostCenter);
  const seller = useSelector(stationSelectors.stationSeller);
  const users = useSelector(stationSelectors.stationUsers);
  const priceList = useSelector(stationSelectors.stationPriceList);
  const APIGraphql = useSelector(stationSelectors.APIGraphqlSelector);


  useEffect(() => {
    window.dataLayer.push({
      event: 'VirtualPageview',
      virtualPageURL: '/settings/station',
      virtualPageTitle: 'Current Station Settings',
    });
  }, []);

  const submit = async (values) => {
    const transformedValues = transform(values);

    try {
      const response = await APIGraphql(
        graphqlOperation(mutations.updateStation, {
          station: stationTransformer({ ...station, ...transformedValues }),
        })
      );

      const updatedStation = get(response, 'data.updateStation');
      if (!!updatedStation) dispatch(updateStation(updatedStation));
      toast.success({
        title: I18n.get('stationUpdated', 'terminal actualizada'),
      });
      history.push('/settings');
    } catch (error) {
      toast.error({
        title: formError(
          error,
          I18n.get(
            'updateStationError',
            'hubo un error al cambiar los datos de la terminal'
          )
        ),
      });
      return formError(
        error,
        I18n.get(
          'updateStationError',
          'hubo un error al cambiar los datos de la terminal'
        )
      );
    }
  };

  return (
    <div className='container p-5'>
      <div className='d-flex flex-column'>
        <Form
          onSubmit={submit}
          validate={(values) =>
            validate(values, { cashReceiptActive, refundActive })
          }
          initialValues={{
            name: station.name,
            pendingInvoicesEnabled: !onlyInvoicing
              ? station.pendingInvoicesEnabled
              : false,
            pendingInvoicesPrefix: station.pendingInvoicesPrefix,
            numberTemplate,
            invoiceNumberTemplate,
            receiptNumberTemplate,
            refundNumberTemplate,
            feNumberTemplate,
            debit,
            credit,
            transfer,
            cash,
            baseBank,
            closeBank,
            warehouse,
            costCenter,
            seller,
            priceList,
            users: !isEmpty(users) ? users : [{ idGlobal: -1, name: 'Todos' }],
          }}
          keepDirtyOnReinitialize
        >
          {({ handleSubmit, values, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Header
                title={
                  <p className='text-title-secundary'>{`${I18n.get(
                    'stationSettingTitle',
                    'Terminal'
                  )}: ${station.name}`}</p>
                }
                subtitle={
                  <p>
                    {I18n.get(
                      'stationSettingSubtitle',
                      'configura tu terminal, valores por defecto a la hora de facturar y mucho más'
                    )}{' '}
                    <a
                      className='text-capitalize-first btn-link'
                      href='https://ayuda.alegra.com/es/configura-terminales-para-facturar-en-el-punto-de-venta'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {capitalize(I18n.get('seeMore', 'ver más'))}
                    </a>
                  </p>
                }
                info={
                  !can('edit', 'pos-station')
                    ? I18n.get(
                        ('userNotAllowed.pos-station.edit',
                        'no tienes permisos suficientes para editar la información de la terminal')
                      )
                    : null
                }
              />

              <Body values={values} />

              <Bottom
                disabled={!can('edit', 'pos-station')}
                submitting={submitting}
                onClose={() => history.push('/settings')}
              />
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

export default Station;
