import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { get, remove } from 'lodash'

import { fetch, getInitialState } from '../utils'
import { handleError } from '../utils/errors'
import { APIGraphqlSelector } from '../selectors/app';
import alegraAPI from '../reducers/alegraAPI';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null,
  selected: [],
}

const fetchItemsCategoriesPartial = (api) => async (metadata, params) => {
  //ex queries.allItemCategories
  const response = await alegraAPI.get('/item-categories', {
    metadata: true,
    ...params,
    ...metadata
  })
  const data = get(response, 'data.data', null)
  const total = get(response, 'data.metadata.total', 0)
  if (data)
    return { data, total };
  return { data: [], total };
}

export const fetchItemCategories = createAsyncThunk(
  'itemCategories/fetch',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      const APIGraphql = APIGraphqlSelector(getState());
      return await fetch(fetchItemsCategoriesPartial(APIGraphql), !!preloaded ? preloaded : [], params)
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'itemCategories',
  initialState: getInitialState('itemCategories', initialState),
  reducers: {
    addSelected: (state, action) => {
      state.selected.push(action.payload)
      state.selected.push((action.payload.toString()))
    },
    removeSelected: (state, action) => {
      let selected = [...state.selected]
      remove(selected, el => el === action.payload)
      remove(selected, el => el === action.payload.toString())
      state.selected = !!selected ? selected : []
    },
    clearSelected: (state) => {
      state.selected = []
    },
    addItemCategory: adapter.addOne,
    updateItemCategory: adapter.updateOne,
    removeItemCategory: adapter.removeOne,
  },
  extraReducers: builder => {
    builder.addCase(fetchItemCategories.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchItemCategories.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchItemCategories.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { reducer, actions } = appSlice;

export const itemCategoriesSelector = adapter.getSelectors(state => state.itemCategories);

export const {
  addSelected,
  removeSelected,
  clearSelected,
  addItemCategory,
  updateItemCategory,
  removeItemCategory,
} = actions;

export default reducer;