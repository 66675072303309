import { I18n } from '@aws-amplify/core';
import toastHandler from './toast-handler';

export function printPDFFromUrl(url) {
  window.open(url, '_blank', 'noopener,noreferrer');
}

export async function downloadFileFromUrl(url, fileName) {
  const toastId = `downdload-file-${fileName}`;
  toastHandler(
    {
      type: 'neutral',
      description: I18n.get('downloadingFile', 'Descargando archivo...'),
      loader: true,
    },
    { toastId }
  );

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(
        `Failed to fetch file: ${response.status} ${response.statusText}`
      );
    }
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName || 'downloadedFile';
    document.body.appendChild(link);
    link.click();
    toastHandler(
      {
        update: true,
        type: 'success',
        description: I18n.get('fileDownloaded', 'Archivo descargado'),
      },
      { toastId }
    );
    document.body.removeChild(link);
  } catch (error) {
    toastHandler(
      {
        update: true,
        type: 'error',
        description: I18n.get(
          'fileDownloadError',
          'Error al descargar el archivo'
        ),
      },
      { toastId }
    );
  }
}
