// eslint-disable-next-line
export default ({ id, className }) => (
  <svg 
    id={id}
    className={className}
    width="49" 
    height="48" 
    viewBox="0 0 49 48" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M9.06592 13.9431L25.9025 5.45481C26.9185 4.94272 28.1785 5.16597 28.9246 5.98985L43.3954 21.9754C44.2715 22.9434 44.2542 24.3671 43.3548 25.3163L27.7136 41.8189C26.1975 43.2699 23.6588 43.1211 22.3476 41.5045L7.18793 21.428C5.28763 18.9111 6.16725 15.4044 9.06592 13.9431Z" fill="#FF2F73"/>
    <g style={{mixBlendMode: 'multiply'}}>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.9494 21.771L16.906 7.09732C17.567 6.21196 18.7994 5.88017 19.8616 6.30197L40.467 14.4808C41.7148 14.9761 42.3836 16.255 42.0365 17.4813L35.9995 38.8088C35.3429 40.7433 33.0041 41.6798 31.0563 40.7886L7.86949 29.2466C4.96265 27.7994 4.06324 24.2974 5.9494 21.771Z" fill="#2FCAD7"/>
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.442 11.7369L22.3006 7.27074L34.4487 12.0926L41.391 19.7617L38.313 30.6357L29.4482 39.9887L16.2369 33.4124L7.18769 21.4282C7.01439 21.1986 6.86421 20.9609 6.73657 20.7172L13.442 11.7369Z" fill="#1A1446"/>
  </svg>
)
