import es from './es'

const dictionary = {
  ...es,
  "AlegraAccounting": "Contabilidad",
  "AlegraAccounting.description": "Gestiona las cuentas de tu negocio",
  "AlegraPOS": "Punto de venta",
  "AlegraPOS.description": "Factura fácil en tus puntos de venta",
  "AlegraShop.description": "Crea tu tienda online fácil y rápido",
  "identification": "Número de identificación",
  "identificationType": "Tipo de Receptor",
  "immediatePaymentSale": "Ventas de contado",
  "billingPoint": "Punto de facturación",
  "cashReturned": "Vuelto",
  "change": "Vuelto",
  "configureFEmodal.title": "<p style='color: #000; font-weight: bold;'>¡Facturas electrónicas en Alegra POS!</p>",
  "configureFEmodal.description": "Habilítate en minutos ante la DGI y <b>empieza a emitir</b> tus facturas electrónicas desde tu punto de venta.",
  "dgi": "DGI",
  "dueDate": "Fecha de vencimiento",
  "emissionStatus": "Estado DGI",
  "goToTutorial": "Conoce cómo habilitarme",
  "howToEmit": "Cómo emitirlas",
  "invoiceModalTitle.invoice.electronic": "Factura electrónica",
  "knowHowToCreateThem": "Conoce cómo crearlas",
  "kindOfPerson": "Tipo de contribuyente",
  "legalStatus.issued": "Autorizada",
  "notElectronic": "No electrónica",
  "observations": "Notificaciones",
  "onboarding.video.url": "https://www.youtube.com/embed/6TLSRE-D9bg",
  "onboarding.video.id": "6TLSRE-D9bg",
  "planIncomeFeature": "Ingresos hasta {}{} {} o Balboas",
  "plansUpdatePAN": "Los planes fueron actualizados el 24/10/22, tenlo en cuenta si necesitas cambiar tu suscripción.",
  "passport": "Pasaporte",
  "subtotalWithoutTax": "Subtotal sin impuestos",
  "township": "Corregimiento",
  "youCanNowMakeElectronicInvoices": "¡Habilítate para emitir Facturas Electrónicas! 💥",
  "youCanNowMakeElectronicInvoices.description": "Actívate en 5 minutos y empieza a crear facturas electrónicas en tu punto de venta con todos los requisitos de la DGI.  ",
  "youCanNotMakeElectronicInvoices": "¡Habilítate para crear Facturas Electrónicas! 💥",
  "youCanNotMakeElectronicInvoices.template": "{} 💥",
  "youCanNotMakeElectronicInvoices.description": "Actívate en {} ante la DGI y empieza a crear facturas electrónicas en tu punto de venta",
  "youHavePendingInvoicesToEmit": "¡Tienes facturas por emitir! 🚨",
  "youHavePendingInvoicesToEmit.description": "Aprende a identificar las facturas que tienes “Por emitir” y envíalas a la DGI con un clic.",
  "youHavePendingInvoicesToEmit.link": "https://ayuda.alegra.com/es/conoce-el-estado-de-emision-de-tus-facturas-electronicas-en-el-pos-panama",
  "electronicPayrollTutorial.video.id": "I2JV1zwprRw",
  "helpedArticle": "Ir a habilitarme",
  "solutions.description.new": "Más soluciones para tu negocio",
  "AlegraPayroll.new": "Facturación",
  "AlegraPayroll.description.new": "Factura electrónicamente con un clic",
  "AlegraAccounting.new": "Contabilidad",
  "AlegraAccounting.description.new": "Contabiliza, factura y controla tu stock",
  "AlegraPOS.new": "POS",
  "AlegraPOS.description.new": "Vende y factura sin impresora fiscal",
  "AlegraShop.new": "Tienda",
  "AlegraShop.description.new": "Crea tu primera tienda online en 4 clics",
  "importProducts.text": "Importar productos",
  "importProducts.tooltip": "Te llevaremos a Alegra Contabilidad para que los importes de manera masiva.",
  "solutionModal.accounting.supTitle": "Tus cuentas claras con",
  "solutionModal.accounting.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.accounting.buttonSecondaryText": "Más información",
  "solutionModal.accounting.description": "Con Alegra Contabilidad controlas tus ingresos y gastos con un clic, y obtienes reportes inteligentes para que estés siempre al día con la DGI.",
  "solutionModal.accounting.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.store.supTitle": "Incrementa tus ventas con",
  "solutionModal.store.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.store.buttonSecondaryText": "Más información",
  "solutionModal.store.description": "¡Empieza a vender online en segundos!\nCon Alegra Tienda creas tu primera tienda online en solo 4 clics.",
  "solutionModal.store.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.pos.supTitle": "Gestiona tu negocio con",
  "solutionModal.pos.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.pos.buttonSecondaryText": "Más información",
  "solutionModal.pos.description": "Factura en segundos con el Sistema Punto de Venta más veloz del mercado, además, podrás generar tus reportes de ventas e inventario en segundos. ⚡",
  "solutionModal.pos.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.invoice.supTitle": "Ahorra tiempo con",
  "solutionModal.invoice.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.invoice.buttonSecondaryText": "Más información",
  "solutionModal.invoice.description": "Genera en segundos tus facturas con el software de Facturación más veloz de Panamá. Sistema 100% adaptado a la DGI.",
  "solutionModal.invoice.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "AlegraInvoicing.description.new": "Factura electrónicamente con un clic",
  "numerationElectronicOffline": "Necesitas conexión a internet para crear y enviar facturas electrónicas a la DGI."
}

export default dictionary;