// src/hooks/useRefundActions.js

import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import alegraAPI from '../../../reducers/alegraAPI';
import { openModal } from '../../../reducers/modals';
import { hasPermissionTo } from '../../../selectors/auth';
import { companySelector, country as countrySelector } from '../../../selectors/company';
import { getVoidDefaultMethod, isUserElegibleForNewRefund } from '../utils';
import { toast } from '../../../utils';
import { COUNTRIES } from '../../../utils/enums/countries';


export const modalParamsByCountry = {
  [COUNTRIES.SPAIN]: {
    step: 1,
    method: getVoidDefaultMethod(COUNTRIES.SPAIN),
    country: COUNTRIES.SPAIN,
  },
  [COUNTRIES.COLOMBIA]: {
    country: COUNTRIES.COLOMBIA,
  },
};

/**
 * Custom hook to handle refund actions.
 *
 * @param {Object} refund - The refund object.
 * @param {Function} onRefresh - Function to refresh data.
 * @returns {Object} - Handlers and state related to refund actions.
 */
const useRefundActions = (refund, onRefresh) => {
  const [stampLoading, setStampLoading] = useState(false);
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);

  const sendRefund = useCallback(() => {
    dispatch(openModal({ modal: 'sendRefundEmail', params: { refund } }));
  }, [dispatch, refund]);

  const emitRefund = useCallback(async () => {
    try {
      setStampLoading(true);
      await alegraAPI.get(`/credit-notes/${refund.id}/stamp/`);
      onRefresh();

      toast.success({
        title: I18n.get('success', 'Éxito'),
        subtitle: I18n.get(
          refund.numberTemplate?.documentType === 'creditNote'
            ? 'refund.creditNoteStamped'
            : 'redund.adjustmentNoteStamped'
        ),
        params: { autoClose: 3000 },
      });
    } catch (error) {
      toast.error({
        title: I18n.get('error', 'Error'),
        subtitle: (
          <div className="notification-error-list">
            {error.response?.data?.message || ''}
          </div>
        ),
        params: { autoClose: 3000 },
      });
    } finally {
      setStampLoading(false);
    }
  }, [refund, onRefresh]);

  const openNewRefundModal = useCallback(() => {
    const params = modalParamsByCountry[country] || {};
    if (isUserElegibleForNewRefund(country, company)) {
      dispatch(openModal({ modal: 'newRefunds', params }));
    } else {
      dispatch(openModal({ modal: 'refunds' }));
    }
  }, [dispatch, country, company]);

  return {
    stampLoading,
    sendRefund,
    emitRefund,
    canAddRefund: can('add', 'credit-notes'),
    openNewRefundModal,
  };
};

export default useRefundActions;
