import React from 'react';
import { Form } from 'react-final-form';
import Modal from '../../common/Modal';
import Payments from './WizardSteps/Payments';
import ControlStepsButton from './ControlStepsButton';
import RefundHeader from './RefundHeader';
import RefundNotifications from './RefundNotifications';
import EditItem from '../../forms/newRefund/EditItem';
import Tip from '../../forms/newRefund/Tip';
import useNewRefunds from './hooks/useNewRefunds';
import useSendGTMEvent from '../../../hooks/useSendGtmEvent/hook';
import { validate } from './utils';

/**
 * NewRefunds Component
 *
 * Handles the creation of new refunds through a multi-step form within a modal.
 *
 * @returns {JSX.Element} - The rendered component.
 */
const NewRefunds = () => {
  const {
    step,
    editItemIsOpen,
    editTipIsOpen,
    itemIndex,
    method,
    initialRefundMemoized,
    isOpen,
    total,
    itemsInRefund,
    invoiceSelected,
    shiftOpen,
    shiftsEnabled,
    shiftCash,
    stationCashBank,
    isAllItemsPupaleted,
    isSelectingMode,
    tip,
    openEditItem,
    closeEditItem,
    openEditTip,
    closeEditTip,
    selectMethod,
    changeStep,
    onCloseModal,
    submit,
    country,
  } = useNewRefunds();

  // Send GTM Event when modal opens
  useSendGTMEvent(isOpen, 'pos-refund-started');

  return (
    <Modal
      isOpen={isOpen}
      className={`modal__refunds-new ${step !== 0 ? `step-${step}` : ''} ${method ? `method-${method}` : ''}`}
      includeHeader={false}
      id='refund-modal-portal'
    >
      <Form
        initialValues={invoiceSelected ? initialRefundMemoized : {}}
        validate={(values) =>
          validate(values, {
            total,
            tip,
            step,
            shiftOpen,
            shiftsEnabled,
            shiftCash,
            stationCashBank,
            itemsInRefund,
            isAllItemsPupaleted,
          })
        }
        onSubmit={(values) => submit(values, tip)}
      >
        {({ handleSubmit, form, submitError, errors, submitting }) => (
          <form onSubmit={handleSubmit} className='h-100'>
            <div className='form-body p-md-4 h-100 d-flex flex-column'>
              <RefundHeader
                step={step}
                method={method}
                isSelectingMode={isSelectingMode}
                changeStep={changeStep}
              />

              <div className='container-body-error'>
                <div className='d-flex flex-column'>
                  <Payments
                    step={step}
                    method={method}
                    onChooseMethod={selectMethod}
                    changeStep={changeStep}
                    reset={form.reset}
                    openEditItem={openEditItem}
                    openEditTip={openEditTip}
                    country={country}
                  />
                </div>

                <RefundNotifications
                  method={method}
                  submitError={submitError}
                  step={step}
                />
              </div>

              <ControlStepsButton
                closeModal={onCloseModal}
                method={method}
                step={step}
                disabled={Object.keys(errors).length > 0 || submitting}
                lastStepError={errors?.refund?.amount ?? false}
                submitting={submitting}
                editItemIsOpen={editItemIsOpen}
                changeStep={changeStep}
              />
            </div>
          </form>
        )}
      </Form>
      <EditItem
        isOpen={editItemIsOpen}
        selectedIndex={itemIndex}
        onClose={closeEditItem}
      />
      <Tip isOpen={editTipIsOpen} onClose={closeEditTip} />
    </Modal>
  );
};

export default React.memo(NewRefunds);
