import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import { Cash } from './Cash';
import { CreditToSales } from './CreditToSales';
import { Combined } from './Combined';
import { PositiveBalance } from './PositiveBalance';

import { companySelector } from '../../../../selectors/company';
import { isSelectingItems } from '../../../../selectors/activeRefund';

import { Icon } from '@alegradev/smile-ui-react';
import { getPrevStep } from '../utils';
import { RectificativeInSimplifiedInvoice } from './RectificativeInSimplifiedInvoice';
import { REFUND_METHODS } from '../../../../utils/enums/refundMethods';
import { IconArrowLeft } from '@tabler/icons-react';

/**
 * Generates the help link based on the method and FE activity status.
 *
 * @param {boolean} hasFEactive - Indicates if FE is active.
 * @param {string} method - The refund method.
 *
 * @returns {string|null} The help link URL or null if method is not recognized.
 */
const getHelpLink = (hasFEactive, method) => {
  if (!method) return null;

  const baseUrl = 'https://ayuda.alegra.com/es/crea-devoluciones-de-tus-productos-desde-el-sistema-punto-de-venta-pos';

  switch (method) {
    case 'cash':
      return `${baseUrl}#Devolucion_dinero`;
    case 'creditToSales':
      return hasFEactive
        ? 'https://ayuda.alegra.com/es/realiza-notas-credito-electronicas-desde-el-pos-colombia'
        : `${baseUrl}#Credito_factura`;
    case 'combined':
      return `${baseUrl}#Combinado`;
    case 'positiveBalance':
      return `${baseUrl}#Saldo_favor`;
    default:
      return null;
  }
};

/**
 * Mapping of refund methods to their corresponding components and any additional props.
 */
const refundMethodComponents = {
  cash: Cash,
  creditToSales: CreditToSales,
  combined: Combined,
  positiveBalance: PositiveBalance,
  rectificativeInSimplifiedInvoice: RectificativeInSimplifiedInvoice,
  rest: RectificativeInSimplifiedInvoice,
};

/**
 * ButtonControl Component
 *
 * Controls the navigation and rendering of different refund methods based on the current step.
 *
 * @param {Object} props - Component properties.
 * @param {number} props.step - Current step in the refund process.
 * @param {Function} props.changeStep - Function to change the current step.
 * @param {string} props.method - Refund method selected.
 * @param {Function} props.closeModal - Function to close the modal.
 * @param {boolean} props.disabled - Flag to disable buttons.
 * @param {boolean} props.submitting - Flag indicating if a submission is in progress.
 * @param {boolean} props.editItemIsOpen - Flag indicating if the edit item modal is open.
 * @param {string} props.lastStepError - Error message from the last step.
 *
 * @returns {JSX.Element|null} The rendered component or null if editItemIsOpen is true.
 */
const ButtonControl = React.memo(({
  step,
  changeStep,
  method,
  closeModal,
  disabled,
  submitting,
  editItemIsOpen,
  lastStepError,
}) => {
  const isSelectingMode = useSelector(isSelectingItems);
  const company = useSelector(companySelector);
  const hasFEactive = useMemo(() => company?.settings?.electronicInvoicing || false, [company]);

  const prevStep = useMemo(() => getPrevStep(step, method), [step, method]);
  const helpLink = useMemo(() => getHelpLink(hasFEactive, method), [hasFEactive, method]);

  const handleGoBack = useCallback(() => {
    changeStep(prevStep);
  }, [changeStep, prevStep]);

  if (editItemIsOpen) return null;

  // Determine the component to render based on the method
  const RefundComponent = refundMethodComponents[method];

  // Prepare the props to pass to the RefundComponent
  const refundComponentProps = {
    changeStep,
    closeModal,
    step,
    disabled,
    submitting,
    ...(([REFUND_METHODS.CREDIT_TO_SALES || REFUND_METHODS.RECTIFICATIVE_IN_SIMPLIFIED_INVOICE || REFUND_METHODS.REST].includes(method)) && { lastStepError }),
  };

  return (
    <div className='bottom'>
      {/* Display help link for steps 1 and 2 on small screens */}
      {(step === 1 || step === 2) && (
        <div className="help-link d-flex justify-content-center d-md-none mb-2 w-100">
          <a
            className='d-block text-center'
            href={helpLink}
            target="_blank"
            rel="noreferrer"
          >
            {I18n.get('seeHelp', 'Ver ayuda')}
          </a>
        </div>
      )}
      <div className={`d-flex ${step === 0 ? 'justify-content-end' : 'justify-content-between'}`}>
        {/* Show cancel button only on step 0 */}
        {step === 0 && (
          <button
            type='button'
            className='refunds-cancel-button'
            onClick={closeModal}
          >
            {I18n.get('cancel', 'Cancelar')}
          </button>
        )}

        {/* Display help link for steps 1 and 2 on medium and larger screens */}
        {(step === 1 || step === 2) && (
          <div className="help-link d-md-block d-none">
            <a
              href={helpLink}
              target="_blank"
              rel="noreferrer"
            >
              {I18n.get('seeHelp', 'Ver ayuda')}
            </a>
          </div>
        )}

        {/* Show 'Go Back' button on steps greater than 2 if not in selecting mode */}
        {step > 2 && !isSelectingMode && (
          <div className="help-link d-md-block d-none">
            <button
              type='button'
              disabled={submitting}
              className='refunds-cancel-button'
              style={{ border: 'none', fontWeight: '500' }}
              onClick={handleGoBack}
            >
              <Icon icon={IconArrowLeft} extraClass={`${submitting ? 'icon-white' : 'icon-black'} mr-2`} />
              {I18n.get('goBack', 'Volver')}
            </button>
          </div>
        )}

        {RefundComponent && <RefundComponent {...refundComponentProps} />}
      </div>
    </div>
  );
});

ButtonControl.propTypes = {
  step: PropTypes.number.isRequired,
  changeStep: PropTypes.func.isRequired,
  method: PropTypes.oneOf(['cash',
    'creditToSales',
    'combined',
    'positiveBalance',
    'rectificativeInSimplifiedInvoice']).isRequired,
  closeModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  editItemIsOpen: PropTypes.bool,
  lastStepError: PropTypes.string,
};

ButtonControl.defaultProps = {
  disabled: false,
  submitting: false,
  editItemIsOpen: false,
  lastStepError: '',
};

export default ButtonControl;
