import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../common/Modal';
import { get } from 'lodash';
import { closeModal, openModal } from '../../../reducers/modals';
import InvoiceSVG from '../../svg/illustrations/Invoice';
import CurrencySVG from '../../svg/illustrations/Currency.svg';
import Typography from '../../common/Typography';
import { I18n } from 'aws-amplify';
import { replaceAndParse } from '../../../utils';
import { checkFeatureLimit } from '../../../reducers/membership';
import { activeNumerations } from '../../../selectors/numerations';
import { useEffect, useState } from 'react';
import { setSettings } from '../../../reducers/activeInvoice';
import { activeInvoice } from '../../../selectors/activeInvoice';
import { Icon } from '@alegradev/smile-ui-react';
import { IconAlertTriangle, IconArrowRight } from '@tabler/icons-react';

const ChangeToOrdinaryNumeration = () => {
  const [ordinaryNumeration, setOrdinaryNumeration] = useState(null);
  const [hasOrdinaryNumeration, setHasOrdinaryNumeration] = useState(false);
  const numerations = useSelector(activeNumerations);
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.changeToOrdinaryNumeration.isOpen', false)
  );
  const invoice = useSelector(activeInvoice);

  useEffect(() => {
    const numerationDefault = numerations.find(
      (numeration) =>
        numeration.documentType === 'invoice' && numeration.isDefault
    );
    if (numerationDefault !== undefined) {
      setOrdinaryNumeration(numerationDefault);
      setHasOrdinaryNumeration(true);
    }
  }, [numerations]);
  const onChangeNumeration = () => {
    if (ordinaryNumeration) {
      dispatch(setSettings({ numeration: ordinaryNumeration }));
      if (
        get(invoice, 'client') === null ||
        get(invoice, 'client.identification') === 'X0101010Y'
      ) {
        close();
      } else {
        onContinue();
      }
    }
  };
  const onContinue = () => {
    dispatch(
      checkFeatureLimit(['income', 'invoices'], () => {
        close();
        dispatch(openModal({ modal: 'invoice' }));
      })
    );
  };
  const close = () => {
    dispatch(closeModal({ modal: 'changeToOrdinaryNumeration' }));
  };

  const getRegulationText = () => {
    if (!hasOrdinaryNumeration) {
      return replaceAndParse(
        I18n.get(
          'regulation.message.help',
          'Según Hacienda, las ventas que superen este valor deben crearse con una numeración de factura ordinaria {}.'
        ),
        [
          `<a
          href='https://ayuda.alegra.com/es/configura-tus-numeraciones-de-facturacion-en-alegra-tpv-espana'
          target='_blank'
          rel='noreferrer'
        >
        </br>
          ${I18n.get(
            'how.to.create.numeration',
            'cómo crear numeraciones'
          )} ${<Icon icon={IconArrowRight} type='primary' />}
        </a>`,
        ]
      );
    }
    return I18n.get(
      'regulation.message',
      'Según Hacienda, las ventas que superen este valor deben crearse con una numeración de factura ordinaria.'
    );
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        includeHeader={false}
        className='modal__change-ordinary-numeration'
        closeAction={() => close(false)}
      >
        <div className='d-flex justify-content-center'>
          <div className='position-relative'>
            <InvoiceSVG />
            <div className='position-absolute modal__change-ordinary-numeration-currency-icon'>
              <img src={CurrencySVG} alt='' />
            </div>
          </div>
        </div>
        <div className='d-flex flex-column align-items-center justify-content-center text-center'>
          <div className='d-flex align-items-center'>
            <Icon icon={IconAlertTriangle} extraClass='icon-warning' />
            <Typography
              type='body-2-bold'
              text={replaceAndParse(
                I18n.get(
                  'numerationModal.amount.total',
                  'La venta supera €{{price}}.'
                ),
                ['400']
              )}
            />
          </div>
          <Typography
            type='body-3-regular'
            variant='new-text-tertiary'
            className='mt-2'
            text={getRegulationText()}
          />
        </div>
        <div className='d-flex justify-content-between mt-5'>
          {hasOrdinaryNumeration && (
            <button
              className='btn btn-cancel text-capitalize-first shadow'
              onClick={onChangeNumeration}
            >
              <Typography
                type='label-1'
                variant='new-text-primary'
                text={I18n.get('change.numeration', 'Cambiar numeración')}
              />
            </button>
          )}

          <button
            className={`btn btn-submit text-capitalize-first shadow ${
              !hasOrdinaryNumeration ? 'w-100' : ''
            }`}
            onClick={onContinue}
          >
            <Typography
              type='label-1'
              text={I18n.get('continue.sale', 'Continuar venta')}
            />
          </button>
        </div>
      </Modal>
    </>
  );
};
export default ChangeToOrdinaryNumeration;
