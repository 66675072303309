import { useState } from "react";
import { Button, Typography } from "@alegradev/smile-ui-react";
import ImageDownloadAppSVG from "./images";
import posDesktopAPI from "../../reducers/posDesktopAPI";
import { useDetectOS } from "../../hooks/useDetectOS";

const availableInstallers = ['msi'];

const DownloadAppPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const os = useDetectOS();

  const handleDownload = async () => {
    setLoading(true);
    setError(null);

    if (!os && !availableInstallers.includes(installerType)) {
      setError('Unsupported operating system');
      setLoading(false);
      return;
    }

    try {
      const response = await posDesktopAPI.get(`/get-presigned-url?os=${os}`);

      const data = response?.data;

      const { url } = data;
      window.location.href = url;
    } catch (error) {
      setError('Error fetching presigned URL');
      console.error('Error fetching presigned URL:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page__download-app">
      <div className="page__download-app__images-content">
        <ImageDownloadAppSVG />
      </div>
      <div className="page__download-app__text-content">
        <div className="page__download-app__text-content__container">
          <div>
            <Typography text="APLICACIÓN DE ESCRITORIO" type="body-3-regular" variant="success" />
            <Typography text="Alegra POS para Windows 💻" type="heading-2" />
          </div>
          <div>
            <Typography text="La nueva aplicación de escritorio agiliza las ventas de tu negocio, te permite usar todas las funciones de la web y mejora tu experiencia durante intermitencias de internet." type="body-2-regular" />
            <Typography text="¡Descárgala y dale turbo a tus ventas! ⚡" type="body-2-bold" />
          </div>
          <div className="page__download-app__text-content__actions">
            <Button text="Descargar gratis" loading={loading} onClick={handleDownload} />
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <Button text="Conocer más beneficios" emphasis="outline" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppPage;