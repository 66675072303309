import { get } from 'lodash';
import configAPI from '../../../../reducers/configAPI';


const getCatalogVersion = ({ idCompany }) => {
  return '4.3';

}

export const fetchDistricts = async ({ idCompany }) => {
  const data = await configAPI.get('/districts', {
    application_version: 'costaRica',
    catalog_version: getCatalogVersion({ idCompany }),
  });
  return get(data, 'data', []);
}

export const fetchNeighborhoods = async ({ idCompany, idDistrict }) => {
  const data = await configAPI.get('/neighborhoods', {
    application_version: 'costaRica',
    catalog_version: getCatalogVersion({ idCompany }),
    district_id: idDistrict,
  });
  return get(data, 'data', []);
}