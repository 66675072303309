import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { get } from 'lodash'

import { hasPermissionTo } from '../selectors/auth'
import { handleError } from '../utils/errors'
import alegraAPI from '../reducers/alegraAPI';
import { getInitialState } from '../utils';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null
}

export const fetchPaymentTerms = createAsyncThunk(
  'paymentTerms/fetch',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      const can = hasPermissionTo(getState())
      if (!can('index', 'terms'))
        return []

      if (!!preloaded)
        return preloaded
      // ex queries.allPaymentTerms

      const data = await alegraAPI.get('/terms', {
        ...params
      })
      return get(data, 'data', null);
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'paymentTerms',
  initialState: getInitialState('paymentTerms', initialState),
  reducers: {
    addPaymentTerm: adapter.addOne
  },
  extraReducers: builder => {
    builder.addCase(fetchPaymentTerms.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchPaymentTerms.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchPaymentTerms.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { actions, reducer } = appSlice;

export const { addPaymentTerm } = actions;

export const paymentTermsSelector = adapter.getSelectors(state => state.paymentTerms);

export default reducer;