import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { get } from 'lodash'

import { handleError } from '../utils/errors'
import alegraAPI from './alegraAPI'
import { getInitialState } from '../utils';

const initialState = {
  entities: {},
  ids: [],
  loading: 'loading',
  error: null
}

export const fetchInventoryAdjustmentsNumerations = createAsyncThunk(
  'inventoryAdjustmentsNumerations/fetch',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      if (!!preloaded)
        return preloaded

      const data = await alegraAPI.get('/inventory-adjustments/numerations', {
        ...params
      })
      return get(data, 'data', null)
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'inventoryAdjustmentsNumerations',
  initialState: getInitialState('inventoryAdjustmentsNumerations', initialState),
  reducers: {
    addInventoryAdjustmentsNumeration: adapter.addOne,
    updateInventoryAdjustmentsNumeration: adapter.updateOne,
    removeInventoryAdjustmentsNumeration: adapter.removeOne,
  },
  extraReducers: builder => {
    builder.addCase(fetchInventoryAdjustmentsNumerations.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchInventoryAdjustmentsNumerations.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchInventoryAdjustmentsNumerations.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { actions, reducer } = appSlice;

export const { addInventoryAdjustmentsNumeration, updateInventoryAdjustmentsNumeration, removeInventoryAdjustmentsNumeration } = actions;

export const inventoryAdjustmentsNumerationsSelector = adapter.getSelectors(state => state.inventoryAdjustmentsNumerations);
export const loading = state => get(state, 'inventoryAdjustmentsNumerations.loading') === 'loading';
export const error = state => get(state, 'inventoryAdjustmentsNumerations.error');

export default reducer;