import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { get } from 'lodash'

import { hasPermissionTo } from '../selectors/auth'
import { handleError } from '../utils/errors'
import { fetch, getInitialState } from '../utils'
import { APIGraphqlSelector } from '../selectors/app';
import alegraAPI from '../reducers/alegraAPI';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null
}

export const fetchBanksPartial = (api) => async (metadata, params) => {
  // ex queries.allBanks

  const response = await alegraAPI.get('/bank-accounts', {
    metadata: true,
    ...metadata,
    ...params
  })

  const data = get(response, 'data.data', null)
  const total = get(response, 'data.metadata.total', 0)
  if (data)
    return { data, total };
  return { data: [], total };
}

export const fetchBanks = createAsyncThunk(
  'banks/fetch',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      const can = hasPermissionTo(getState())
      const APIGraphql = APIGraphqlSelector(getState());
      if (!can('view', 'bank-accounts') || !can('index', 'bank-accounts'))
        return []

      return await fetch(fetchBanksPartial(APIGraphql), !!preloaded ? preloaded : [], params)
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'banks',
  initialState: getInitialState('banks', initialState),
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(fetchBanks.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchBanks.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchBanks.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { reducer } = appSlice;

export const banksSelector = adapter.getSelectors(state => state.banks);

export default reducer;