import React from 'react';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, get } from 'lodash';

import * as mutations from '../../../../../graphql/mutations';
import { replaceAndParse, toast } from '../../../../../utils';
import { formError } from '../../../../../utils/errors';
import { addSeller } from '../../../../../reducers/sellers';
import {
  validate,
  transform,
} from '../../../../../components/forms/seller/utils';
import { APIGraphqlSelector } from '../../../../../selectors/app';
import { sendGTMEvent, sendNewGTMEvent } from '../../../../../reducers/company';
import {
  Button,
  Grid,
  Typography,
  useModal,
  toastHandler,
} from '@alegradev/smile-ui-react';
import { renderField } from '../../../../../components/forms/fields/V2';

function Seller({ onCreated, from, modalName }) {
  const { Wrapper } = Grid;
  const { closeModal } = useModal();
  const dispatch = useDispatch();
  const APIGraphql = useSelector(APIGraphqlSelector);

  const submit = async (values) => {
    const transformedValues = transform(values);

    try {
      const response = await APIGraphql(
        graphqlOperation(mutations.createSeller, {
          seller: transformedValues,
        })
      );

      const seller = !!get(response, 'data.createSeller')
        ? get(response, 'data.createSeller')
        : null;

      if (!!seller) {
        dispatch(sendGTMEvent('create_seller'));
        dispatch(
          sendNewGTMEvent('pos-seller-created', {
            origin: from === 'sideModal' ? 'sale' : 'sellers',
          })
        )
        dispatch(addSeller(seller));
        if (!!onCreated) onCreated(seller);
      }

      closeModal(modalName);

      toastHandler({
        type: 'success',
        title: `${I18n.get('sellerCreatedSuccessfully', 'vendedor creado con éxito')} 💪`,
        description: I18n.get(
          'sellerCreatedSuccessfullyMessage',
          'Ya puedes asociar en tus facturas a {} como vendedor.'
        )?.replace('{}', `<b>${get(seller, 'name', '')}</b>`),
      });
    } catch (error) {
      return formError(
        error,
        I18n.get(
          'createSellerError',
          'hubo un error en la creación del vendedor'
        )
      );
    }
  };

  return (
    <Form onSubmit={submit} validate={(values) => validate(values)}>
      {({ handleSubmit, submitting, submitError, errors }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Wrapper direction={{ lg: 'column' }} gap={10}>
            <Field
              name='name'
              component={renderField}
              type='text'
              label={capitalize(I18n.get('name', 'Nombre'))}
              required
            />
            <Field
              name='identification'
              component={renderField}
              type='text'
              label={I18n.get('identification', 'identificación')}
            />
            <Field
              name='observations'
              component={renderField}
              type='textarea'
              fieldType='textarea'
              label={capitalize(I18n.get('observations', 'Observaciones'))}
            />

            {submitError && (
              <Typography
                type='body-3-regular'
                variant='error'
                text={submitError}
              />
            )}

            <Wrapper gap={10}>
              <Button
                emphasis='outline'
                full
                disabled={submitting}
                onClick={() => closeModal(modalName)}
                type='button'
                text={I18n.get('cancel', 'Cancelar')}
              />
              <Button
                full
                loading={submitting}
                disabled={submitting || Object.keys(errors).length > 0}
                text={I18n.get('save', 'Guardar')}
              />
            </Wrapper>
          </Wrapper>
        </form>
      )}
    </Form>
  );
}

export default Seller;
