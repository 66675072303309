// eslint-disable-next-line
export default ({ id, className }) => (
  <svg
    id={id}
    className={className}
    width='154'
    height='182'
    viewBox='0 0 154 182'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g filter='url(#filter0_dd_3167_25906)'>
      <rect
        x='16.5'
        y='11.2983'
        width='108.088'
        height='131.051'
        rx='16.3031'
        transform='rotate(-6 16.5 11.2983)'
        fill='white'
        shape-rendering='crispEdges'
      />
      <rect
        x='45.1074'
        y='45.2192'
        width='57.5997'
        height='57.5997'
        rx='28.7999'
        transform='rotate(-6 45.1074 45.2192)'
        fill='#F1F5F9'
      />
      <path
        d='M74.9161 70.3806C74.5361 70.0729 73.9785 70.1315 73.6708 70.5115C73.363 70.8916 73.4216 71.4491 73.8017 71.7569L75.7086 73.3011L74.1644 75.2081C73.8566 75.5882 73.9152 76.1457 74.2953 76.4535C74.6753 76.7613 75.2329 76.7027 75.5407 76.3226L77.0849 74.4156L78.9919 75.9599C79.3719 76.2676 79.9295 76.209 80.2373 75.829C80.545 75.4489 80.4864 74.8914 80.1064 74.5836L78.1994 73.0394L79.7436 71.1324C80.0514 70.7523 79.9928 70.1948 79.6127 69.887C79.2327 69.5792 78.6751 69.6378 78.3674 70.0179L76.8231 71.9249L74.9161 70.3806Z'
        fill='#64748B'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M67.4494 61.1767C67.9913 60.5075 68.7769 60.081 69.6332 59.991L77.8523 59.1272C78.0858 59.1026 78.3196 59.1718 78.5021 59.3196L84.9899 64.5733C85.1724 64.7211 85.2887 64.9354 85.3132 65.1689L86.6707 78.0846C86.7607 78.941 86.5069 79.798 85.965 80.4672C85.4231 81.1364 84.6376 81.5629 83.7812 81.6529L72.0397 82.887C71.1833 82.977 70.3263 82.7231 69.6571 82.1812C68.9879 81.6393 68.5614 80.8538 68.4714 79.9974L66.7437 63.5593C66.6537 62.7029 66.9075 61.8459 67.4494 61.1767ZM69.8183 61.7522C69.4291 61.7932 69.072 61.987 68.8257 62.2912C68.5794 62.5954 68.464 62.9849 68.5049 63.3742L70.2326 79.8123C70.2735 80.2016 70.4674 80.5586 70.7716 80.8049C71.0757 81.0513 71.4653 81.1667 71.8546 81.1257L83.5961 79.8917C83.9853 79.8507 84.3424 79.6569 84.5887 79.3527C84.835 79.0485 84.9504 78.659 84.9095 78.2697L83.6446 66.2347L79.8286 66.6357C79.2836 66.693 78.7382 66.5315 78.3124 66.1866C77.8865 65.8418 77.6151 65.3419 77.5579 64.7969L77.1568 60.9809L69.8183 61.7522ZM79.0489 62.0412L82.2141 64.6043L79.6435 64.8745C79.5656 64.8827 79.4877 64.8596 79.4269 64.8103C79.366 64.7611 79.3273 64.6897 79.3191 64.6118L79.0489 62.0412Z'
        fill='#64748B'
      />
    </g>
    <defs>
      <filter
        id='filter0_dd_3167_25906'
        x='-1.20929'
        y='3.05176e-05'
        width='156.614'
        height='182.953'
        filterUnits='userSpaceOnUse'
        color-interpolation-filters='sRGB'
      >
        <feFlood flood-opacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='5.9031'
          operator='erode'
          in='SourceAlpha'
          result='effect1_dropShadow_3167_25906'
        />
        <feOffset dy='11.8062' />
        <feGaussianBlur stdDeviation='5.9031' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_3167_25906'
        />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='5.9031'
          operator='erode'
          in='SourceAlpha'
          result='effect2_dropShadow_3167_25906'
        />
        <feOffset dy='23.6124' />
        <feGaussianBlur stdDeviation='11.8062' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
        />
        <feBlend
          mode='normal'
          in2='effect1_dropShadow_3167_25906'
          result='effect2_dropShadow_3167_25906'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect2_dropShadow_3167_25906'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);
