import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import { calculateRefundType, getDateTime } from '../utilities';
import { formatDate, getRefundTypeLabel } from '../../../refunds/utils';
import { REFUND_METHODS } from '../../../../utils/enums/refundMethods';
import { DOCUMENT_TYPES } from '../../../../utils/enums/documentTypes';

/**
 * Spain-specific RefundInfo Component.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.refund - The refund object.
 * @param {string} props.align - Alignment for text.
 * @param {string} props.country - The country code.
 *
 * @returns {JSX.Element} - Rendered component for Colombia.
 */
const RefundInfoSpain = React.memo(({ refund, align, country }) => {
  const refundType = calculateRefundType(refund, country);
  const fullNumber = refund?.numberTemplate?.fullNumber ?? '';
  const emissionDate = refund?.stamp?.date ?? '';
  const creditNoteOperationType = refund?.creditNoteOperationType ?? '';
  const documentType = refund?.numberTemplate?.documentType ?? '';

  return (
    <div
      className={`w-100 text-${align} py-2 d-flex flex-column border-bottom`}
    >
      <p>
        <strong>{refundType}</strong> N° {fullNumber}
      </p>
      {emissionDate && (
        <p>
          <strong>{I18n.get('emissionDate', 'Fecha de emisión')}</strong>{' '}
          {formatDate(emissionDate)}
        </p>
      )}
      <p>
        <strong>
          {I18n.get('creditNoteOperationType', 'Tipo de operación')}
        </strong>
        {I18n.get(
          REFUND_METHODS[creditNoteOperationType],
          'Factura rectificativa en factura simplificada'
        )}
      </p>
      <p>
        <strong>
          {documentType === DOCUMENT_TYPES.CREDIT_NOTE
            ? I18n.get(
                'rectificativeInvoiceType',
                'Tipo de factura rectificativa'
              )
            : I18n.get('refundType', 'Tipo de devolución')}
        </strong>
        {getRefundTypeLabel(refund, country)}
      </p>
      <p>
        <strong>{I18n.get('reasonRefund', 'Razón')}</strong> {refund?.cause}
      </p>
    </div>
  );
});

RefundInfoSpain.propTypes = {
  refund: PropTypes.shape({
    numberTemplate: PropTypes.shape({
      documentType: PropTypes.string,
      isElectronic: PropTypes.bool,
      fullNumber: PropTypes.string,
    }),
    dueDate: PropTypes.string,
  }).isRequired,
  align: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

export default RefundInfoSpain;
