// SpainAddressSimplified.js

import React, { useMemo } from "react";
import { Field } from "react-final-form";
import { I18n } from "aws-amplify";
import { capitalize } from "lodash";

import spainAddresses from "../../../components/countriesData/spain/municipalityProvince.json";
import { renderField, renderWindowedSelect } from '../../forms/fields/V0/Fields';

/**
 * Component responsible for rendering the simplified address fields for Spain,
 * including a searchable and optimized select field.
 *
 * @component
 * @param {Object} props - Component properties.
 * @returns {JSX.Element} The rendered address fields with search and optimized rendering.
 */
const SpainAddressSimplified = (props) => {
  /**
   * Memoized filtered options.
   *
   * @returns {Array<{ text: string, value: string }>} Array of option objects.
   */
  const filteredOptions = useMemo(() => {
    return spainAddresses.map((item) => ({
      text: item.value,
      value: item.value,
    }));
  }, []);

  /**
   * Generates the label for the combined municipality and province field.
   *
   * @returns {string} The concatenated label.
   */
  const generateLabel = () => {
    return [
      capitalize(I18n.get("city", "Municipio")),
      capitalize(I18n.get("province", "Provincia")),
    ].join(" / ");
  };

  /**
   * Generates a placeholder for the select field.
   *
   * @returns {string} The placeholder text.
   */
  const generateSelectPlaceholder = () => {
    return I18n.get("select", "Seleccionar");
  };

  return (
    <div className='form-body__fields form-row justify-content-start'>
      <Field
        name="address.combined"
        className="col-12"
        component={renderWindowedSelect}
        options={spainAddresses}
        getOptionLabel={option => option.value}
        getOptionValue={option => option.value}
        placeholder={generateSelectPlaceholder()}
        label={generateLabel()}
        required
      />
      <Field
        name="address.address"
        className="col-12"
        component={renderField}
        type="text"
        label={capitalize(I18n.get("address", "Dirección"))}
        required
        placeholder={I18n.get("enterAddress", "Ingresa la dirección")}
      />
    </div>
  );
};

SpainAddressSimplified.propTypes = {
  // Define any props if necessary
};

export default SpainAddressSimplified;
