import { capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import useElectronicPayrollStatus from '../../../hooks/useElectronicPayrollStatus/hook';

import { closeMenu } from '../../../reducers/app';
import { openModal } from '../../../reducers/modals';
import Tag from '../../common/Tag';
import Typography from '../../common/Typography';
import { country as countrySelector } from '../../../selectors/company';
import { COUNTRIES } from '../../../utils/enums/countries';
import { ChipStatus, Icon } from '@alegradev/smile-ui-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useCallback, useMemo } from 'react';
import { IconCircleCheck, IconCircleMinus } from '@tabler/icons-react';

const ELECTRONIC_PAYROLL_TEXT = {
  [COUNTRIES.COLOMBIA]: 'dianActivation',
  [COUNTRIES.SPAIN]: 'ticketBai',
  default: '',
}

const ELECTRONIC_PAYROLL_DEFAULT_TEXT = 'Facturación electrónica';

const ACTIVATED_KEY = 'enabled';
const DEACTIVATED_KEY = 'disabled';

const ElectronicPayrollStatus = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const country = useSelector(countrySelector);
  const { isActive } = useElectronicPayrollStatus();

  const handleOpenModal = useCallback(
    (e) => {
      e.preventDefault();

      if (isActive) {
        return;
      }

      dispatch(closeMenu());

      if (country === COUNTRIES.MEXICO) {
        window.open(
          'https://ayuda.alegra.com/es/pasos-para-habilitar-tu-facturaci%C3%B3n-electr%C3%B3nica-en-alegra-m%C3%A9xico',
          '_blank',
          'noopener,noreferrer'
        );
        return;
      }

      if (country === COUNTRIES.SPAIN) {
        history.push('/settings/company');
        return;
      }

      dispatch(
        openModal({
          modal: 'electronicPayrollTutorial',
        })
      );
    },
    [dispatch, isActive, country, history]
  );

  const electronicPayrollText = useMemo(() => {
    const key = ELECTRONIC_PAYROLL_TEXT[country] || 'default';
    const defaultText = ELECTRONIC_PAYROLL_DEFAULT_TEXT;

    return I18n.get(key, defaultText);
  }, [country]);

  const tagText = useMemo(() => {
    const key = isActive ? ACTIVATED_KEY : DEACTIVATED_KEY;
    const defaultText = isActive ? I18n.get('enabled', "Habilitado") : I18n.get('disabled', "Deshabilitado");

    return capitalize(I18n.get(key, defaultText));
  }, [isActive]);

  return (
    <div
      className='electronic-payroll-status w-100 d-flex align-items-center justify-content-between p-0'
      onClick={(e) => handleOpenModal(e)}
    >
      <div className='d-flex align-items-center'>
        <Icon
          icon='receipt-2'
        />
        <Typography
          type='body-3-bold'
          variant='secondary'
          text={electronicPayrollText}
        />
      </div>

      <ChipStatus
        disabled={!isActive}
        icon={isActive ? () => <IconCircleCheck /> : () => <IconCircleMinus />}
        iconVariant="reversed"
        label={tagText}
        variant="success"
      />
    </div>
  );
}

export default ElectronicPayrollStatus;
