export const calculateInvoiceReceived = (values) => {
  return !!values.cash
    ? +values.cash
    : null + !!values.debit
      ? +values.debit
      : null + !!values.credit
        ? +values.credit
        : null + !!values.transfer
          ? +values.transfer
          : null;
};
