import React from 'react';
import PropsTypes from 'prop-types';
import ReactModal from 'react-modal';

import Header from './Header';

const Modal = ({
  children,
  title,
  headerImg,
  includeHeader = true,
  ...props
}) => {
  return (
    <ReactModal {...props}>
      <div className='d-flex flex-column' style={{
        width: "-webkit-fill-available"
      }}>
        {!!includeHeader && (
          <Header
            title={title}
            headerImg={headerImg}
            onClose={
              props.closeAction ? props.closeAction : props.onRequestClose
            }
            {...props}
          />
        )}

        <div className='modal__body'>{children}</div>
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropsTypes.bool.isRequired,
  style: PropsTypes.object,
  portalClassName: PropsTypes.string,
  bodyOpenClassName: PropsTypes.string,
  htmlOpenClassName: PropsTypes.string,
  className: PropsTypes.string,
  overlayClassName: PropsTypes.string,
  appElement: PropsTypes.element,
  onAfterOpen: PropsTypes.func,
  onAfterClose: PropsTypes.func,
  onRequestClose: PropsTypes.func,
  closeTimeoutMS: PropsTypes.number,
  ariaHideApp: PropsTypes.bool,
  shouldFocusAfterRender: PropsTypes.bool,
  shouldCloseOnOverlayClick: PropsTypes.bool,
  shouldCloseOnEsc: PropsTypes.bool,
  includeHeader: PropsTypes.bool,
  shouldReturnFocusAfterClose: PropsTypes.bool,
  preventScroll: PropsTypes.bool,
  parentSelector: PropsTypes.element,
  aria: PropsTypes.shape({
    labelledby: PropsTypes.string,
    describedby: PropsTypes.string,
    modal: PropsTypes.bool,
  }),
  data: PropsTypes.any,
  role: PropsTypes.string,
  contentLabel: PropsTypes.string,
  contentRef: PropsTypes.element,
  overlayRef: PropsTypes.element,
  overlayElement: PropsTypes.element,
  contentElement: PropsTypes.element,
  testId: PropsTypes.string,
  id: PropsTypes.string,
  title: PropsTypes.oneOfType([PropsTypes.string, PropsTypes.element]),
  onConfirm: PropsTypes.func,
  hideHeader: PropsTypes.bool,
};

export default Modal;
