import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { BigNumber } from 'bignumber.js';
import dayjs from 'dayjs';

import {
  timezone as tzSelector
} from '../../../selectors/company';
import { lowerCase } from 'lodash';
import { useFormat } from '../../../hooks/useFormat';

const ShiftDetail = ({ shift, format }) => {
  const { decimal, fmt } = useFormat();
  const timezone = useSelector(tzSelector);

  if (!shift) return null;

  const start = shift.start;
  const end = shift.end;
  const base = new BigNumber(shift.startingAmount);
  const cash = new BigNumber(shift.cash);
  const debit = new BigNumber(shift.debit);
  const credit = new BigNumber(shift.credit);
  const transfer = new BigNumber(shift.transfer);
  const refunds = new BigNumber(shift.refunds);
  const inPayment = new BigNumber(shift.inPayment);
  const outPayment = new BigNumber(shift.outPayment);
  const totalsTipCash = new BigNumber(shift.tipsTotalCash);
  const totalsTipDebit = new BigNumber(shift.tipsTotalDebit);
  const totalsTipCredit = new BigNumber(shift.tipsTotalCredit);
  const totalsTipTransfer = new BigNumber(shift.tipsTotalTransfer);

  const totalsTipsOther = totalsTipDebit.plus(totalsTipCredit).plus(totalsTipTransfer);

  const cashPaymentSales = new BigNumber(0).plus(cash).plus(debit).plus(credit).plus(transfer);
  // const creditSales = new BigNumber(shift.creditSales);
  const total = new BigNumber(base)
    .plus(cash)
    .plus(debit)
    .plus(credit)
    .plus(transfer)
    .plus(inPayment)
    .plus(totalsTipCash)
    .plus(totalsTipsOther)
    .minus(refunds)
    .minus(outPayment)

  return (
    <div className="py-2 px-2 h-100 d-flex flex-column">

      <div className="shifts__detail-header mb-4 d-flex justify-content-between align-items-center">
        <h1 className="text-capitalize-first text-muted">
          {`${I18n.get('closeNo', 'Cierre No')} ${shift.idLocal}`}
        </h1>

      </div>

      <div className="d-flex flex-column mb-3">
        <div className={`d-flex ${format === '57' ?'flex-column align-items-start' : 'align-items-center'} justify-content-between`} style={{ fontSize: "10px" }}>
          <span className="text-capitalize-first">{I18n.get('date', 'fecha')}</span>
          <span>{!!start ? dayjs.tz(start, timezone).format(I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a')) : ''} - {!!end ? dayjs.tz(end, timezone).format(I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a')) : ''}</span>
        </div>
      </div>

      <div className="d-flex flex-column mb-3">
        {/* <div className="d-flex align-items-center justify-content-between border-bottom py-2" style={{ fontSize: "10px" }}>
          <span className="text-capitalize-first">{I18n.get('immediatePaymentSale', 'Ventas a contado')}</span>
          <span>{cashPaymentSales.toFormat(decimal)}</span>
        </div> */}
        {/* <div className="d-flex align-items-center justify-content-between border-bottom py-2" style={{ fontSize: "10px" }}>
          <span className="text-capitalize-first">{I18n.get('creditPaymentSales', 'Ventas a crédito')}</span>
          <span>{creditSales.toFormat(decimal)}</span>
        </div> */}
        <div className="d-flex align-items-center justify-content-between py-2" style={{ fontSize: "10px" }}>
          <b className="text-capitalize-first">{I18n.get('total', 'total') + ' ' + lowerCase(I18n.get('of', 'de') + ' ' + I18n.get('sales', 'ventas'))}</b>
          <b>{cashPaymentSales.toFormat(decimal, fmt)}</b>
        </div>
      </div>

      <div className="d-flex flex-column mb-3" style={{ fontSize: "10px" }}>
        <div className="d-flex align-items-center justify-content-between border-bottom py-2">
          <span className="text-capitalize-first">{I18n.get('base', 'base inicial')}</span>
          <span>{new BigNumber(base).toFormat(decimal, fmt)}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between border-bottom py-2">
          <span className="text-capitalize-first">{I18n.get('cashSales', 'ventas en efectivo')}</span>
          <div>{cash.toFormat(decimal, fmt)}</div>
        </div>

        <div className="d-flex align-items-center justify-content-between border-bottom py-2">
          <span className="text-capitalize-first">{I18n.get('debitSales', 'ventas por tarjeta de débito')}</span>
          <div>{debit.toFormat(decimal, fmt)}</div>
        </div>

        <div className="d-flex align-items-center justify-content-between border-bottom py-2">
          <span className="text-capitalize-first">{I18n.get('creditSales', 'ventas por tarjeta de crédito')}</span>
          <div>{credit.toFormat(decimal, fmt)}</div>
        </div>

        <div className="d-flex align-items-center justify-content-between border-bottom py-2">
          <span className="text-capitalize-first">{I18n.get('transferSales', 'ventas por transferencias')}</span>
          <div>{transfer.toFormat(decimal, fmt)}</div>
        </div>

        <div className="d-flex align-items-center justify-content-between border-bottom py-2">
          <span className="text-capitalize-first">{I18n.get('refundCash', 'Devolución de dinero')}</span>
          <div>{refunds.eq(0) ? '' : '(-'}{refunds.toFormat(decimal, fmt)}{refunds.eq(0) ? '' : ')'}</div>
        </div>

        <div className="d-flex align-items-center justify-content-between border-bottom py-2">
          <span className="text-capitalize-first">{I18n.get('invoiceCash', 'ingresos de efectivo')}</span>
          <div>{inPayment.toFormat(decimal, fmt)}</div>
        </div>

        {(totalsTipCash.isGreaterThan(0) || totalsTipsOther.isGreaterThan(0)) && 
          <>
            <div className="d-flex align-items-center justify-content-between border-bottom py-2">
              <span className="text-capitalize-first">{I18n.get('shift.tipsCash', 'Propinas en efectivo')}</span>
              <div>{totalsTipCash.toFormat(decimal, fmt)}</div>
            </div>

            <div className="d-flex align-items-center justify-content-between border-bottom py-2">
              <span className="text-capitalize-first">{I18n.get('shift.tipsOther', 'Propinas en otros medios')}</span>
              <div>{totalsTipsOther.toFormat(decimal, fmt)}</div>
            </div>
          </>
        }

        <div className="d-flex align-items-center justify-content-between border-bottom py-2">
          <span className="text-capitalize-first">{I18n.get('expenseCash', 'retiros de efectivo')}</span>
          <div>{outPayment.eq(0) ? '' : '(-'}{outPayment.toFormat(decimal, fmt)}{outPayment.eq(0) ? '' : ')'}</div>
        </div>

        <div className="d-flex align-items-center justify-content-between py-2">
          <b className="text-capitalize-first">{I18n.get('totalOfMovements', 'total de movimientos')}</b>
          <b>{total.toFormat(decimal, fmt)}</b>
        </div>
      </div>

      <div className={`d-flex ${format === '57' ?'flex-column align-items-start' : 'align-items-center'} justify-content-between mb-3`} style={{ fontSize: "12px" }}>
        <span className="text-capitalize-first">{I18n.get('cashInClosingShift', 'dinero en efectivo del cierre')}</span>
        <div>{base.plus(cash).plus(inPayment).plus(totalsTipCash).minus(outPayment).minus(refunds).toFormat(decimal, fmt)}</div>
      </div>

      <div className="d-flex align-items-center justify-content-between" style={{ fontSize: "10px" }}>
        <span className={`text-capitalize-first${!shift.observations ? ' text-muted noselect' : ''}`}>{I18n.get('observations', 'observaciones')}:</span>
        <p>
          {!!shift.observations
            ? shift.observations
            : "-"}</p>
      </div>
    </div>
  )
}

export default ShiftDetail;