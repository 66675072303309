import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash'

import * as queries from '../graphql/queries'
import { handleError } from '../utils/errors'
import { fetch, getInitialState } from '../utils'
import { APIGraphqlSelector } from '../selectors/app';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null
}

export const fetchWarehousesPartial = (api) => async (metadata, params) => {
  const response = await api(graphqlOperation(queries.allWarehouses, {
    includeMetadata: true,
    batch: metadata,
    ...params,
  }));

  const data = get(response, 'data.allWarehouses.data', null)
  const total = get(response, 'data.allWarehouses.metadata.total', 0)
  if (data)
    return { data, total };
  return { data: [], total };
}

export const fetchWarehouses = createAsyncThunk(
  'warehouses/fetch',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      const APIGraphql = APIGraphqlSelector(getState());
      return await fetch(fetchWarehousesPartial(APIGraphql), !!preloaded ? preloaded : [], params)
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'warehouses',
  initialState: getInitialState('warehouses', initialState),
  reducers: {
    addWarehouse: adapter.addOne,
    updateWarehouse: adapter.updateOne,
    removeWarehouse: adapter.removeOne,
  },
  extraReducers: builder => {
    builder.addCase(fetchWarehouses.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchWarehouses.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchWarehouses.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { reducer, actions } = appSlice;

export const { addWarehouse, updateWarehouse, removeWarehouse } = actions

export const warehousesSelector = adapter.getSelectors(state => state.warehouses);

export default reducer;