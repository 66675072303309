import es from "./es";

const dictionary = {
  ...es,
  "aboutPOS": "Conocé tu Punto de venta",
  "actualShiftIsClosed": "Abrí un nuevo turno y continuá gestionando el efectivo de tu negocio",
  "addWarehouse": "Agregar depósito",
  "address": "Domicilio",
  "AlegraAccounting": "Gestión",
  "AlegraAccounting.description": "Reportes, control de inventario y más",
  "AlegraPOS": "Punto de venta",
  "AlegraPOS.description": "Agilizá tus ventas y controlá tu efectivo",
  "AlegraShop": "Tienda",
  "AlegraShop.description": "Creá tu tienda online fácil y rápido",
  "alegraWeekIsHere1.demo": "Elegí tu plan ahora con 50% OFF por 2 meses o seguí disfrutando tus {} {} gratis.",
  "alegraWeekIsHere1.readOnly": "Elegí tu plan hoy y llevátelo con un 50% OFF por 2 meses.",
  "alegraWeekIsHere2": "Compra dos meses de tu plan favorito de Alegra Punto de venta y recibe el",
  "anyDocumentSavedInOffline": "Seguí vendiendo mientras tu {} se guarda o consultá el detalle en el",
  "areYouSureToDeletePendingInvoice": "¿Seguro que querés eliminar la venta pendiente?",
  "areYouSureToVoidInvoice": "Confirma que deseas anular la venta <strong> {} </strong> para que se elimine de tus registros.",
  "AssociatePriceListsToTerminalsNotification.title": "¡Configurá tu lista de precios favorita! 💡",
  "AssociatePriceListsToTerminalsNotification.body": "Podés definir una lista de precios en tu terminal para que venga seleccionada por defecto y ahorrar tiempo al vender.",
  "AssociatePriceListsToTerminalsNotification.action": "Ir a configurarla",
  "bankAccountsTutorial": "Elige el banco al que va a ingresar el dinero según el método de pago.",
  "baseBankHelp": "Elige el banco del cual va a salir el dinero para la base inicial",
  "baseHelp": "Indicá el dinero en efectivo con el que iniciás el turno",
  "cashManagementExpenseHelp": "Selecciona la cuenta contable por defecto para el registro de tus gastos desde el Punto de venta",
  "categoryImage": "Seleccioná o arrastrá una imagen para tu categoría.",
  "categoriesNoDataText": "Creá tu primera categoría fácil y rápido. 🚀",
  "categoriesSubtitle": "Creá categorías para clasificar tus productos o servicios por tipo o cualidades.",
  "checkInInPOS": "Facturación en el Punto de venta",
  "chooseMyPlan": "Elegir mi plan",
  "choosePlanNote1": "(*) Tu plan de Alegra Punto de venta dependerá de tus ingresos mensuales.",
  "client.advancedOptionsSubtitles": "Configurá las condiciones de venta para tu cliente, como vendedor y plazo de pago",
  "client.generalInfoSubtitles": "Ingresá la información principal de tu cliente",
  "clientSyncErrorMessage": "Error en la sincronización del cliente. Conocé <a class='text-danger font-weight-bold' href='https://www.youtube.com/watch?v=Fl51wZ9ugNE' target='_blank'><u>comó editar el cliente</u></a> en esta factura antes de sincronizarla de nuevo",
  "city": "Localidad",
  "companyName": "Nombre de tu negocio",
  "companySetting": "Configurá tu negocio",
  "companySettingInfo": "Ingresa tu información y adapta Alegra Punto de venta a tu negocio",
  "companySettingSubtitle": "Actualizá los datos de tu negocio que van a aparecer en tus facturas",
  "configureStationWarehouse": "Configurar depósito",
  "contactAccountingError": "Algo impidió traer la configuración contable para tu cliente, recargá e intentá crearlo de nuevo.",
  "contactUs": "Contactá a soporte",
  "createClientToSelect": "Creá un nuevo cliente para seleccionarlo",
  "createThisNewClientToSelect": "Creá este nuevo cliente para seleccionarlo",
  "createFirstInvoice": "Crear mi primera factura",
  "createWarehouse": "Crear depósito",
  "createWarehouseError": "Error en la creación del depósito",
  "createItemSubmitError1Message":
    "Desde la sección variantes debes seleccionar por lo menos una opción e indicar la cantidad disponible en depósito de las que elijas.",
  "createKitHelp": "Agrupá varios productos, servicios o una combinación de ambos.",
  "createProductHelp": "Creá los bienes y artículos que vendés e incluí todos sus detalles.",
  "createServiceHelp": "Creá las actividades comerciales o de consultoría que ofrecés a tus clientes.",
  "createVariantHelp": "Creá una nueva característica con sus opciones, por ejemplo diferentes colores o tamaños.",
  "currentShiftDetails.description": "Conocé los movimientos de efectivo en tu turno actual.",
  "customFieldCreatedSuccessfullyMessage": "Tu campo adicional {} ya está disponible para que lo asocies a tus productos.",
  "customFieldNoDataText": "Creá tu primer campo adicional ahora. 🙌",
  "customFieldTypeHelp": "Con esta opción podés definir la estructura y formato de tu campo.",
  "customFieldsInventoryInfo": "Creá campos personalizables y agregá información adicional en tus productos o servicios, como el código de barras.",
  "customFieldsSubtitle": "Creá campos personalizables para agregar información adicional de tus productos o servicios.",
  "dataOfflineSubtitles": "Podés seguir vendiendo y tus ventas se sincronizarán cuando tengas internet",
  "decimalPrecisionTooltip": "Elige el número de decimales que vas a utilizar en los precios de tus productos y valores de tus facturas.",
  "defaultValueHelp.customField": "Podés definir el valor predeterminado que tomará este campo al asociarlo a un producto.",
  "defaultValueHelp.customField.boolean": "Marcá esta opción para que este campo venga activo al asociarlo a uno de tus productos.",
  "deleteInventoryAdjustment.info": "Tené en cuenta que al eliminar el <b> ajuste de inventario No. {} </b> se borrará la variación de cantidades que había generado en tus productos.",
  "deleteInventoryAdjustments.info": "Tené en cuenta que al eliminar los <b> ajustes de inventario seleccionados</b>, se borrará la variación de cantidades que habían generado en tus productos.",
  "discountCalculationHelTooltip": "El descuento se calculará sobre el precio base antes de<br/>impuestos, conocé más sobre",
  "discoverAvailablePlans": "¡Conocé todos los planes disponibles para vos!",
  "distributeInWarehouses": "Distribuir en depósitos",
  "expectedCashHelp": "En caso de ser diferente al esperado en caja podés realizar un ingreso o retiro de efectivo mientras verificás a que se debe.",
  "expectedCashShouldBeEqualToTotal":
    "El valor real en caja debe coincidir con el dinero esperado, podés realizar un ingreso o retiro de efectivo mientras verificás y dejá la observación.",
  "generalInfoTutorial": "Configurá la información principal de tu negocio",
  "happyWeekSubtitle3": "de Alegra Punto de venta y recibe el",
  "here": "acá",
  "howToEmit": "Cómo emitirlas",
  "initAppError": "Ups! El Punto de venta no se ha podido cargar",
  "inventariableHelp": "Distribuye tus productos entre tus depósitos",
  "inventoryAdjustmentstInfo": "Registrá aumentos y disminuciones manuales para controlar las cantidades de tus productos.",
  "inventoryAdjustmentCreatedLocally.title": "¡Se creó como pendiente! 📌",
  "inventoryAdjustmentCreatedLocally.subtitle": "El ajuste se guardará en tu punto de venta cuando tengas conexión.",
  "inventoryAdjustmentDeletedLocally.subtitle": "El ajuste se borrará de forma definitiva en cuanto tengas conexión.",
  "inventoryAdjustmentEditedLocally.subtitle": "Los datos editados del ajuste se guardarán cuando tengas conexión.",
  "inventoryWarehouses": "Dépositos de inventario",
  "invoicePrintFooter": "Generado en Alegra Punto de venta - alegra.com/pos",
  "invoicePrintSetting": "Configurá tu plantilla de impresión",
  "invoicePrintSettingSubtitle": "Elige los parámetros que vas a utilizar y serán visibles en tus facturas.",
  "invoiceSavedInOffline": "Seguí vendiendo mientras tu factura se guarda o consultá el detalle en el",
  "invoiceSyncErrorOfflineSubtitle": "Podrás consultar la factura en el historial de ventas en cuanto recuperés tu conexión.",
  "isMomentToChoseYourPlan": "Es momento de elegir tu plan ideal para seguir vendiendo fácil y rápido en tu local.",
  "item.accountingOptionsSubtitles": "Elegí la cuenta en la que se van a registrar los ingresos de este producto/servicio/combo.",
  "item.advancedOptionsSubtitles": "Agregá el código de barras y la descripción de tus productos",
  "item.electronicFieldsOptionsSubtitles": "Seleccioná los campos que vas a incluir para personalizar tus productos y servicios.",
  "item.generalInfoSubtitles": "Configurá la información principal de tu producto, servicio o combo.",
  "item.inventoryOptionsSubtitles": "Controlá las cantidades de este producto que tenés en diferentes lugares.",
  "item.kitListOptionsSubtitles": "Seleccioná los productos y sus cantidades para armar un combo.",
  "item.priceListOptionsSubtitles": "Agregá diferentes precios a tus productos.",
  "item.variantsListOptionsSubtitles": "Agregá atributos como talle, color u otra cualidad para asociarlos a tus productos.",
  "itemCategoryCreatedSuccessfullyMessage": "La categoría {} ya está disponible para que clasifiqués tus productos.",
  "itemCreatedSuccessfullyMessage": "Tu {} {} ya está disponible en tu cuenta y se encuentra listo para la venta.",
  "itemCategoriesAreEmpty": "Las categorías que seleccionaste están vacías en el depósito de esta terminal. 🔍",
  "itemCategoryIsEmpty": "La categoría {} está vacía en el depósito de esta terminal. 🌪️",
  "itemCreatedSuccessfullyMessageAlter": "Tené en cuenta que no lo asociaste al depósito de tu terminal actual y para venderlo debes ",
  "itemDescriptionHelp": "Clasificá tus productos y servicios por tipos o grupos",
  "itemLimitWarningTitle.kit": "Revisá los productos del combo 🔍",
  "itemLimitWarningTitle.kitAvailable": "¡Podés vender máximo {} combos! ✋",
  "itemManagementInfo": "Creá, editá y administrá cada detalle de los productos o servicios que vendés.",
  "itemMinimumQuantityReached": "Llegó a la cantidad mínima configurada, recordá abastecerte.",
  "knowTheFirstStepsInPOS": "Conoce los primeros pasos en el Punto de venta",
  "limitedPlanNotification": "¡Actualizá tu suscripción y no parés tu facturación!🚨",
  "limitedPlanNotification.description": "Superaste las características de tu plan y a partir del lunes 13 de marzo solo podrás ver la información creada hasta ese día.",
  "limitedPlanNotification.action": "Actualizar suscripción",
  "limitedQueryPlan": "¡Actualizá tu suscripción y no parés tu facturación!🚨",
  "limitedQueryPlan.description": "Te encontrás en un Plan Consulta y a partir del lunes 13 de marzo solo podrás ver la información creada hasta ese día.",
  "limitedQueryPlan.action": "Actualizar suscripción",
  "loadingResourcesSubtitle": "Podés vender mientras cargan tus productos y clientes.",
  "makeSureYouHaveTheNecessaryPermissions": "Asegurate de tener los permisos necesarios para ingresar al Punto de venta",
  "maxQuantityHelp": "Vas a recibir alertas al llegar al stock máximo que indiques para este producto en tu depósito.",
  "minQuantityHelp": "Vas a recibir alertas al llegar al stock mínimo que indiques para este producto en tu depósito.",
  "moreSettingInfo": "Podés encontrar más funcionalidades para tu negocio en Alegra Contabilidad",
  "multicurrencyFieldHelp": "Generá tus facturas del Punto de venta en diferentes monedas",
  "myWarehouses": "mis depósitos",
  "needConnectionToEditAdjustment": "Necesitás tener conexión para editar un ajuste.",
  "newInventoryAdjustmentSubtitle": "Modificá las cantidades de los productos que tenés en el depósito de tu terminal actual.",
  "newItemCategoryModalSubtitle": "Creá una nueva categoría para clasificar tus productos y ubicarlos fácilmente.",
  "newClientSubtitle": "Creá los contactos que asociarás en tus facturas de venta.",
  "newCustomFieldModalSubtitle": "Creá un nuevo campo para agregar información extra de tus productos o servicios.",
  "newItemSubtitle": "Creá los productos o servicios que ofrecés en tu negocio.",
  "newPriceListModalSubtitle": "Creá una lista para utilizar un precio diferente en tus productos o servicios.",
  "newWarehouse": "Nuevo depósito",
  "newWarehouseSubtitle": "Creá todos tus puntos de almacenamiento de tu inventario",
  "noInventoryAdjustmentsFound.subtitle": "Ajustá los filtros para encontrar lo que estás buscando",
  "noSelectedInvoice": "👈 Elige una factura para conocer el detalle o crea una nueva venta ahora.",
  "noStationPermissionWithNameError": "¿Necesitás ingresar a la terminal{}?",
  "noStationPermissionErrorMessage": "Solicitá acceso a un perfil administrador o ingresá desde otra terminal en la que tengas permiso.",
  "indicateTheVariantQuantity": "Indica aquí las cantidades en tus depósitos",
  "itemCreatedSuccessfully": "Ya lo tenés. 🎉",
  "itemVariantHelp": "Indica aquí las cantidades en tus depósitos",
  "learnToUseShifts.link": "https://ayuda.alegra.com/es/turnos-de-caja-alegra-punto-de-venta-argentina",
  "notConfiguredPaymentMethodMessage": "no está configurado aún. Si querés configurarlo debes ir",
  "notInvoiceYet": "Creá tu primera venta en segundos.",
  "noItemsInInventoryAdjustmentError": "Debés agregar al menos un producto a tu ajuste",
  "noSelectedQRPayment": "Selecciona por lo menos un pago de la lista para ver el detalle o crea el primero acá",
  "notPaymentYet": "Creá tu primer movimiento de efectivo para registrar un ingreso o salida de dinero.",
  "notQRPaymentYet": "No has creado tuprimer pago, créalo acá",
  "notRefundYet": "Creá en segundos la primera devolución que te haga un cliente.",
  "numerationsSettingTooltip": 'Administrá desde "Alegra Gestión" las numeraciones que usás para facturar en tu local.',
  "onboarding.readonly.header": "¡Seguí vendiendo fácil y rápido! 😎",
  "onboarding.readonly.message.1": "Ya disfrutaste de tu prueba gratis, podés",
  "onboarding.readonly.message.2": "elegir tu plan ahora",
  "onboarding.readonly.message.3": "y seguir teniendo el control de tus puntos de venta.",
  "onboarding.video.url": "https://www.youtube.com/embed/NWBhaZnzpH8",
  "onboarding.video.id": "NWBhaZnzpH8",
  "onboardingEndSubtitle": "Crear facturas en segundos, controlar tu inventario y gestionar turnos de caja ¡Ya es posible con Alegra Punto de venta! 🙌",
  "onboardingEndTitle": "¡Ahora es tu turno!",
  "onboardingWelcomeSubtitle": "Conocé en este recorrido cómo <span class='font-weight-bold'>crear más rápido tus facturas</span> y empezá a darle superpoderes a tu negocio.",
  "onboardingWelcomeTitle": "¡Te damos la bienvenida a Alegra Punto de Venta! 🤗",
  "onboardingWizardSubtitleStep0": "Con esta información vas a dejar listo tu punto de venta",
  "onboardingWizardSubtitleStep1":
    "En menos de 2 minutos podrías hacerte un mate o conocer cómo ganar tiempo y tranquilidad con tu nuevo sistema de Punto de venta. <span class='font-weight-bold'>¡Dale play!</span>",
  "onboardingWizardTitleStep0": "Completá los datos de tu negocio",
  "onboardingWizardTitleStep1": "¡Ahorrate el controlador fiscal con Alegra!",
  "paymentDeclined8.title": "Actualizá tus datos de pago",
  "paymentDeclined8.text": "Hemos detectado un problema con tu método de pago al realizar el cobro de tu suscripción. <b>¿Querés agregar un nuevo método de pago?</b>",
  "paymentDeclined8.init": "Si necesitás ayuda podés ",
  "paymentDeclined12.text": "No fue posible procesar tu último pago, por favor, <b>agregá un nuevo método de pago</b> para evitar que tu cuenta sea suspendida.",
  "paymentDeclined12.init": "Si necesitás ayuda podés ",
  "paymentDeclined12.link": "contactar a soporte.",
  "paymentDeclined12.action": "Agregar método de pago ahora",
  "paymentMethodHelp": "En Alegra Punto de venta puedes crear facturas de contado, conoce como generar tus facturas a crédito en Alegra Contabilidad",
  "pendingInvoiceSettingsTutorial": "Creá varias facturas a la vez para manejar por ejemplo el control de mesas.",
  "planLimitReachedSubtitle": "Pasate a un plan hecho a tu medida para seguir vendiendo fácil y rápido en tu punto de venta con mayores beneficios.",
  "pos": "Punto de venta",
  "POSSetting": "Funcionalidades Punto de venta",
  "posSettingsSubtitles": "Acá podés configurar los datos que intervienen directamente con el Punto de venta",
  "POSSettingTutorial": "Activá las funcionalidades que vas a utilizar en todas tus terminales",
  "POSStations": "Terminales Punto de venta",
  "POSTutorial": "Hacé un recorrido guiado",
  "priceListCreatedSuccessfully": "Ya podés asociar tu lista de precios. 🎊",
  "priceListsNoDataText": "Creá tu primera lista de precios en un parpadeo. 👀",
  "priceListsHelpTooltip": "Las listas de precio te permiten definir precios especiales para clientes o grupos de productos y servicios. ¡Configuralas desde tu punto de venta!",
  "priceListsKnowMore": "Conocé más",
  "priceListsSubtitle": "Asigná varios precios a tus productos con un valor fijo o un porcentaje de descuento.",
  "printFormatHelp": "Elige el tamaño y las márgenes de tu plantilla en milímetros (mm)",
  "printItemFullLineHelp": "Activá para mostrar el nombre, cantidad y precio en una sola columna.",
  "printTemplateHelp": "Elige la plantilla para la impresión de tus facturas.",
  "printTemplateSettingInfo": "Configurá cómo se van a ver las facturas de tu negocio",
  "printMeasurement": "Incluir la unidad de medida en tus facturas",
  "printMeasurementHelp": "Hacé visible la unidad de medida de tus productos y servicios.",
  "printLines": "Mostrar total de líneas y productos en tus facturas",
  "printLinesHelp": "Incluí el número de líneas de tu factura y el total de productos vendidos.",
  "productDistributionHelp.mid": " cómo distribuir en diferentes depósitos",
  "productWithVariantsHelp": "Indicá si este producto tiene variantes como color, talle u otra cualidad.",
  "refundAccountWarning":
    "Solo se descontarán del valor en caja de tu cierre de turno los valores asociados a la cuenta bancaria del Efectivo del Punto de venta.",
  "renamePendingInvoiceHelp": "Para cambiar el prefijo general tenés que dirigirte a",
  "requiredHelp.customField": "Si activás esta opción, siempre vas a tener que definir un valor para este campo en tus productos.",
  "selectProductHelp": "Agregá acá los productos de tu combo",
  "selectProductsToCreateYourInvoice": "Acá verás los productos que elijas para tu primera venta",
  "sellerCreatedSuccessfullyMessage": "Ya podés asociar en tus facturas a {} como vendedor.",
  "sellersSettingInfo": "Gestioná la información de los integrantes de tu equipo de ventas",
  "sellersSettingSubtitle": "Creá y administrá los integrantes de tu equipo de ventas.",
  "setStationWarehouse": "Configurá un depósito para tu terminal",
  "setStationWarehouseHelp": "Parece que se desactivó o eliminó el depósito que estaba asociado a tu terminal, seleccioná uno para seguir vendiendo.",
  "shiftIsOpenToMuchTime":
    "Tenés un turno abierto hace más de una semana. Te recomendamos cerrarlo para que controlés tus ingresos o desactivá esta opción desde la configuración de tu negocio.",
  "shiftsFieldHelp": "Tené el control de tu dinero utilizando la apertura y cierre de caja.",
  "startTour": "Iniciar recorrido",
  "stationCreatedSuccessfullyMessage": "La terminal {} ya está disponible para que gestionés las ventas de tu negocio.",
  "stationSettingInfo": "Administrá tus puntos de atención y controlá las ventas de cada uno",
  "stationSettingSubtitle": "Configurá los datos de tu terminal y la información necesaria para facturar.",
  "stationsSettingSubtitle": "Creá y administrá las terminales que usás para la gestión de tus puntos de venta.",
  "stationsSettingTitle": "Gestiona tus terminales del Punto de venta",
  "stationWarehouseDeletedError": "Solo podés eliminar depósitos que no estén asociados a una terminal.",
  "stationWarehouseStatusError": "Solo podés desactivar depósitos que no estén asociados a una terminal.",
  "subscriptionIndications":
    "Con Alegra Punto de venta vendes en segundos y haces crecer tu negocio. Conoce a continuación los detalles de tu suscripción:",
  "taxesHelp": "Si tu producto tiene más de un impuesto, seleccioná cada uno de ellos en la lista desplegable.",
  "taxesSettingTooltip": 'Configurá desde "Alegra Gestión" los impuestos que necesitás para facturar en tu punto de venta.',
  "tours.onboarding.content.1": "Acá podés crear productos y servicios que ofrecés en tu punto de venta.",
  "tours.onboarding.content.2": "Pueden ser Consumidores Finales, la opción por defecto, o clientes nuevos.",
  "tours.onboarding.content.3": "Hacé clic en uno o más productos de la visualización a tu izquierda para cobrarlos.",
  "tours.onboarding.content.4": "Con esta opción creás tu factura, elegís el método de pago e imprimís tu recibo.",
  "tours.onboarding.title.1": "¡Creá productos con un clic! 🏷",
  "tours.onboarding.title.2": "Añadí a tus clientes 👥",
  "tours.onboarding.title.3": "👈 Elegí los productos a vender",
  "tours.onboarding.title.4": "¡Boom, tu primera venta! 🎉",
  "updateWarehouse": "Actualizar depósito",
  "updateWarehouseError": "Error al cambiar los datos del depósito",
  "userNotAllowed.contacts.view":
    "No tienes permiso para ver el detalle de contacto. Habla con tu administrador para que te habilite el permiso y así puedas usar el Punto de venta correctamente.",
  "userNotAllowed.warehouses.add": "No tienes permisos para agregar depósitos",
  "userNotAllowed.warehouses.delete": "No tienes permisos para eliminar depósitos",
  "userNotAllowed.warehouses.edit": "No tienes permisos para editar depósitos",
  "variantCreatedSuccessfullyMessage": "Tu variante {} ya está disponible para que la asocies a tus productos.",
  "variantsInventoryInfo": "Configurá atributos que definen las características de tus productos, como color, talle u otra cualidad.",
  "variantsNoDataText": "Creá tu primera variante sin pararte de la silla. 🎨",
  "variantsSubtitle": "Agregá atributos como talle, color u otra cualidad para asociarlos a tus productos.",
  "updateYourPlanNotification": "¡Actualizá tu suscripción y no parés tu facturación!🚨",
  "updateYourPlanNotification.description": "Te encontrás en un Plan Consulta y a partir del lunes 18 de julio solo podrás ver la información creada hasta ese día.",
  "videoIntroductionPOS": "Video introductorio Punto de venta",
  "warehouse": "Depósito",
  "warehouses": "Depósitos",
  "warehouseActivated": "depósito activado con éxito",
  "warehouseCreatedSuccessfullyMessage": "El depósito {} ya está disponible para que distribuyás tu inventario.",
  "warehouseChangeError": "No tienes los permisos para ver productos de otros depósitos.",
  "warehouseChangeInfo": "Cambia de depósito para visualizar los productos presentes en cada uno.",
  "warehouseDeactivated": "depósito desactivado con éxito",
  "warehouseDeleted": "depósito eliminado con éxito",
  "warehouseDeletedError": "error eliminando depósito",
  "warehouseNoDataText": "Creá tu primer depósito en segundos. 🙌",
  "warehouseQuantity": "Cantidad en Depósito",
  "warehouseSetting": "Configuración de depósito",
  "warehousesLoadError": "Sucedió un error cargando los depósitos",
  "warehousesSettingSubtitle": "Administra los depósitos de tu negocio",
  "warehousesInventorySubtitle": "Creá depósitos para gestionar tu inventario en diferentes lugares de almacenamiento y distribución.",
  "warehouseModalSubtitle": "Creá todos los puntos de almacenamiento y distribución de tus productos.",
  "warehouseStatusError": "error cambiando estado del depósito",
  "warehouseUpdated": "depósito actualizado con éxito",
  "warehouse_name": "Depósito",
  "welcomeToAlegraPOS": "¡Bienvenido a Alegra Punto de venta!",
  "welcomeToYourPOSSystem": "¡Bienvenido a tu sistema Punto de venta!",
  "whatYouDo": "Rubro",
  "youHave": "Tenés",
  "youHaveA15DayFreeTrial": "Tenés {} {} gratis para descubrir lo fácil que es gestionar las ventas de tu negocio.",
  "youDontHaveCreatedInventoryAdjustments.title": "¡Tu primer ajuste en un parpadeo! ⚖",
  "youDontHaveCreatedInventoryAdjustments.subtitle": "Mantené actualizada la cantidad de tus productos",
  "youDontHaveCreatedSellers": "No has creado tu primer vendedor, créalo acá.",
  "youDontHaveCreatedStations": "No has creado tu primera terminal, créala acá.",
  "youDontHaveCreatedWarehouses": "No has creado tu primer depósito, créalo acá.",
  "youCanNowMakeElectronicInvoices": "¡Empezá a facturar electrónicamente! 💥",
  "youCanNowMakeElectronicInvoices.description": "Activá la emisión de comprobantes electrónicos y facturá desde tu local. Seguí los pasos del tutorial para empezar hoy.",
  "youHavePendingInvoicesToEmit": "¡Tenés facturas por emitir! 🚨",
  "youHavePendingInvoicesToEmit.description": "Aprendé a identificar las facturas que tenés “Por emitir” y envialas a la AFIP con un clic.",
  "youHavePendingInvoicesToEmit.link": "https://ayuda.alegra.com/es/conoce-estado-emision-facturas-sistema-punto-venta-argentina",
  "youNeedPermissionsToViewList": "Necesitás permiso para ver el listado",
  "youNeedToAskForPermissions.view-numerations": "Para ver el listado de numeraciones, tenés que pedirle el permiso a un administrador.",
  "youRegistred": "Registraste",
  "yourPOSSystem": "tu sistema Punto de venta",
  "youShouldSelectANumeration": "Tenés que seleccionar una numeración, pedile el permiso a un administrador para ver el listado.",
  "offlineNotificationTitle": "¡Estás usando Alegra Punto de venta sin internet!",
  "offlineNotification": "Podés seguir facturando y tus ventas se guardarán al recuperar la conexión.",
  "connectionIssueTitle": "Sin internet en tu local",
  "solutions.description.new": "Más soluciones para tu negocio",
  "AlegraPayroll.new": "Facturación",
  "AlegraPayroll.description.new": "Emití facturas electrónicas con un clic",
  "AlegraAccounting.new": "Gestión",
  "AlegraAccounting.description.new": "Administrá, facturá y ordená tu stock",
  "AlegraPOS.new": "Punto de venta",
  "AlegraPOS.description.new": "Vendé y facturá sin controlador fiscal",
  "AlegraShop.new": "Tienda",
  "AlegraShop.description.new": "Creá tu primera tienda online en 4 clics",
  "electronicPayrollHabilitation": "Conocé cómo activar tu facturación electrónica.",
  "electronicPayrollTutorial.video.id": "gSPTOAuDfK4",
  "goToTutorial": "Ir al tutorial",
  "new_design_tittle": "¡Nuevo diseño en tu punto de venta! 🖌️",
  "new_design_description": "Tu menú cuenta con una imagen y un color renovados para que identifiques cada sección de manera más sencilla.",
  "importProducts.text": "Importar productos",
  "importProducts.tooltip": "Te llevaremos a Alegra Gestión para que los subas de manera masiva.",
  "solutionModal.accounting.supTitle": "Tus cuentas claras con",
  "solutionModal.accounting.buttonMainText": "Probá 15 días gratis",
  "solutionModal.accounting.buttonSecondaryText": "Más información",
  "solutionModal.accounting.description": "Con Alegra Gestión controlás tus ingresos y gastos con un clic, y generás reportes administrativos en tiempo real para que estés siempre al día con la AFIP.",
  "solutionModal.accounting.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.store.supTitle": "Incrementa tus ventas con",
  "solutionModal.store.buttonMainText": "Probá 15 días gratis",
  "solutionModal.store.buttonSecondaryText": "Más información",
  "solutionModal.store.description": "¡Empezá a vender online en segundos!\nCon Alegra Tienda creas tu primera tienda online en solo 4 clics.",
  "solutionModal.store.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.pos.supTitle": "Gestiona tu negocio con",
  "solutionModal.pos.buttonMainText": "Probá 15 días gratis",
  "solutionModal.pos.buttonSecondaryText": "Más información",
  "solutionModal.pos.description": "Con Alegra Punto de Venta facturás electrónicamente en tu local y controlás muy fácil tu inventario.\n¡Pasate a la nueva tecnología y ahorrá!",
  "solutionModal.pos.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "userNotAllowed.edit.invoice": "Necesitás permiso del administrador para editar facturas.",
  "wifi_disabled_description": "Podés seguir vendiendo y tus ventas se sincronizarán cuando tengas conexión.",
  "wifi_enabled_description": "Tenés conexión y todas las funciones están disponibles para hacer crecer tu negocio.",
  "connection_missed_description": "Podés seguir vendiendo mientras la recuperás. Por favor, <b>evitá refrescar la página</b> para conservar los datos de tus ventas y que puedan sincronizarse cuando regrese tu internet.",
  "connection_restored_description": "Podés usar todas las funciones con total normalidad mientras guardamos las ventas que hiciste sin internet.",
  "offline_success_invoice_total": "Total {{total}}",
  "offline_success_invoice_title": "Venta pendiente de sincronizarse",
  "offline_success_invoice_description": "Podés seguir vendiendo y sincronizaremos tus ventas cuando tengas conexión.",
  "numerationElectronicOffline": "Necesitás conexión a internet para crear y enviar facturas electrónicas a la AFIP.",
  "api_failure": "Con intermitencia",
  "api_failured": "⚠ Servicio con intermitencias",
  "api_failure_description": "Podés seguir vendiendo y tus facturas se sincronizarán cuando estabilicemos el servicio. Agradecemos tu comprensión. 🤝",
  "api_intermittences_invoice_title": "Se sincronizará cuando estabilicemos el servicio",
  "api_intermittences_invoice_description": "La factura se creó y quedó pendiente de sincronizarse, la guardaremos por vos cuando finalice la intermitencia. Agradecemos tu comprensión.",
  "headerTopOfflineNotification": "<b>No recargués la página</b> para seguir vendiendo sin conexión y tus ventas se sincronizarán cuando tengas internet.",
  "confirm-delete": "Confirmá que deseas eliminar definitivamente el {{product-type}} <b>{{name}}</b> de tu cuenta.",
  "disabledEditItem": "Necesitás permiso del administrador para editar productos.",
  "disabledDisableItem": "Necesitás permiso del administrador para desactivar productos.",
  "disabledEnableItem": "Necesitás permiso del administrador para activar productos.",
  "disabledDeleteItem": "Necesitás permiso del administrador para eliminar productos.",
  "confirmDeleteProducts": "Confirmá que deseas eliminar definitivamente los <b>{{total}} productos</b> seleccionados de tu cuenta.",
  "massiveDeleteProductsErrorDescription": "Conocé el detalle de los productos que no se eliminaron porque ya los has usado en tu cuenta.",
  "contactSupportDescription": "Ahora vas a encontrar todas tus opciones de ayuda en un mismo lugar, disponibles las 24 horas. ¡Descubrí tu nuevo centro de soporte!",
  "contactSupportTitleNewUsers": "Explorá tus canales de soporte 🧑‍💻",
  "contactSupportDescriptionNewUsers": "¡Descubrí dónde conseguir ayuda cuando la precisés! Explorá nuestros canales de soporte disponibles las 24 horas, todos los días.",
  'onbPhoneMessageValidationError': 'Asegurate de ingresar un teléfono con {} dígitos',
  "offileCreatingProductBlocked": "Necesitás tener conexión para crear productos.",
  "manageYourAccount": "Administrá tu cuenta",
  "manageYourAccountDescription": "Encontrá en un solo lugar las configuraciones que aplican a todas tus soluciones de Alegra",
  "youNeedToIncludePhone": "Necesitás incluir un número de teléfono para el envío",
  "shareInvoice.phone.error": "Ingresá el número telefónico de tu cliente",
  "shareInvoice.whatsapp.message.error": "Ingresá el mensaje que enviarás a tu cliente",
  "shareInvoice.email.subject.error": "Ingresá un asunto para tu correo",
  "createFirstClient": "¡Creá tu primer cliente! 👥",
  "createFirstClientDescription": "Acá podrás gestionar todos los contactos de tu punto de venta.",
  "noContacts": "Sin resultados 🔦",
  "noContactsDescription": "Probá con otro nombre o ajustá los filtros de tu búsqueda.",
  "youNeedPermissions": "Necesitas autorización",
  "youNeedPermissionsDescription": "Tu usuario tiene un perfil limitado, para ingresar a esta sección tenés que pedir permiso a un usuario administrador.",
  "contactsDescription": "Gestioná la información de los clientes que podrás asociar en tus facturas de venta.",
  "autocompleteTestTitle": "Probá el autocompletado ✨",
  "autocompleteTestDescription": "Escribí el CUIT de tu cliente y obtené sus datos de AFIP.",
  "contactsDescription": "Gestioná la información de los clientes que podrás asociar en tus facturas de venta.",
  "deleteClient": "¿Querés borrar este contacto?",
  "deleteClientDescription": "Si confirmás que querés eliminar a <b>{{name}}</b>, se borrará permanentemente de tus contactos.",
  "confirmDeleteContact": "Si, deseo eliminarlo",
  "contactCreatedDescription": "El contacto <b>{{name}}</b> está disponible para que lo selecciones al vender.",
  "warehouseChangeMessage": "Podés cambiar de depósito para revisar los productos de cada uno.",
  "warehouseChangeErrorNew": 'Te falta el permiso de "ver detalle" en depósitos para revisar las demás.',
  "youHavePendingInvoices": "Tenés ventas pendientes de sincronización",
  "goAppDesktopTitle": "Ingresá a Alegra Punto de venta",
  "goAppDesktopDescription": "Elegí cómo querés abrir tu punto de venta",
};

export default dictionary;
