import React from 'react'
import { Field } from 'react-final-form'

import { renderSendEmail } from '../fields/V0/Fields'

const SendInvoiceEmail = ({ invalid, submitting }) => (
  <Field
    name="email"
    component={renderSendEmail}
    type="email"
    disabled={invalid || submitting}
    submitting={submitting}
  />
);

export default SendInvoiceEmail