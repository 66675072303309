import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { get } from 'lodash';

import { filter } from '../../../database/itemsDB';
import { openModal } from '../../../reducers/modals';
import { addItem } from '../../../reducers/activeInvoice';
import { checkFeatureLimit } from '../../../reducers/membership';
import { hasPermissionTo } from '../../../selectors/auth'
import Select from '../../common/Select';
import { Tooltip } from '@alegradev/smile-ui-react';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';

const SearchItem = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo)
  const online = useConnectionStatus();

  return (
    <div className="invoice-top__item px-3 pb-3 shadow d-flex d-sm-none w-100 text-white align-items-center">
      <div className="w-100 px-2">
        <Select
          loadOptions={async text => await filter({ text, categories: [], limit: 10 })}
          defaultOptions
          value={null}
          placeholder={I18n.get('searchItems', 'Buscar items')}
          getOptionLabel={option => {
            return (
              <div className="d-flex align-items-center justify-content-between p-1">
                <p className="h4 text-truncate">
                  {`${option.name}${!!get(option, 'reference.reference') 
                    ? ` (${get(option, 'reference.reference')})`: !!get(option, 'reference')
                    ? ` (${get(option, 'reference')})`: ''}`
                  }
                </p>
              </div>
            )
          }}
          getOptionValue={option => option.id}
          onChange={item => dispatch(addItem(item))}
        />
      </div>

      <Tooltip
        visible={!can('add', 'items') || !online}
        overlay={online ? I18n.get(
          'userNotAllowed.items.add',
          'no tienes permisos para agregar productos'
        ) : I18n.get('offileCreatingProductBlocked', 'Necesitás tener conexión para crear productos.')}
      >
        <button
          id="add-item-button"
          type="button"
          disabled={!can('add', 'items') || !online}
          className="btn button-transparent btn-add-item d-flex justify-content-between align-items-center mr-3"
          onClick={() => dispatch(checkFeatureLimit('items', () => dispatch(openModal({ modal: 'item' }))))}
        >
          <div className="w-100">{I18n.get('newItemTitle', 'Nuevo item')}</div>
        </button>
      </Tooltip>
    </div>
  )
}

export default SearchItem;