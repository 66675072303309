import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from '../utils';

const initialState = {
  activities: []
}

const appSlice = createSlice({
  name: 'economicActivities',
  initialState: getInitialState('economicActivities', initialState),
  reducers: {
    setAllEconomicActivities: (state, action) => {

      const activities = !action.payload ? []
        : action.payload.map(activity => {
          const { id, ...properties } = activity;
          properties.main = properties.main ? properties.main : false;
          return { ...properties }
        })
      return {
        ...state,
        activities: activities.filter(activity => activity.code !== null)
      }
    },
    addEconomicActivity: (state, action) => {
      return {
        ...state,
        activities: [...state.activities, action.payload]
      }
    },
    deleteEconomicActivity: (state, action) => {
      return {
        ...state,
        activities: state.activities.filter(activity => activity.code !== action.payload.code)
      }
    },
    selectedMainActivity: (state, action) => {
      const activities = state.activities.map(currentActivity => {
        const activity = { ...currentActivity };
        if (activity.code === action.payload.code) {
          activity.main = true;
        } else {
          activity.main = false;
        }
        return activity;
      })
      return {
        ...state,
        activities: activities
      }
    }
  }
});

const { actions, reducer } = appSlice;

export const { setAllEconomicActivities, addEconomicActivity, deleteEconomicActivity, selectedMainActivity } = actions;

export const getEconomicActivities = (activities = []) => {
  return async (dispatch) => {
    try {
      dispatch(setAllEconomicActivities(activities))
    } catch (error) {
    }
  }
}

export default reducer;