// usePopulateItems.js

import { useEffect, useRef } from 'react';
import posCacheAPI from '../../../../reducers/posCacheAPI';
import { getItem } from '../../../../database/itemsDB';
import {
  setIsSelectingItems,
  populateItem,
  updateItem,
  updateItemStatusById,
  clearDiscardedItems,
  updateIsAllItemsPopulated,
} from '../../../../reducers/activeRefund';

/**
 * Transforms an item from the invoice into the refund item structure.
 *
 * @param {Object} item - The item from the invoice.
 * @returns {Object} The transformed item.
 */
const transformItemFromInvoice = (item) => ({
  id: item.id,
  name: item.name,
  description: item.description,
  price: [{ price: item.price }],
  quantity: item.quantity,
  discount: item.discount,
  tax: item.tax,
});

/**
 * Checks if an item is loaded by fetching it from the database.
 *
 * @param {number} id - The item ID.
 * @returns {Promise<number|null>} The item ID if not loaded, otherwise null.
 */
const checkIfItemIsLoaded = async (id) => {
  const item = await getItem(Number(id));
  return item ? null : id;
};

/**
 * Fetches items that are not loaded from the invoice.
 *
 * @param {Array} items - The list of items from the invoice.
 * @returns {Promise<Array>} The list of item IDs that are not loaded.
 */
const getItemsNotLoaded = async (items) => {
  const itemsPromises = items.map((item) => checkIfItemIsLoaded(Number(item.id)));
  const itemsNotLoaded = await Promise.all(itemsPromises);
  // Filter out items that are loaded (null)
  return itemsNotLoaded.filter((item) => item !== null);
};

/**
 * Fetches an item from Alegra based on the item ID.
 *
 * @param {Object} params - Parameters for fetching the item.
 * @param {number} params.id - The item ID.
 * @param {number} idCompany - The company ID.
 * @param {Object} user - The user object.
 * @returns {Promise<Object|null>} The fetched item or null if not found.
 */
const getItemFromAlegra = async ({ id }, idCompany, user) => {
  try {
    const response = await posCacheAPI.get(
      `/items/${id}`,
      {},
      {
        idUser: user.idGlobal,
        idCompany: idCompany,
      }
    );

    return response?.data ?? null;
  } catch (error) {
    console.error(`Error fetching item with ID ${id} from Alegra:`, error);
    return null;
  }
};

/**
 * Custom Hook to populate items from the invoice.
 *
 * @param {Object} params - Parameters for populating items.
 * @param {Array} params.itemsInRefund - List of items currently in the refund.
 * @param {Object|null} params.invoiceSelected - The selected invoice.
 * @param {string} params.devolutionType - The type of devolution.
 * @param {Object} params.values - Form values.
 * @param {Function} params.dispatch - Redux dispatch function.
 * @param {Function} params.setLoadingItems - Function to set loading state.
 * @param {number} params.idCompany - The company ID.
 * @param {Object} params.user - The user object.
 */
const usePopulateItems = ({
  itemsInRefund,
  invoiceSelected,
  devolutionType,
  values,
  dispatch,
  setLoadingItems,
  idCompany,
  user,
}) => {
  const hasRun = useRef(false);
  const isLoading = useRef(false);

  useEffect(() => {
    if (hasRun.current || isLoading.current) return;
    hasRun.current = true;
    isLoading.current = true;

    if (itemsInRefund.length) {
      isLoading.current = false;
      return;
    }

    const populateItemsFromInvoice = async () => {
      const populatedInvoice =
        invoiceSelected ||
        ((devolutionType === 'creditToSales' || devolutionType === 'rectificativeInSimplifiedInvoice') ? values?.refund?.document : null);

      if (populatedInvoice && populatedInvoice.items?.length) {
        dispatch(setIsSelectingItems(false));
        setLoadingItems(true);

        try {
          // Fetch items that are not loaded
          const itemsToFetch = await getItemsNotLoaded(populatedInvoice.items);

          // Populate items from the invoice
          populatedInvoice.items.forEach((item, index) => {
            const transformedItem = transformItemFromInvoice(item);

            // Check if the item already exists
            const exists = itemsInRefund.some(
              (existingItem) => existingItem.id === transformedItem.id
            );

            if (!exists) {
              dispatch(populateItem(transformedItem));

              if (item.quantity > 1) {
                dispatch(
                  updateItem({
                    index,
                    values: {
                      quantity: item.quantity,
                    },
                  })
                );
              }
            }
          });

          // Fetch additional items from Alegra if any are not loaded
          if (itemsToFetch.length) {
            const itemsPromises = itemsToFetch.map((itemId) =>
              getItemFromAlegra({ id: itemId }, idCompany, user)
            );
            const results = await Promise.allSettled(itemsPromises);

            results.forEach((result) => {
              if (result.status === 'fulfilled') {
                const item = result.value;
                if (item?.id && item.status === 'inactive') {
                  dispatch(
                    updateItemStatusById({
                      id: String(item.id),
                      status: 'inactive',
                    })
                  );
                }
              } else {
                console.error('Error fetching item from Alegra:', result.reason);
              }
            });
          }

          dispatch(updateIsAllItemsPopulated(true));
        } catch (error) {
          console.error('Error populating items from invoice:', error);
        } finally {
          setLoadingItems(false);
          isLoading.current = false;
        }
      }
    };

    populateItemsFromInvoice();
  }, [
    itemsInRefund.length,
    invoiceSelected,
    devolutionType,
    values,
    dispatch,
    setLoadingItems,
    idCompany,
    user,
  ]);
};

export default usePopulateItems;
