import { I18n } from '@aws-amplify/core';
import { get, includes } from 'lodash';

import { openModal } from '../../../../reducers/modals';


import alegraAPI from '../../../../reducers/alegraAPI';
import { downloadFileFromUrl, printPDFFromUrl } from '../../../../utils/pdf';
import toastHandler from '../../../../utils/toast-handler';
import { globalInvoiceConverterToData } from '../../invoice/converters/globalInvoiceToApi';
import { IconChecklist, IconFileTypePdf, IconPencil, IconTrash } from '@tabler/icons-react';

function globalInvoicesActions({
  row,
  refetch,
  history,
  APIGraphql,
  dispatch,
  mutate,
  mutateCreation,
  numerations,
}) {
  const handleEdit = () => {
    history.push(`/global-invoices/invoice/${row?.id}`);
  };

  const handleDelete = (id) => {
    mutate({ id, api: APIGraphql, dispatch, refetch });
  };

  const handleStamp = () => {
    const values = globalInvoiceConverterToData(row, numerations);

    mutateCreation({
      values: { ...values, isEditable: true },
      api: APIGraphql,
    });
  };

  const handlePrint = async () => {
    if (!get(row, 'id')) return null;
    const toastId = `downdload-file-${get(row, 'id')}`;
    toastHandler(
      {
        type: 'neutral',
        description: I18n.get('printingFile', 'Imprimiendo archivo...'),
        loader: true,
      },
      { toastId }
    );
    try {
      const response = await alegraAPI.get(
        `/global-invoices/${get(row, 'id')}?fields=pdf`
      );
      toastHandler(
        {
          update: true,
          type: 'success',
          description: I18n.get('filePrinted', 'Archivo impreso'),
        },
        { toastId }
      );
      printPDFFromUrl(get(response, 'data.pdf'));
    } catch (error) {
      toastHandler(
        {
          update: true,
          type: 'error',
          description: I18n.get(
            'filePrimtError',
            'Error al imprimir el archivo'
          ),
        },
        { toastId }
      );
    }
  };

  const handleDownloadPDF = async () => {
    if (!get(row, 'id')) return null;
    try {
      const response = await alegraAPI.get(
        `/global-invoices/${get(row, 'id')}?fields=pdf`
      );
      downloadFileFromUrl(
        get(response, 'data.pdf'),
        `Factura global ${get(row, 'numberTemplate.number')}.pdf`
      );
    } catch (_) {}
  };

  if (
    includes(['draft', 'open'], get(row, 'status')) &&
    !get(row, 'stamp.uuid', null)
  ) {
    return [
      {
        icon: IconPencil,
        title: I18n.get('pencil', 'Editar'),
        action: () => handleEdit(),
      },
      {
        icon: IconChecklist,
        title: I18n.get('stamp', 'Timbrar'),
        action: () => handleStamp(),
      },
      {
        icon: IconTrash,
        title: I18n.get('delete', 'Eliminar'),
        disabled: get(row, 'status') === 'open',
        action: () =>
          dispatch(
            openModal({
              modal: 'confirmAction',
              params: {
                onSuccess: () => handleDelete(row?.id),
                title: I18n.get(
                  'cofirmDeleteGlobalInvoiceTitle',
                  '¿Deseas eliminar tu factura global?'
                ),
                description: I18n.get(
                  'cofirmDeleteGlobalInvoiceDescription',
                  'Al confirmar que deseas eliminar, tu <b>Factura global {{invoice}}</b> borrará de forma definitiva de tus documentos'
                ).replace('{{invoice}}', get(row, 'numberTemplate.number')),
                successButton: I18n.get(
                  'cofirmGlobalInvoiceDeletion',
                  'Sí, eliminar factura'
                ),
                cancelButton: I18n.get(
                  'cancelGlobalInvoiceDeletion',
                  'Conservar factura'
                ),
              },
            })
          ),
      },
    ];
  }

  if (get(row, 'stamp.uuid', null) !== null) {
    return [
      {
        icon: IconPencil,
        title: I18n.get('print', 'Imprimir'),
        action: () => handlePrint(),
      },
      {
        icon: IconFileTypePdf,
        title: I18n.get('downloadPDF', 'Descargar PDF'),
        action: () => handleDownloadPDF(),
      },
    ];
  }

  return [];
}

export default globalInvoicesActions;
