import CanVoidStrategy from "./canVoidStrategy";
import { COUNTRIES } from "../../../utils/enums/countries";
import { getHoursBetweenDates, getLocalDate } from "../../../utils";
import { get } from "lodash";
import { isElegibleForApplyRefund } from "../utils";

/**
 * Default strategy for countries without specific voiding logic.
 */
export class DefaultStrategy extends CanVoidStrategy {
  canVoid(invoice, { country, company, isShiftEnabled, isShiftOpen, currentShift, can }) {
    let result =
      get(invoice, 'status') !== 'void' &&
      (!isShiftEnabled ||
        (isShiftEnabled &&
          isShiftOpen === true &&
          get(invoice, 'idShift') === get(currentShift, 'idLocal')));

    result = result && can('void', 'invoices');

    return result;
  }
}

/**
 * Strategy for Mexico-specific voiding logic.
 */
export class MexicoStrategy extends CanVoidStrategy {
  constructor() {
    super();
    this.defaultStrategy = new DefaultStrategy();
  }

  canVoid(invoice, context) {
    const { country } = context;
    if (
      country === COUNTRIES.MEXICO &&
      get(invoice, 'numberTemplate.documentType') === 'invoice'
    ) {
      return false;
    }
    return this.defaultStrategy.canVoid(invoice, context);
  }
}

/**
 * Strategy for Peru-specific voiding logic.
 */
export class PeruStrategy extends CanVoidStrategy {
  constructor() {
    super();
    this.defaultStrategy = new DefaultStrategy();
  }

  canVoid(invoice, context) {
    const { country } = context;
    let result = this.defaultStrategy.canVoid(invoice, context);

    if (country === COUNTRIES.PERU) {
      result = result && !get(invoice, 'numberTemplate.isElectronic');
    }

    return result;
  }
}

/**
 * Strategy for Republica Dominicana-specific voiding logic.
 */
export class RepublicaDominicanaStrategy extends CanVoidStrategy {
  constructor() {
    super();
    this.defaultStrategy = new DefaultStrategy();
  }

  canVoid(invoice, context) {
    const { country } = context;
    let result = this.defaultStrategy.canVoid(invoice, context);

    if (country === COUNTRIES.REPUBLICA_DOMINICANA) {
      result = result && !get(invoice, 'stamp') && !get(invoice, 'numberTemplate.isElectronic');
    }

    return result;
  }
}

/**
 * Strategy for Panama-specific voiding logic.
 */
export class PanamaStrategy extends CanVoidStrategy {
  constructor() {
    super();
    this.defaultStrategy = new DefaultStrategy();
  }

  canVoid(invoice, context) {
    const { country } = context;
    let result = this.defaultStrategy.canVoid(invoice, context);

    if (country === COUNTRIES.PANAMA) {
      const hasStamp = get(invoice, 'stamp') && get(invoice, 'stamp.date');
      const isElectronic = get(invoice, 'numberTemplate.isElectronic');
      if (hasStamp && isElectronic) {
        const hours = getHoursBetweenDates(
          get(invoice, 'stamp.date'),
          getLocalDate(new Date(), country)
        );
        result = result && hours <= 180;
      }
    }

    return result;
  }
}

/**
 * Strategy for Colombia-specific voiding logic.
 */
export class ColombiaStrategy extends CanVoidStrategy {
  constructor() {
    super();
    this.defaultStrategy = new DefaultStrategy();
  }

  canVoid(invoice, context) {
    const { country, company, can } = context;
    let result = this.defaultStrategy.canVoid(invoice, context);

    if (country === COUNTRIES.COLOMBIA) {
      const numerationIsElectronic = get(invoice, 'numberTemplate.isElectronic');
      const invoiceEmissionAccepted = get(invoice, 'stamp.legalStatus', '').includes(
        'STAMPED_AND_ACCEPTED'
      );

      if (
        isElegibleForApplyRefund(invoice, country, company) &&
        invoiceEmissionAccepted
      ) {
        result = result && true;
      } else if (numerationIsElectronic) {
        result = result && false;
      }
    }

    return result;
  }
}

/**
 * Strategy for Spain-specific voiding logic.
 */
export class SpainStrategy extends CanVoidStrategy {
  constructor() {
    super();
    this.defaultStrategy = new DefaultStrategy();
  }

  canVoid(invoice, context) {
    const { country, company } = context;
    let result = this.defaultStrategy.canVoid(invoice, context);

    if (country === COUNTRIES.SPAIN) {
      const numerationIsElectronic = get(invoice, 'numberTemplate.isElectronic');

      if (isElegibleForApplyRefund(invoice, country, company)) {
        result = result && true;
      } else if (numerationIsElectronic) {
        result = result && false;
      }
    }

    return result;
  }
}