import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useNavigationMenu } from './context';
import Typography from '../Typography';
import Tag from '../Tag';
import { Icon, Tooltip } from '@alegradev/smile-ui-react';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

/**
 * Represents an individual navigation item option within a menu.
 *
 * @component
 * @param {object} props - The properties passed to the NavigationItemOption component.
 * @param {string} props.menuName - The name of the menu this item belongs to.
 * @param {string} props.title - The title or label of the navigation item.
 * @param {string} props.to - The target route or URL for the navigation item.
 * @param {string} props.disabledTooltip - If the item is disabled this is the text on tooltip
 * @param {function} props.action - The action to perform when the item is clicked.
 * @param {JSX.Element} props.icon - The icon element for the navigation item.
 * @param {boolean} props.hasSubmenu - Whether the item has a submenu.
 * @param {object} props.extra - Extra information associated with the navigation item.
 * @param {boolean} props.disabled - Whether the navigation item is disabled.
 * @param {boolean} props.isSubItem - Whether the item is a submenu item.
 * @param {elementType} props.component - A custom component to render as the item.
 * @param {string} props.target - The target attribute for the navigation link.
 * @param {JSX.Element} props.extraIcon - An extra icon element associated with the item.
 * @param {boolean} props.isMenu - Whether the item represents a menu.
 * @param {Array} props.items - An array of sub-items for submenu navigation.
 *
 * @returns {JSX.Element} A React element representing the navigation item option.
 */
function NavigationItemOption({
  menuName,
  title,
  to,
  action,
  icon,
  hasSubmenu,
  extra,
  disabled,
  isSubItem = false,
  component,
  target,
  extraIcon,
  isMenu = true,
  items,
  hover = true,
  disabledTooltip,
  dataTestId,
  errorIndicator = false
}) {
  const { currentMenu, menu, setMenuOptions } = useNavigationMenu();
  const tooltipVisible = disabled && disabledTooltip;
  const history = useHistory();

  const handleClick = () => {
    if(disabled) return;
    
    if (to) {
      if (target === '_blank') {
        window.open(to, '_blank');
      } else {
        history.push(to);
      }
    }

    if (isMenu) {
      if (isSubItem) {
        setMenuOptions({
          name: menu.name,
          submenu: menuName,
        });
      } else {
        if (!hasSubmenu) {
          setMenuOptions({
            name: menuName,
            expandable: false,
            submenu: '',
          });
        } else {
          setMenuOptions({
            name: menuName,
            expandable: hasSubmenu
              ? menu.name !== menuName
                ? true
                : !menu.expandable
              : false,
          });
        }
      }
    }

    if (action) {
      
      action();
    }
  };

  const containerType = () => {
    if (disabled) return 'menu-submenu-disabled';
    if (hasSubmenu) {
      if (items.some((item) => item.menuName === menu.submenu)) {
        if (menu.expandable && menu.name === menuName)
          return `menu-submenu-item ${hover ? 'menu-submenu-item-hover' : ''}`;
        return 'menu-submenu-open';
      }
    }

    if (menu.submenu === menuName) return 'menu-submenu-open';

    if (currentMenu === menuName && !hasSubmenu) return 'menu-submenu-open';

    return `menu-submenu-item ${hover ? 'menu-submenu-item-hover' : ''}`;
  };

  if (component) {
    return (
      <div
        className={`menu-submenu-item ${
          hover ? 'menu-submenu-item-hover' : ''
        }`}
      >
        {component}
      </div>
    );
  }

  return (
    <Tooltip
      overlay={disabledTooltip}
      visible={!!tooltipVisible}
      placement='bottom'
    >
      <div onClick={handleClick} className={containerType()} data-testid={dataTestId}>
        <div className='menu-submenu-item-info'>
          {icon && icon}
          <Typography text={title} type='body-3-regular' variant='secondary' />
        </div>
        {hasSubmenu && (
          <>
            {menu.name === menuName && menu.expandable ? (
              <Icon icon={IconChevronUp} className='icon-tertiary' size='small' />
            ) : (
              <Icon icon={IconChevronDown} className='icon-tertiary' size='small' />
            )}
          </>
        )}
        {extra && <Tag text={extra?.title} variant={extra?.variant} />}
        {extraIcon && extraIcon}
        {errorIndicator && <Icon icon='info-circle' size='medium' color='#E11D48'/>}
      </div>
    </Tooltip>
  );
}

NavigationItemOption.propTypes = {
  menuName: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string,
  action: PropTypes.func,
  icon: PropTypes.element,
  hasSubmenu: PropTypes.bool,
  extra: PropTypes.shape({
    title: PropTypes.string,
    variant: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  isSubItem: PropTypes.bool,
  component: PropTypes.object,
  target: PropTypes.string,
  extraIcon: PropTypes.element,
  isMenu: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      menuName: PropTypes.string,
      action: PropTypes.func,
      title: PropTypes.string,
      to: PropTypes.string,
      extra: PropTypes.shape({
        title: PropTypes.string,
        variant: PropTypes.string,
      }),
      component: PropTypes.object,
    })
  ),
};

export default NavigationItemOption;
