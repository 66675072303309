import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { graphqlOperation } from '@aws-amplify/api';
import { get } from 'lodash'

import * as queries from '../graphql/queries'
import { handleError } from '../utils/errors'
import { APIGraphqlSelector, station as stationSelector } from '../selectors/app'
import { updateStation } from './app'
import { getInitialState } from '../utils';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null
}

export const fetchStations = createAsyncThunk(
  'stations/fetch',
  async (_, { dispatch, getState, fulfillWithValue, extra }) => {
    const maxAttempts = get(extra, 'maxAttempts', 2);
    const APIGraphql = APIGraphqlSelector(getState());

    try {
      const data = await APIGraphql(graphqlOperation(queries.allStations));
      const stations = get(data, 'data.allStations', [])
      if (!!stations) {
        const storedStation = stationSelector(getState())

        const updatedStation = !!storedStation
          ? stations.find(station =>
            station.id === storedStation.id && station.idCompany === storedStation.idCompany
          ) : null

        if (!!updatedStation)
          dispatch(updateStation(updatedStation))

        return stations;
      }
      return []
    } catch (error) {

      let result;
      if (get(error, 'errors.0.errorType', '') === "Runtime.ExitError" && maxAttempts > 0) {
        result = await dispatch(fetchStations({ ...extra, maxAttempts: maxAttempts - 1 }));
      }
      if (!!get(result, 'payload'))
        return fulfillWithValue(result.payload);
      throw handleError(error);
    }
  }
)

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'stations',
  initialState: getInitialState('stations', initialState),
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(fetchStations.fulfilled, adapter.setAll)
  }
});

const { reducer } = appSlice;

export const stationsSelector = adapter.getSelectors(state => state.stations);

export default reducer;