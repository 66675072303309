import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@aws-amplify/core';
import RefundDetails from './RefundDetails';

/**
 * Spain-specific Refund Tbody Component.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.refund - The refund object.
 * @param {boolean} props.isElectronic - Flag indicating if the refund is electronic.
 * @param {Function} props.selectStatusColor - Function to select status color.
 * @param {string} props.dateFormat - Date format string.
 * @param {string} props.formattedDate - Formatted date string.
 * @param {boolean} props.isTicketBaiEnabled - Flag to determine if TicketBAI is enabled.
 * @param {string} props.country - The country code.
 * @returns {JSX.Element} - Rendered tbody for Spain.
 */
const RefundBodySpain = ({
  refund,
  isElectronic,
  selectStatusColor,
  dateFormat,
  isTicketBaiEnabled,
  country,
}) => {
  return (
    <>
      {isElectronic && (
        <RefundDetails
          refund={refund}
          statusColor={selectStatusColor(refund)}
          dateFormat={dateFormat}
          isTicketBaiEnabled={isTicketBaiEnabled}
          country={country}
        />
      )}
      {!isElectronic && refund?.cause && (
        <tr>
          <td className='caption-bold'>{I18n.get('reasonRefund', 'Razón')}</td>
          <td>{refund.cause}</td>
        </tr>
      )}
    </>
  );
};

RefundBodySpain.propTypes = {
  refund: PropTypes.object.isRequired,
  isElectronic: PropTypes.bool.isRequired,
  selectStatusColor: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  isTicketBaiEnabled: PropTypes.bool.isRequired,
  country: PropTypes.string.isRequired,
};

export default RefundBodySpain;
