import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { clear } from '../../../../reducers/activeRefund';
import { RectificativeInSimplifiedInvoice } from '../methods/RectificativeInSimplifiedInvoice';
import { OrdinaryInvoiceMethods } from '../methods/OrdinaryInvoiceMethods';

/**
 * Mapping of refund methods to their corresponding components.
 */
const methodComponents = {
  rectificativeInSimplifiedInvoice: RectificativeInSimplifiedInvoice,
  rest: OrdinaryInvoiceMethods,
  errorOrModificationOfTaxableAmount: OrdinaryInvoiceMethods,
  contest: OrdinaryInvoiceMethods,
  uncollectibleDebt: OrdinaryInvoiceMethods,
};

/**
 * ColombiaForm Component
 *
 * Manages the refund form specific to Colombia by rendering different components based on the selected refund method.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.method - The selected refund method.
 * @param {Function} props.onChooseMethod - Callback invoked when a refund method is selected.
 * @param {Function} props.changeStep - Callback to change the current step in the refund process.
 * @param {Function} props.reset - Callback to reset the form.
 * @returns {JSX.Element} - The rendered ColombiaForm component.
 */
const SpainForm = ({ method, onChooseMethod, changeStep, reset }) => {
  const dispatch = useDispatch();

  /**
   * Handles the change event when a new refund method is selected.
   *
   * @param {Object} option - The selected option from the dropdown.
   * @returns {Object} - The selected option.
   */
  const handleMethodChange = useMemo(
    () => (option) => {
      if (option && option.value) {
        onChooseMethod(option.value);
        reset();
        dispatch(clear());
      }
      return option;
    },
    [onChooseMethod, reset, dispatch]
  );

  /**
   * Determines which refund method component to render based on the selected method.
   */
  const SelectedMethodComponent = useMemo(() => {
    return methodComponents[method] || null;
  }, [method]);

  return (
    <div className='h-100 mt-4'>
      <div className='refund-form-body'>
        {SelectedMethodComponent && (
          <SelectedMethodComponent changeStep={changeStep} method={method} />
        )}
      </div>
    </div>
  );
};

SpainForm.propTypes = {
  method: PropTypes.oneOf([
    'cash',
    'creditToSales',
    'combined',
    'positiveBalance',
    'rectificativeInSimplifiedInvoice',
  ]).isRequired,
  onChooseMethod: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default React.memo(SpainForm);
