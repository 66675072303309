import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from '../utils';

const initialState = {
  componentBox: null,
  componentTop: null,
};

const appSlice = createSlice({
  name: 'notificationsWC',
  initialState: getInitialState('notificationsWC', initialState),
  reducers: {
    setNotificationContent: (state, action) => {
      state[action.payload.webcomponent] = {
        hasContent: action.payload.hasContent
      };
    },
    clearAllNotificationContent: () => {
      return initialState;
    },
  },
});

const { actions, reducer } = appSlice;

export const { setNotificationContent, clearAllNotificationContent } = actions;

export default reducer;
