import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import * as totalToCollectDB from '../database/totalToCollectDB';
import { getInitialState } from '../utils';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null,
};

export const getAllTotalToCollect = createAsyncThunk(
  'totalToCollect/getAllTotalToCollect',
  async (_, { rejectWithValue, getState }) => {
    try {
      const data = await totalToCollectDB.getAll();
      return { data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addTotalToCollect = createAsyncThunk(
  'totalToCollect/addTotalToCollect',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      await totalToCollectDB.put(data);
      dispatch(refresh());
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const refresh = createAsyncThunk(
  'totalToCollect/refresh',
  async (_, { rejectWithValue, getState }) => {
    try {
      const data = await totalToCollectDB.getAll();
      return { data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'totalToCollect',
  initialState: getInitialState('totalToCollect', initialState),
  reducers: {
    createTotalToCollect: adapter.addOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTotalToCollect.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllTotalToCollect.fulfilled, (state, action) => {
        state.isLoading = false;
        adapter.setAll(state, action.payload.data);
      })
      .addCase(getAllTotalToCollect.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(refresh.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(refresh.fulfilled, (state, action) => {
        state.isLoading = false;
        adapter.setAll(state, action.payload.data);
      })
      .addCase(refresh.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

const { reducer, actions } = appSlice;

export const totalToCollectSelector = adapter.getSelectors(
  (state) => state.totalToCollect
);

export default reducer;
