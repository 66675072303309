import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import { openModal } from '../../reducers/modals';
import { hasPermissionTo } from '../../selectors/auth';
import { companySelector, country as countrySelector } from '../../selectors/company';
import { capitalize } from 'lodash';
import { sendGTMEvent } from '../../reducers/company';
import { isUserElegibleForNewRefund } from './utils';
import { Tooltip } from '@alegradev/smile-ui-react';

const EmptyDetail = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo)
  const country = useSelector(countrySelector)
  const company = useSelector(companySelector)

  const getSeeMore = country => {
    switch (country) {
      case "mexico":
        return 'https://ayuda.alegra.com/es/crea-devoluciones-a-tickets-de-venta-desde-tpv-mex';
      default:
        return 'https://ayuda.alegra.com/es/crea-devoluciones-de-tus-productos-desde-el-pos-de-alegra';
    }
  }

  return (
    <div className="p-4 w-100 h-100 d-flex align-items-center justify-content-center overflow-hidden">
      <div className="d-flex flex-column align-items-center card p-5">
        <p className="text-capitalize-first h3 mb-3 text-center">
          {I18n.get('notRefundYet', 'aún no tienes devoluciones creadas desde POS')}{' '}
          <a className="btn-link" href={getSeeMore(country)} target="_blank" rel="noreferrer">
            {capitalize(I18n.get('seeMore', 'ver mas'))}
          </a>
        </p>

        <Tooltip
          visible={!can('add', 'credit-notes')}
          overlay={I18n.get('userNotAllowed.credit-notes.add', 'no tienes permisos para agregar devoluciones')}
        >
          <button
            type="button"
            disabled={!can('add', 'credit-notes')}
            className="btn btn-submit"
            onClick={() => {
              isUserElegibleForNewRefund(country, company)
                ? dispatch(openModal({ modal: 'newRefunds' }))
                : dispatch(openModal({ modal: 'refunds' }))
              dispatch(sendGTMEvent("new-return-attempted", {
                creationStatus: "success",
              }))
            }}
          >
            {I18n.get('newRefund', 'nueva devolución')}
          </button>
        </Tooltip>
      </div>
    </div>
  )
}

export default EmptyDetail;