import React, { useRef, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import DatePicker from 'react-datepicker';
import MultiDatePicker from 'react-multi-date-picker';
import dayjs from 'dayjs';
import countries from '../../../countriesData/general/countries';
import OnlyInvoicingTooltip from '../../../common/Tooltip/OnlyInvoicing';
import Select, { SelectWithIcon } from '../../../common/Select';
import Switch from '../../../common/Switch';
import { countryByVersion } from '../../../../utils';
import Button from '../../../common/Button';
import Typography from '../../../common/Typography';
import "react-multi-date-picker/styles/layouts/mobile.css"

import { Icon, Tooltip } from '@alegradev/smile-ui-react';

import {
  DATE_PICKER_LOCALE_EN,
  DATE_PICKER_LOCALE_ES,
} from '../utils/date-picker-locales';
import { useSelector } from 'react-redux';
import { languageSelector } from '../../../../selectors/auth';
import { useDeviceType } from '../../../../hooks/useDeviceType';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { IconAlertTriangle, IconCalendar, IconChevronDown, IconChevronUp, IconHelp, IconInfoCircle, IconLoader2, IconMail, IconSearch, IconStar, IconX } from '@tabler/icons-react';
import WindowedSelect from '../../../common/WindowedSelect';

const baseElement = ({
  field,
  meta,
  className,
  label,
  help,
  helpTooltip,
  warning,
  required,
  input,
  headText,
  ...props
}) => {
  const { touched, error } = meta;

  let isErrorIdentificationNumberCol = false;
  if (
    label === I18n.get('identification', 'identificación') &&
    props.country === 'colombia'
  ) {
    if (
      error ===
      I18n.get(
        'onlyNumericValuesAreSupported',
        'Solo se admiten valores numéricos'
      )
    ) {
      isErrorIdentificationNumberCol = true;
    }
  }
  return (
    <div
      className={`form-group ${isErrorIdentificationNumberCol
        ? 'has-error'
        : touched && error
          ? 'has-error'
          : ''
        } ${!!className ? className : ''} ${input.type === 'date' ? 'input-date-picker' : ''
        }`}
    >
      <div className='form-group__label'>
        {!!headText && (
          <div className='d-flex align-items-center'>
            <h4 htmlFor={input.name}>{headText}</h4>
          </div>
        )}

        {!!label && (
          <div className='label-wrapper d-flex align-items-center'>
            <label
              htmlFor={input.name}
              className={` h3 ${required ? 'required' : ''}`}
            >
              {label}
            </label>
            {!!helpTooltip && (
              <Tooltip
                overlay={helpTooltip}
              >
                <Icon icon={IconHelp} size='small' type='primary' />
              </Tooltip>
            )}
          </div>
        )}

        {!!help && (
          <p className='text-muted h5 text-capitalize-first'>{help}</p>
        )}

        {field}

        {!!warning && (
          <p className='h5 text-capitalize-first text-warning py-2'>
            {warning}
          </p>
        )}

        {!touched &&
          error &&
          isErrorIdentificationNumberCol &&
          !props.hideErrorLabel && (
            <p
              className={`h5 text-capitalize-first text-danger py-2 ${props.errorRight ? 'text-right' : ''
                }`}
            >
              {error}
            </p>
          )}

        {props?.showDateError && error && !props.hideErrorLabel && (
          <p
            className={`h5 text-capitalize-first text-danger py-2 ${props.errorRight ? 'text-right' : ''
              }`}
          >
            {error}
          </p>
        )}

        {touched && error && !props.hideErrorLabel && (
          <p
            className={`h5 text-capitalize-first text-danger py-2 ${props.errorRight ? 'text-right' : ''
              }`}
          >
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

export const RenderLabel = ({
  className,
  label,
  help,
  helpTooltip,
  required,
}) => {
  return (
    <div className={`form-group ${!!className ? className : ''}`}>
      <div className='form-group__label'>
        {!!label && (
          <div className='label-wrapper d-flex align-items-center'>
            <label className={` h3 ${required ? 'required' : ''}`}>
              {label}
            </label>
            {!!helpTooltip && (
              <Tooltip
                overlay={helpTooltip}
              >
                <Icon icon={IconHelp} size='small' type='primary' />
              </Tooltip>
            )}
          </div>
        )}

        {!!help && (
          <p className='text-muted h5 text-capitalize-first'>{help}</p>
        )}
      </div>
    </div>
  );
};

export const renderField = (props) => {
  let field = null;
  if (props.input.type === 'textarea') {
    field = (
      <textarea
        {...props.input}
        className={`form-control ${props.small ? 'small-textarea' : ''}`}
        disabled={props.disabled}
        placeholder={props.placeholder}
        data-testid={props['data-testid']}
        onBlur={(e) => {
          if (!!props.onBlur) props.onBlur();
          props.input.onBlur(e);
        }}
      />
    );
  }
  if (props.input.type === 'text' || props.input.type === 'number') {
    field = (
      <div
        className='position-relative d-flex align-items-center justify-content-center input-number-wrapper'
        style={{ background: `${props.disabled ? '#e9ecef' : ''}` }}
      >
        <input
          {...props.input}
          className={`form-control${!!props.customClassName ? ' ' + props.customClassName : ''
            }`}
          disabled={props.disabled}
          placeholder={props.placeholder}
          data-testid={props['data-testid']}
          onWheel={(e) => e.target.blur()}
          onBlur={(e) => {
            if (!!props.onBlur) props.onBlur();
            props.input.onBlur(e);
          }}
          onChange={(values) => {
            if (!!props.onChange) values = props.onChange(values);
            if (!!props.input.onChange) props.input.onChange(values);
          }}
          onKeyDown={(values) => {
            if (!!props.onKeyDown) values = props.onKeyDown(values);
            if (!!props.input.onKeyDown) props.input.onKeyDown(values);
          }}
          style={{
            height: props.height || '3.4rem',
            fontSize: props.fontSize || '14px',
            border: 'none',
          }}
        />
        {props.iconDelete && (
          <span
            role='button'
            onClick={props.onDeleteField}
            className='position-absolute'
            style={{
              top: '15%',
              bottom: '20%',
              right: 5,
              backgroundColor: '#FFF',
            }}
          >
            <Icon icon={IconX} extraClass='icon-gray' />
          </span>
        )}
        {props.incrementIcon && (
          <div className='d-flex flex-column'>
            <button
              type='button'
              onClick={() => props.onIncrement()}
              disabled={props.disabled}
              className='btn-transparent-inline d-flex align-items-center m-0'
            >
              <Icon icon={IconChevronUp} extraClass='icon-gray' />
            </button>
            <button
              type='button'
              onClick={() => props.onDecrement()}
              disabled={props.disabled}
              className='btn-transparent-inline d-flex align-items-center'
            >
              <Icon icon={IconChevronDown} extraClass='icon-gray m-0' />
            </button>
          </div>
        )}
      </div>
    );
  }
  return baseElement({ ...props, field });
};

export const renderGeneralSearchField = (props) => {
  let field = null;
  if (props.input.type === 'text' || props.input.type === 'number') {
    field = (
      <div
        className='position-relative d-flex align-items-center justify-content-center input-number-wrapper'
        style={{
          background: 'white',
          borderRadius: '8px',
          padding: '8px 0px 8px 12px',
          height: props.height ?? '40px',
        }}
      >
        <div
          className='d-flex justify-content-center align-items-center'
          style={{
            background: '#FFF',
            borderRadius: '8px',
            border: 'none',
          }}
        >
          <Icon icon={IconSearch} color='#0F172A' size='medium' />
        </div>
        <input
          {...props.input}
          className={`form-control${!!props.customClassName ? ' ' + props.customClassName : ''
            }`}
          disabled={props.disabled}
          placeholder={props.placeholder}
          data-testid={props['data-testid']}
          onWheel={(e) => e.target.blur()}
          onBlur={(e) => {
            if (!!props.onBlur) props.onBlur();
            props.input.onBlur(e);
          }}
          onChange={(values) => {
            if (!!props.onChange) values = props.onChange(values);
            if (!!props.input.onChange) props.input.onChange(values);
          }}
          style={{
            fontSize: props.fontSize || '16px',
            border: 'none',
            borderRadius: '8px',
          }}
        />
      </div>
    );
  }
  return baseElement({ ...props, field });
};

export const renderTextSearchField = (props) => {
  let field = null;

  if (props.input.type === 'text') {
    field = (
      <div className='text-search-field-input'>
        <Icon
          icon={IconSearch}
          color='#64748B'
          size='small'
          extraClass='text-search-field-input-icon'
        />
        <input
          {...props.input}
          className={`form-control${!!props.customClassName ? ' ' + props.customClassName : ''
            }`}
          disabled={props.disabled}
          placeholder={props.placeholder}
          data-testid={props['data-testid']}
          onWheel={(e) => e.target.blur()}
          onBlur={(e) => {
            if (!!props.onBlur) props.onBlur();
            props.input.onBlur(e);
          }}
          onChange={(values) => {
            if (!!props.onChange) values = props.onChange(values);
            if (!!props.input.onChange) props.input.onChange(values);
          }}
        />
        {props.loading && (
          <Icon icon={IconLoader2} animated extraClass=' icon-primary text-search-field-input-loading' />
        )}
      </div>
    );
  }
  return baseElement({ ...props, field });
};

const FastOptionsCalendarPlugin = ({ value, onPluginClick }) => {
  return (
    <div className='custom-date-picker-fast-options-container'>
      <div
        className='custom-date-picker-fast-options-container-item'
        onClick={() =>
          onPluginClick({ label: 'Hoy', value: dayjs().format('YYYY-MM-DD') })
        }
      >
        <Typography text='Hoy' variant='secondary' type='body-3-regular' />
      </div>
      <div
        className='custom-date-picker-fast-options-container-item'
        onClick={() =>
          onPluginClick({
            label: 'Ayer',
            value: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
          })
        }
      >
        <Typography text='Ayer' variant='secondary' type='body-3-regular' />
      </div>
      <div
        className='custom-date-picker-fast-options-container-item'
        onClick={() =>
          onPluginClick({
            label: 'Esta semana',
            value: dayjs().startOf('week').format('YYYY-MM-DD'),
          })
        }
      >
        <Typography
          text='Semana pasada'
          variant='secondary'
          type='body-3-regular'
        />
      </div>
      <div
        className='custom-date-picker-fast-options-container-item'
        onClick={() =>
          onPluginClick({
            label: 'Últimos 30 días',
            value: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
          })
        }
      >
        <Typography
          text='Últimos 30 días'
          variant='secondary'
          type='body-3-regular'
        />
      </div>
      <div
        className='custom-date-picker-fast-options-container-item'
        onClick={() =>
          onPluginClick({
            label: 'Últimos 60 días',
            value: dayjs().subtract(60, 'day').format('YYYY-MM-DD'),
          })
        }
      >
        <Typography
          text='Últimos 60 días'
          variant='secondary'
          type='body-3-regular'
        />
      </div>
      <div
        className='custom-date-picker-fast-options-container-item'
        onClick={() =>
          onPluginClick({
            label: 'Últimos 90 días',
            value: dayjs().subtract(90, 'day').format('YYYY-MM-DD'),
          })
        }
      >
        <Typography
          text='Últimos 90 días'
          variant='secondary'
          type='body-3-regular'
        />
      </div>
      <div
        className='custom-date-picker-fast-options-container-item'
        onClick={() =>
          onPluginClick({
            label: 'Último año',
            value: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
          })
        }
      >
        <Typography
          text='Último año'
          variant='secondary'
          type='body-3-regular'
        />
      </div>
    </div>
  );
};
export const renderMultiDatePickerField = (props) => {
  const language = useSelector(languageSelector);
  const deviceType = useDeviceType();
  const datePickerRef = useRef();
  const [value, setValue] = useState({
    partial: [],
    current: [],
    label: '',
  });

  const locale = language.includes('es')
    ? DATE_PICKER_LOCALE_ES
    : DATE_PICKER_LOCALE_EN;

  const handlePluginOnClick = (fastOptionValue) => {
    setValue({
      ...value,
      partial: [new Date(fastOptionValue.value), new Date()],
      current: [new Date(fastOptionValue.value), new Date()],
      label: fastOptionValue.label,
    });
    props.onChange([new Date(fastOptionValue.value), new Date()],);
    datePickerRef.current.closeCalendar();
  };

  const field = (
    <MultiDatePicker
      {...props}
      className={`custom-date-picker ${deviceType === 'mobile' && 'rmdp-mobile'
        }`}
      value={value.current}
      onChange={(date) => {
        if (Array.isArray(date) && date.length === 2) {
          setValue({
            ...value,
            partial: [new Date(date[0]), new Date(date[1])],
            label: `${format(new Date(date[0]), 'dd/MM/yyyy')} - ${format(
              new Date(date[1]),
              'dd/MM/yyyy'
            )}`,
          });
        }
      }}
      range
      numberOfMonths={props.numberOfMonths ?? 1}
      highlightToday={false}
      shadow={false}
      weekStartDayIndex={1}
      showOtherDays
      locale={locale}
      calendarPosition='bottom'
      maxDate={new Date()}
      monthYearSeparator=' '
      plugins={
        props.fastOptions
          ? [
            <FastOptionsCalendarPlugin
              position='left'
              value={value}
              onPluginClick={handlePluginOnClick}
            />,
          ]
          : []
      }
      render={(_, openCalendar) => (
        <div className='custom-input-multi-calendar' onClick={openCalendar}>
          <div className='d-flex align-items-center'>
            <Icon icon={IconCalendar} />
            <Typography
              text={value.label || props?.placeholder}
              type='body-2-regular'
            />
          </div>
          <Icon icon={IconChevronDown} />
        </div>
      )}
      ref={datePickerRef}
      mobileLabels={{
        OK: I18n.get('applyFilter', 'Aplicar filtro'),
        CANCEL: I18n.get('cancel', 'Cancelar'),
      }}
    >
      {/* {props?.showHeader && <FilterHeader
        title={I18n.get('customPeriod', 'Periodo personalizado')}
        action={props?.closeAction}
      />} */}

      <div className='custom-date-picker-actions'>
        <Button
          size='sm'
          variant='ghost'
          buttonType='default'
          onClick={() => [
            datePickerRef.current.closeCalendar(),
            setValue({ ...value, partial: [] })
          ]}
        >
          <Typography
            type='label-2'
            text={I18n.get('cancel', 'Cancelar')}
            variant='secondary'
          />
        </Button>
        <Button
          size='sm'
          variant='filled'
          buttonType='default'
          disabled={value.partial.length < 2}
          onClick={() => [
            props.onChange(value.partial),
            setValue({ ...value, partial: [], current: value.partial }),
            datePickerRef.current.closeCalendar(),
          ]}
        >
          <Typography
            type='label-2'
            text={I18n.get('applyFilter', 'Aplicar filtro')}
          />
        </Button>
      </div>
    </MultiDatePicker>
  );

  return baseElement({ ...props, field });
};

export const renderDatePickerField = (props) => {
  const language = useSelector(languageSelector);
  const deviceType = useDeviceType();
  const datePickerRef = useRef();

  const locale = language.includes('es')
    ? DATE_PICKER_LOCALE_ES
    : DATE_PICKER_LOCALE_EN;

  const field = (
    <MultiDatePicker
      {...props?.input}
      {...props}
      className={`custom-date-picker custom-date-picker-container-input ${props?.hideYear && props.hideMonth && 'no-picker-header'
        } ${deviceType === 'mobile' && 'rmdp-mobile'}`}
      inputClass='custom-date-picker-input'
      highlightToday={false}
      shadow={false}
      weekStartDayIndex={1}
      showOtherDays
      format={props?.inputFormat}
      locale={locale}
      calendarPosition='bottom'
      maxDate={new Date()}
      minDate={new Date(props?.minDate || '')}
      monthYearSeparator=' '
      ref={datePickerRef}
      showDateError
      mobileLabels={{
        OK: I18n.get('applyFilter', 'Aplicar filtro'),
        CANCEL: I18n.get('cancel', 'Cancelar'),
      }}
    />
  );

  return baseElement({ ...props, showDateError: true, field });
};

export const renderMonthPickerField = (props) => {
  const language = useSelector(languageSelector);
  const deviceType = useDeviceType();
  const datePickerRef = useRef();

  const locale = language.includes('es')
    ? DATE_PICKER_LOCALE_ES
    : DATE_PICKER_LOCALE_EN;

  const field = (
    <MultiDatePicker
      {...props?.input}
      {...props}
      className={`custom-date-picker ${props?.hideYear && props.hideMonth && 'no-picker-header'
        } ${props.noHeader && 'custom-date-picker-container-input'} ${deviceType === 'mobile' && 'rmdp-mobile'
        }`}
      inputClass={
        props.noHeader ? 'custom-date-picker-input' : props.inputClass
      }
      format='MMMM'
      highlightToday={false}
      shadow={false}
      onlyMonthPicker
      numberOfMonths={1}
      hideMonth={true}
      maxDate={new Date()}
      locale={locale}
      multiple={false}
      calendarPosition='bottom'
      ref={datePickerRef}
      mobileLabels={{
        OK: I18n.get('applyFilter', 'Aplicar filtro'),
        CANCEL: I18n.get('cancel', 'Cancelar'),
      }}
    >
      {/* {!props.noHeader && (
        <div className='custom-date-picker-header'>
          <FilterHeader
            title={I18n.get('month', 'Mes')}
            action={props?.closeAction}
          />
        </div>
      )} */}
    </MultiDatePicker>
  );

  return baseElement({ ...props, showDateError: true, field });
};

export const renderFieldDV = (props) => {
  let field = (
    <div className='position-relative'>
      <input
        {...props.input}
        className='form-control'
        disabled={props.disabled}
        placeholder={props.placeholder}
        data-testid={props['data-testid']}
        onWheel={(e) => e.target.blur()}
        onBlur={(e) => {
          if (!!props.onBlur) props.onBlur();
          props.input.onBlur(e);
        }}
        onChange={(values) => {
          if (!!props.onChange) values = props.onChange(values);
          if (!!props.input.onChange) props.input.onChange(values);
        }}
        style={{
          height: props.height || '3.6rem',
          fontSize: props.fontSize || '14px',
        }}
      />
      {props.isLoading && (
        <div
          className='position-absolute d-flex justify-content-center align-items-center'
          style={{ top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <Icon icon={IconLoader2} animated extraClass=' icon-primary' />
        </div>
      )}
    </div>
  );
  return baseElement({ ...props, showDateError: true, field });
};

export const renderSearchField = (props) => {
  let field = (
    <div className='position-relative'>
      <input
        // {...props.input}
        ref={props.inputRef}
        className='form-control'
        disabled={props.disabled}
        placeholder={props.placeholder}
        data-testid={props['data-testid']}
        onWheel={(e) => e.target.blur()}
        onFocus={(e) => {
          props.input.onChange('');
          props.onViewOptions();
          if (!!props.onFocus) props.onFocus();
          props.input.onFocus(e);
        }}
        onBlur={(e) => {
          if (props.isOnTheListActivities) return;
          if (!!props.onBlur) props.onBlur();
          props.input.onBlur(e);
        }}
        onChange={(values) => {
          props.onSearchByTerm(values);
        }}
      />
      <div
        className='container-icon-search'
        onClick={() => {
          props.inputRef.current.focus();
        }}
      >
        <Icon icon={IconSearch} extraClass='icon-primary' />
      </div>

      <div
        className={`search-activities ${!props.showActivities ? 'd-none' : 'd-block'
          }`}
        onMouseEnter={() => props.setIsOnTheListActivities(true)}
        onMouseLeave={() => {
          props.inputRef.current.blur();
          props.setIsOnTheListActivities(false);
        }}
      >
        <div>
          {props.selectedActivities.length > 0 && (
            <div className='search-activities__selected'>
              {props.selectedActivities.map((activity, index) => (
                <div
                  className='search-activities__selected__container'
                  key={index}
                >
                  <span
                    onClick={() =>
                      !activity.main && props.selectedFavorite(activity)
                    }
                  >
                    <Icon icon={IconStar}
                      extraClass={activity.main ? 'activity-favorite' : ''}
                    />
                  </span>
                  <p className='m-0 py-1'>{activity.value}</p>
                  <span onClick={() => props.deleteActivity(activity)}>
                    <Icon icon={IconX} />
                  </span>
                </div>
              ))}
            </div>
          )}

          {props.showMessageDelete && (
            <div
              className='d-flex align-items-center justify-content-center'
              style={{
                borderLeft: '1px solid #ccc',
                borderRight: '1px solid #ccc',
              }}
            >
              <div
                style={{ backgroundColor: '#FFD772' }}
                className='d-flex align-items-center justify-content-center p-1 mr-2'
              >
                <Icon icon={IconAlertTriangle} extraClass='svg-icon-warning-delete' />
              </div>
              <p className='m-0' style={{ color: '#FF9800', fontSize: '11px' }}>
                {I18n.get(
                  'messageDeleteMainActivity',
                  'Antes de eliminar esta actividad debes marcar la que quedara como preferida.'
                )}
              </p>
            </div>
          )}
        </div>

        <div className='px-4 py-3 search-activities__list list-checkbox'>
          {props.options.map((option, index) => (
            <div key={index} className='py-2'>
              <label className='container-checkbox pl-5'>
                {`${option.key} - ${option.value}`}
                <input
                  type='checkbox'
                  ref={props.refs.current[index]}
                  onChange={props.onChange}
                  name={option.key}
                  value={option.value}
                  id={index}
                />
                <span
                  className='checkmark'
                  style={{ left: '0px', bottom: '0px' }}
                ></span>
              </label>
            </div>
          ))}
          {props.options.length === 0 && (
            <div className='d-flex justify-content-center align-items-center'>
              <p>
                {I18n.get(
                  'noResultsWereFound',
                  'No se encontraron resultados.'
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return baseElement({ ...props, field });
};

export const renderWindowedSelect = (props) => {
  const { touched, error } = props.meta;

  let field = (
    <OnlyInvoicingTooltip visible={!!props.onlyInvoicingTooltip}>
      <WindowedSelect
        {...props.input}
        showFavoriteIcon={props.showFavoriteIcon}
        height={props.height || '3.6rem'}
        fontSize={props.fontSize || '14px'}
        hasError={(touched && error) || props.hasError}
        options={props.options}
        placeholder={props.placeholder || I18n.get('select', 'Seleccionar')}
        value={props.input.value}
        onBlur={(e) => {
          if (!!props.onBlur) props.onBlur();
          if (!!props.input.onBlur) props.input.onBlur(e);
        }}
        noOptionsMessage={!!props.noOptionsMessage ? props.noOptionsMessage : () => I18n.get('noResultsWereFound', 'No se encontraron resultados.')}
        isMulti={props.isMulti}
        getOptionLabel={props.getOptionLabel}
        getOptionValue={props.getOptionValue}
        menuPosition={!!props.menuPosition ? props.menuPosition : 'fixed'}
        isDisabled={props.disabled}
        isSearchable={props.isSearchable}
        isLoading={props.isLoading}
        isClearable={true}
        backspaceRemovesValue={false}
        showClearIndicator={props.showClearIndicator}
        hideDropdownIndicator={props.hideDropdownIndicator}
        hideNoOptionMessage={props.hideNoOptionMessage}
        loadOptions={props.loadOptions}
        cacheOptions={props.cacheOptions}
        defaultOptions={props.defaultOptions}
        selectAction={props.addOptionAction}
        selectActionText={props.addOptionText}
        selectFavorite={props.selectFavorite}
        onInputChange={props.onInputChange}
        selectInfo={props.selectInfo}
        placeholderColor={props.placeholderColor}
        optionAdditionalInfo={props.optionAdditionalInfo}
        onChange={(values) => {
          if (!!props.onChange) values = props.onChange(values);
          if (!!props.input.onChange) props.input.onChange(values);
        }}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
    </OnlyInvoicingTooltip>
  );

  return baseElement({ ...props, field });
};

export const renderSelect = (props) => {
  const { touched, error } = props.meta;

  let field = (
    <OnlyInvoicingTooltip visible={!!props.onlyInvoicingTooltip}>
      <Select
        {...props.input}
        showFavoriteIcon={props.showFavoriteIcon}
        height={props.height || '3.6rem'}
        fontSize={props.fontSize || '14px'}
        hasError={(touched && error) || props.hasError}
        options={props.options}
        placeholder={props.placeholder || I18n.get('select', 'Seleccionar')}
        value={props.input.value}
        onBlur={(e) => {
          if (!!props.onBlur) props.onBlur();
          if (!!props.input.onBlur) props.input.onBlur();
          e.preventDefault();
        }}
        noOptionsMessage={!!props.noOptionsMessage ? props.noOptionsMessage : () => I18n.get('noResultsWereFound', 'No se encontraron resultados.')}
        isMulti={props.isMulti}
        getOptionLabel={props.getOptionLabel}
        getOptionValue={props.getOptionValue}
        menuPosition={!!props.menuPosition ? props.menuPosition : 'fixed'}
        isDisabled={props.disabled}
        isSearchable={props.isSearchable}
        isLoading={props.isLoading}
        isClearable={true}
        backspaceRemovesValue={false}
        showClearIndicator={props.showClearIndicator}
        hideDropdownIndicator={props.hideDropdownIndicator}
        hideNoOptionMessage={props.hideNoOptionMessage}
        loadOptions={props.loadOptions}
        cacheOptions={props.cacheOptions}
        defaultOptions={props.defaultOptions}
        selectAction={props.addOptionAction}
        selectActionText={props.addOptionText}
        selectFavorite={props.selectFavorite}
        onInputChange={props.onInputChange}
        selectInfo={props.selectInfo}
        placeholderColor={props.placeholderColor}
        optionAdditionalInfo={props.optionAdditionalInfo}
        onChange={(values) => {
          if (!!props.onChange) values = props.onChange(values);
          if (!!props.input.onChange) props.input.onChange(values);
        }}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
    </OnlyInvoicingTooltip>
  );

  return baseElement({ ...props, field });
};

export const renderSelectActivities = (props) => {
  const { options } = props;

  const field = (
    <SelectWithIcon
      defaultValue={props.input.value}
      formatOptionLabel={(props) => (
        <div style={{ display: 'flex' }} className='m-0'>
          <p className='m-0'>
            {props.code} - {props.value}{' '}
            {props.main && (
              <span>
                <Icon icon={IconStar} extraClass='svg-icon-primary' />
              </span>
            )}
          </p>
        </div>
      )}
      options={options}
      onChange={props.input.onChange}
    />
  );

  return baseElement({ ...props, field });
};

export const renderIdentificationNumberField = (props) => {
  const field = (
    <div
      className='position-relative field-with-action'
      data-testid={props['data-testid']}
    >
      <input
        className='form-control'
        id={props.input.name}
        {...props.input}
        disabled={props.disabled}
        style={{ paddingRight: !!props.searchLabel ? '11rem' : '4.5rem' }}
        onBlur={(e) => {
          if (!!props.onBlur) props.onBlur();
          props.input.onBlur(e);
        }}
      />

      <button
        className='btn btn-primary px-3 d-flex align-items-center position-absolute justify-content-center'
        disabled={
          !props.onSearchIdentification || props.searchingIdentification
        }
        onClick={props.onSearchIdentification}
        type='button'
        title={props.searchHelp}
      >
        {props.searchingIdentification ? (
          <Icon icon={IconLoader2} animated extraClass=' icon-white' />
        ) : (
          <>
            <Icon icon={IconSearch} extraClass='icon-white' />
            {props.searchLabel}
          </>
        )}
      </button>
    </div>
  );

  return baseElement({ ...props, field });
};

export const renderSendEmail = (props) => {
  const field = (
    <div
      className='position-relative field-with-action'
      data-testid={props['data-testid']}
    >
      <input
        className='form-control'
        id={props.input.name}
        {...props.input}
        disabled={props.disabled}
        style={{ paddingRight: '10rem' }}
      />

      <button
        style={{
          right: 0,
          top: 1,
          height: '3.4rem',
        }}
        className='btn btn-submit d-flex align-items-center justify-content-center position-absolute'
        disabled={props.disabled}
        type='submit'
      >
        {props.submitting ? (
          <Icon icon={IconLoader2} animated extraClass=' icon-white' />
        ) : (
          <>
            <Icon icon={IconMail} extraClass='icon-white' />
            {I18n.get('send', 'enviar')}
          </>
        )}
      </button>
    </div>
  );

  return baseElement({ ...props, field });
};

export const renderPhone = (props) => {
  const getValue = () => {
    const country = isEmpty(props.phoneCountry)
      ? countries.find(
        (c) =>
          c.dialCode === props.input.value.split('.')[0] &&
          c.key === countryByVersion(props.country).key
      ) ||
      countries.find((c) => c.dialCode === props.input.value.split('.')[0])
      : countries.find((c) => c.key === props.phoneCountry.key);
    return country;
  };
  const field = (
    <div
      data-testid={props['data-testid']}
      style={{
        display: 'flex',
      }}
    >
      <div
        style={{
          width: '180px',
          paddingRight: '5px',
        }}
      >
        <Select
          name={props.input.name + '.prefix'}
          options={countries}
          value={
            getValue()
          }
          getOptionLabel={(c) => (
            <p
              className='m-0 d-flex justify-content-between'
              style={{
                width: '6.25rem',
              }}
            >
              <span class={`fi fi-${c.flag}`} />
              <span className={c.dialCode.length <= 3 ? 'mr-3' : 'm-0'}>
                {c.dialCode}
              </span>
            </p>
          )}
          getOptionValue={(c) => c.key}
          height={props.height || '3.6rem'}
          fontSize={props.fontSize || '14px'}
          hideDropdownIndicator={props.hideDropdownIndicator}
          menuPosition='fixed'
          menuPlacement='top'
          placeholder=''
          onChange={(value) => {
            props.onChangeValue(value)
            props.input.onChange(
              value.dialCode + '.' + props.input.value.split('.')[1]
            );
          }}
        />
      </div>

      <input
        className='form-control'
        id={props.input.name}
        {...props.input}
        value={props.input.value.split('.')[1]}
        onChange={({ target }) => {
          const sanitizedValue = target.value.replace(/[^0-9]/g, '');

          props.input.onChange(
            props.input.value.split('.')[0] + '.' + sanitizedValue
          );
        }}
        type='tel'
        pattern='[0-9]{3}-[0-9]{2}-[0-9]{3}'
        disabled={props.disabled}
      />
    </div>
  );

  return baseElement({ ...props, field });
};

export const switchCheckbox = (props) => (
  <div className={`form-group ${!!props.className ? props.className : ''}`}>
    <div
      className={`check-input ${!!props.position ? props.position : 'left'}`}
    >
      <OnlyInvoicingTooltip visible={!!props.onlyInvoicingTooltip}>
        <div
          className='switch-component'
          onClick={() =>
            !props.disabled ? props.input.onChange(!props.input.value) : null
          }
        >
          <Switch
            active={props.input.value}
            position={props.position}
            disabled={props.disabled}
          />
        </div>
      </OnlyInvoicingTooltip>
      <div className='label'>{props.label}</div>
    </div>
  </div>
);

export const renderPriceListType = (props) => {
  const field = (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div className='col-2 p-0 mr-3'>
        <div className='d-flex align-items-center justify-content-start'>
          <div onClick={() => props.input.onChange('percentage')}>
            <button
              type='button'
              className={`btn btn-${props.input.value === 'percentage' ? 'submit' : 'cancel'
                } text-capitalize-first mr-3`}
              active={(props.input.value === 'percentage').toString()}
              position={props.position}
            >
              {I18n.get('percentage', 'Porcentaje')}
            </button>
          </div>
        </div>
      </div>

      <div className='col-2 p-0 ml-3'>
        <div className='d-flex align-items-center justify-content-start'>
          <div onClick={() => props.input.onChange('amount')}>
            <button
              type='button'
              className={`btn btn-${props.input.value === 'amount' ? 'submit' : 'cancel'
                } text-capitalize-first ml-3`}
              active={props.input.value === 'amount'}
              position={props.position}
            >
              {I18n.get('value', 'Valor')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return baseElement({ ...props, field });
};

export const renderCheckbox = (props) => {
  const inheritHelpTooltip = props.hasOwnProperty('inheritHelpTooltip')
    ? props.inheritHelpTooltip
    : true;
  let field = (
    <div
      className={`d-flex align-items-center 
      ${props.position === 'left'
          ? 'flex-row-reverse justify-content-end'
          : 'justify-content-start'
        }
      ${props.justify === 'between' ? 'justify-content-between' : ''}
    `}
    >
      {props.switchLabelOnLeft && (
        <label className={`h3 ${props.labelExtraCSS}`}>
          {props.switchLabel}
        </label>
      )}
      <OnlyInvoicingTooltip visible={!!props.onlyInvoicingTooltip}>
        <div
          className={props.position === 'left' ? 'ml-2' : 'mr-2'}
          onClick={() =>
            !props.disabled ? props.input.onChange(!props.input.value) : null
          }
        >
          <Switch active={props.input.value} disabled={props.disabled} />
        </div>
      </OnlyInvoicingTooltip>
      {!props.switchLabelOnLeft && (
        <label className={`h3 ${props.labelExtraCSS}`}>
          {props.switchLabel}
        </label>
      )}
      {!!props.helpTooltip && inheritHelpTooltip && (
        <Tooltip
          overlay={props.helpTooltip}
        >
          <Icon icon={IconInfoCircle} extraClass='icon-primary' size='small' />
        </Tooltip>
      )}
    </div>
  );

  return baseElement({ ...props, field });
};

export const renderRadioButtons = (props) => {
  let field = (
    <div className='d-flex align-items-sm-center'>
      {props.options.map((option, index) => (
        <div
          key={index}
          className={`d-flex align-items-end mb-3 ${index < props.options.length - 1 ? 'mr-4' : ''
            }`}
        >
          <div className={props.position === 'left' ? 'ml-3' : 'mr-3'}>
            {option.label}
          </div>
          <input
            {...props.input}
            value={option.value}
            checked={option.value === props.input.value}
            type='radio'
          />
        </div>
      ))}
    </div>
  );

  return baseElement({ ...props, field });
};

export const renderSmileRadioButtons = (props) => {
  let field = (
    <div className='d-flex align-items-sm-center'>
      {props.options.map((option, index) => {
        const id = `radio-button-${index}-${props.input.name}`;
        return (
          <div
            key={id}
            className={`position-relative w-50 ${index < props.options.length - 1 ? 'mr-4' : ''}`}
          >
            <input
              id={id}
              {...props.input}
              value={option.value}
              type='radio'
              checked={option.value === props.input.value}
            />
            <label htmlFor={id}>
              <Typography
                text={option.label}
                type='label-2'
                variant='secondary'
              />
            </label>
          </div>
        );
      })}
    </div>
  );

  return baseElement({ ...props, field });
};

export const renderListCheckbox = (props) => {
  let field = (
    <div className='px-4 py-3 list-checkbox'>
      {props.options.map((option, index) => (
        <div key={index}>
          <label className='container-checkbox'>
            {I18n.get(option.value, option.value)}
            <input
              type='checkbox'
              ref={props.refs.current[index]}
              onChange={props.onChange}
              value={option.value}
              name={option.value}
            />
            <span className='checkmark'></span>
          </label>
        </div>
      ))}
    </div>
  );

  return baseElement({ ...props, field });
};

export const renderFileUpload = (props) => {
  let field = (
    <div className='d-flex'>
      <div className='position-relative' style={{ flex: 1 }}>
        <input
          type='file'
          accept={props.accept}
          hidden='hidden'
          id='file'
          ref={props.fileRef}
          onChange={props.onFileChange}
        />
        <input
          {...props.input}
          className='form-control'
          disabled={props.disabled}
          placeholder={props.placeholder}
          data-testid={props['data-testid']}
          onWheel={(e) => e.target.blur()}
          onBlur={(e) => {
            if (!!props.onBlur) props.onBlur();
            props.input.onBlur(e);
          }}
          onChange={(values) => {
            if (!!props.onChange) values = props.onChange(values);
            if (!!props.input.onChange) props.input.onChange(values);
          }}
          style={{
            height: props.height || '3.6rem',
            fontSize: props.fontSize || '14px',
            backgroundColor: '#FFF',
          }}
        />
        <span
          role='button'
          onClick={props.onDeleteField}
          className='position-absolute'
          style={{
            top: '15%',
            bottom: '20%',
            right: 5,
            backgroundColor: '#FFF',
          }}
        >
          <Icon icon={IconX} className='icon-gray' />
        </span>
      </div>
      <button
        type='button'
        className='btn btn-cancel text-capitalize-first mr-2'
        onClick={props.uploadFile}
      >
        {I18n.get('examiner', 'Examinar')}
      </button>
    </div>
  );
  return baseElement({ ...props, field });
};

export const renderDate = (props) => {
  let field = (
    <DatePicker
      {...props}
      portalId={
        props?.portalId
          ? props?.portalId
          : props?.fromModal
            ? ''
            : 'root-portal'
      }
      selected={
        !!dayjs(props.input.value).isValid()
          ? dayjs(props.input.value).toDate()
          : false
      }
      onChange={(values) => {
        if (!props.disabled) {
          if (!!props.onChange) values = props.onChange(values);
          if (!!props.input.onChange) props.input.onChange(values);
        }
      }}
      dateFormat={I18n.get('dateFormatDateFNS', 'dd/MM/yyyy')}
      locale={props.language || 'es'}
      placeholderText={
        props.placeholder ?? I18n.get('noDate', 'Elige una fecha')
      }
      showYearDropdown
    />
  );

  return baseElement({ ...props, field });
};

export const renderDynamicField = ({ fieldType, ...props }) => {
  switch (fieldType) {
    case 'text':
      return renderField({
        ...props,
        input: {
          ...props.input,
          type: 'text',
        },
      });
    case 'int':
      return renderField({
        ...props,
        input: {
          ...props.input,
          type: 'number',
        },
      });
    case 'decimal':
      return renderField({
        ...props,
        input: {
          ...props.input,
          type: 'number',
        },
      });
    case 'date':
      return renderDate({
        ...props,
        input: {
          ...props.input,
          type: 'date',
        },
      });
    case 'boolean':
      return renderCheckbox(props);
    case 'optionsList':
      try {
        const options = Array.isArray(props.options) ? props.options : JSON.parse(props.options);
        return renderSelect({
          ...props,
          options: options.map((option) => ({ value: option, label: option })),
        });
      } catch (err) {
        return null;
      }
    default:
      return null;
  }
};
