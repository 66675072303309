import { Button, useDeviceType, useModal } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { syncOffline } from '../../../reducers/activeInvoice';
import { useHistory } from 'react-router-dom';
import { sendNewGTMEvent } from '../../../reducers/company';

const BottomSection = ({
  closeWithoutSyncing,
  setCloseWithoutSyncing,
  hasError,
  confirmationCodeInput,
}) => {
  const deviceType = useDeviceType();

  const dispatch = useDispatch();
  const history = useHistory();

  const { closeModal } = useModal();

  const redirectAndSync = () => {
    const eventName = closeWithoutSyncing
      ? 'pos-confirmation-logout'
      : 'pos-warning-logout';
    const status = 'synchronize';

    dispatch(
      sendNewGTMEvent(eventName, {
        status,
      })
    );

    history.push('/invoices');
    dispatch(syncOffline(true));
    closeModal('logoutConfirm');
  };

  const handleCloseWithoutSyncing = () => {
    dispatch(
      sendNewGTMEvent('pos-warning-logout', {
        status: 'confirmationLogout',
      })
    );
    setCloseWithoutSyncing(true);
  };

  return (
    <div
      className={`d-flex mt-5 gap-3 ${
        deviceType === 'mobile' ? 'flex-column' : ''
      }`}
    >
      {!closeWithoutSyncing ? (
        <>
          <Button
            variant='ghost'
            emphasis='text'
            full
            text={I18n.get('closeWithoutSyncing', 'Cerrar sin sincronizar')}
            onClick={handleCloseWithoutSyncing}
          />
          <Button
            full
            text={I18n.get('syncInvoices', 'Sincronizar ventas')}
            rightIcon='refresh'
            onClick={redirectAndSync}
          />
        </>
      ) : (
        <>
          <Button
            type='submit'
            variant='destructive'
            emphasis='filled'
            full
            text={I18n.get('confirmLogout', 'Confirmar cierre de sesión')}
            disabled={hasError || !confirmationCodeInput}
          />
          <Button
            emphasis='outline'
            full
            text={I18n.get('syncInvoices', 'Sincronizar ventas')}
            onClick={redirectAndSync}
          />
        </>
      )}
    </div>
  );
};

export default BottomSection;
