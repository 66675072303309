import React from 'react'
import { I18n } from '@aws-amplify/core';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { get, has } from 'lodash'

import { calculateItemsValues } from '../../../utils';
import { companySelector, country as countrySelector, decimalPrecision, decimalSeparator as decimalSeparatorSelector} from '../../../selectors/company';
import { current as currentShift } from '../../../selectors/shifts';
import { renderField } from '../../forms/fields/V0/Fields';
import { getFmt } from '../utils';

import { Icon } from '@alegradev/smile-ui-react';
import { membershipACSelector } from '../../../selectors/membership';
import { IconInfoCircle } from '@tabler/icons-react';

const Payments = ({ invoice, values, form }) => {
  const decimal = useSelector(decimalPrecision);
  const shift = useSelector(currentShift);
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const membership = useSelector(membershipACSelector)
  const decimalSeparator = useSelector(decimalSeparatorSelector);

  const tipAmount = get(invoice, 'additionalCharges[0].amount', 0)
  const tip = {
    include: tipAmount > 0,
    value: tipAmount,
    type: "VALUE"
  }

  let received = new BigNumber(0)
    .plus(new BigNumber(get(values, 'payments.cash', 0)))
    .plus(new BigNumber(get(values, 'payments.debit', 0)))
    .plus(new BigNumber(get(values, 'payments.credit', 0)))
    .plus(new BigNumber(get(values, 'payments.transfer', 0)))
  const itemsValues = calculateItemsValues({items: values.items,membership, decimal, country, company, tips: tip});

  const fmt = getFmt(null, values.currency, decimalSeparator);

  const current_payments = {}
  const _payments = get(invoice, 'payments', []) || [];
  
  _payments.forEach(p => {
    if (has(p, 'paymentMethod'))
      current_payments[p.paymentMethod] = Number(get(p, 'amount', 0));
  });

  const isInvoiceShiftOpen = get(shift, 'idLocal', null) === get(invoice, 'idShift', null) && get(shift, 'status', null) === 'open'; 
  
  return (
    <div className="detail-payments-table-wrapper">
      {
        <p className={`text-capitalize-first text-warning text-small 
          ${!isInvoiceShiftOpen &&
              ( Number(get(values, 'payments.cash', 0)) !== get(current_payments, 'cash', 0)
                || Number(get(values, 'payments.debit', 0)) !== get(current_payments, 'debit-card', 0)
                || Number(get(values, 'payments.credit', 0)) !== get(current_payments, 'credit-card', 0)
                || Number(get(values, 'payments.transfer', 0)) !== get(current_payments, 'transfer', 0) )
            ? '' : 'hidden' }`}>
          <Icon icon={IconInfoCircle} extraClass="icon-warning" size='small' /> {I18n.get('editPaymentWarning', 'El nuevo valor no se reflejará en los ingresos del turno actual.')}
        </p>
      }
      <table className="detail-payments-table edit-mode rounded-lg mb-4">
        <thead>
          <tr>
            <th>{I18n.get('paymentMethod', 'método de pago')}</th>
            {/* <th>{I18n.get('bank', 'banco')}</th> */}
            <th>{I18n.get('value', 'valor')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-capitalize-first">
              {I18n.get('cash', 'efectivo')}
              {!isInvoiceShiftOpen && Number(get(values, 'payments.cash', 0)) !== get(current_payments, 'cash', 0) &&
                <Icon icon={IconInfoCircle} extraClass="icon-warning" size='small' />
              }
            </td>
            {/* <td /> */}
            <td>
              <Field
                name="payments.cash"
                className={`edit-field ${!isInvoiceShiftOpen && Number(get(values, 'payments.cash', 0)) !== get(current_payments, 'cash', 0) && 'field-warning'}`}
                component={renderField}
                type="number"
                placeholder={fmt.prefix}
                hideErrorLabel
                min="0"
                onBlur={() => {
                  if (!get(values, 'payments.cash'))
                    form.change('payments.cash', '0')
                  else if (+get(values, 'payments.cash') < 0)
                    form.change('payments.cash', '0')
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="text-capitalize-first">
              {I18n.get('debit', 'débito')}
              {!isInvoiceShiftOpen && Number(get(values, 'payments.debit', 0)) !== get(current_payments, 'debit-card', 0) &&
                <Icon icon={IconInfoCircle} extraClass="icon-warning" size='small' />
              }
            </td>
            {/* <td style={{ minWidth: '20rem' }}>
              <Field
                name="payments.debitBank"
                className="edit-field"
                height="2.4rem"
                fontSize="12px"
                component={renderSelect}
                options={!can('index', 'bank-accounts') || !can('view', 'bank-accounts') ? [] : banks}
                selectInfo={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')
                  ? I18n.get('userNotAllowed.bank-accounts.view', 'no tienes permisos para ver la información de las cuentas de bancos')
                  : null}
                hideNoOptionMessage={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
              />
            </td> */}
            <td>
              <Field
                name="payments.debit"
                className={`edit-field ${!isInvoiceShiftOpen && Number(get(values, 'payments.debit', 0)) !== get(current_payments, 'debit-card', 0) && 'field-warning'}`}
                component={renderField}
                type="number"
                placeholder={fmt.prefix}
                hideErrorLabel
                min="0"
                onBlur={() => {
                  if (!get(values, 'payments.debit'))
                    form.change('payments.debit', '0')
                  else if (+get(values, 'payments.debit') < 0)
                    form.change('payments.debit', '0')
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="text-capitalize-first">
              {I18n.get('credit', 'crédito')}
              {!isInvoiceShiftOpen && Number(get(values, 'payments.credit', 0)) !== get(current_payments, 'credit-card', 0) &&
                <Icon icon={IconInfoCircle} extraClass="icon-warning" size='small' />
              }
            </td>
            {/* <td style={{ minWidth: '20rem' }}>
              <Field
                name="payments.creditBank"
                className="edit-field"
                height="2.4rem"
                fontSize="12px"
                component={renderSelect}
                options={!can('index', 'bank-accounts') || !can('view', 'bank-accounts') ? [] : banks}
                selectInfo={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')
                  ? I18n.get('userNotAllowed.bank-accounts.view', 'no tienes permisos para ver la información de las cuentas de bancos')
                  : null}
                hideNoOptionMessage={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
              />
            </td> */}
            <td>
              <Field
                name="payments.credit"
                className={`edit-field ${!isInvoiceShiftOpen && Number(get(values, 'payments.credit', 0)) !== get(current_payments, 'credit-card', 0) && 'field-warning'}`}
                component={renderField}
                type="number"
                placeholder={fmt.prefix}
                hideErrorLabel
                min="0"
                onBlur={() => {
                  if (!get(values, 'payments.credit'))
                    form.change('payments.credit', '0')
                  else if (+get(values, 'payments.credit') < 0)
                    form.change('payments.credit', '0')
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="text-capitalize-first">
              {I18n.get('transfer', 'transferencia')}
              {!isInvoiceShiftOpen && Number(get(values, 'payments.transfer', 0)) !== get(current_payments, 'transfer', 0) &&
                <Icon icon={IconInfoCircle} extraClass="icon-warning" size='small' />
              }
            </td>
            {/* <td style={{ minWidth: '20rem' }}>
              <Field
                name="payments.transferBank"
                className="edit-field"
                height="2.4rem"
                fontSize="12px"
                component={renderSelect}
                options={!can('index', 'bank-accounts') || !can('view', 'bank-accounts') ? [] : banks}
                selectInfo={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')
                  ? I18n.get('userNotAllowed.bank-accounts.view', 'no tienes permisos para ver la información de las cuentas de bancos')
                  : null}
                hideNoOptionMessage={!can('index', 'bank-accounts') || !can('view', 'bank-accounts')}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
              />
            </td> */}
            <td>
              <Field
                name="payments.transfer"
                className={`edit-field ${!isInvoiceShiftOpen && Number(get(values, 'payments.transfer', 0)) !== get(current_payments, 'transfer', 0) && 'field-warning'}`}
                component={renderField}
                type="number"
                placeholder={fmt.prefix}
                hideErrorLabel
                min="0"
                onBlur={() => {
                  if (!get(values, 'payments.transfer'))
                    form.change('payments.transfer', '0')
                  else if (+get(values, 'payments.transfer') < 0)
                    form.change('payments.transfer', '0')
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="text-capitalize-first">{I18n.get('totalReceived', 'total recibido')}</td>
            {/* <td /> */}
            <td>{received.toFormat(decimal, fmt)}</td>
          </tr>
          <tr>
            <td className="text-capitalize-first">
              {itemsValues.total.minus(received).lte(0)
                ? I18n.get('change', 'cambio')
                : I18n.get('toPay', 'por pagar')
              }
            </td>
            {/* <td /> */}
            <td>{itemsValues.total.minus(received).abs().toFormat(decimal, fmt)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Payments