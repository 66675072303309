import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  country as countrySelector,
  companySelector,
  membershipSelector,
  membershipPlanSelector,
} from '../../selectors/company';
import { COUNTRIES } from '../../utils/enums/countries';
import dayjs from 'dayjs';
import { get } from 'lodash';

export const DECIMALS_ACTIVE_COUNTRIES = [
  COUNTRIES.INTERNATIONAL,
  COUNTRIES.SPAIN,
  COUNTRIES.CHILE,
  COUNTRIES.ARGENTINA,
  COUNTRIES.COLOMBIA,
];

const MEXICO_DECIMALS_ACTIVE_IDS = [
  '836826',
  '1181156',
  '1180239',
  '1346031',
  '1397006',
  '8367',
  '23924',
  '49319',
  '54278',
  '59751',
  '65640',
  '68295',
  '85948',
  '100000',
  '120350',
  '143259',
  '144002',
  '145346',
  '146891',
  '150101',
  '153404',
  '184809',
  '190780',
  '192980',
  '200064',
  '228049',
  '243594',
  '245671',
  '250761',
  '268521',
  '269060',
  '284342',
  '288167',
  '308536',
  '315140',
  '318028',
  '328090',
  '330864',
  '331032',
  '333306',
  '341038',
  '363925',
  '386332',
  '404802',
  '406231',
  '426815',
  '470420',
  '512518',
  '522094',
  '538560',
  '557522',
  '572115',
  '604849',
  '624997',
  '629944',
  '638634',
  '647882',
  '678133',
  '680571',
  '681490',
  '682976',
  '683031',
  '684778',
  '685117',
  '701977',
  '731789',
  '732014',
  '746281',
  '760790',
  '784657',
  '793390',
  '803458',
  '813008',
  '820912',
  '826923',
  '827134',
  '835843',
  '846468',
  '846498',
  '846516',
  '846529',
  '846690',
  '961892',
  '1005549',
  '1014910',
  '1040168',
  '1117982',
  '1132133',
  '1132265',
  '1157806',
  '1167090',
  '1176383',
  '1176418',
  '1190261',
  '1190749',
  '1191326',
  '1196146',
  '1198535',
  '1210736',
  '1217794',
  '1218499',
  '1218637',
  '1231330',
  '1291684',
  '1307701',
  '1309819',
  '1322283',
  '1324413',
  '1326568',
  '1332996',
  '1333767',
  '1335092',
  '1350862',
  '1351611',
  '1358168',
  '1359893',
  '1360933',
  '1387531',
  '1398014',
  '1400815',
  '1400816',
  '1401104',
  '776342',
  '562122',
  '1120339',
  '1181156',
  '1180239',
  '354782',
  '811653',
  '30239',
  '1113382',
  '1191621',
  '1220144',
  '1263992',
  '1371683',
  '1395215',
  '605419',
  '1166566',
  '1024690',
  '1143007',
  '416593',
  '1397006',
  '1397729',
  '1395301',
  '1143007',
  '1401861',
  '1268685',
  '613115',
  '1196490',
  '968307',
  '813614'
];

export const DECIMALS_ACTIVE_IDS = [...MEXICO_DECIMALS_ACTIVE_IDS];

export const calculateAvailabilityByRegistryDate = ({ company, country }) => {
  const registryDate = get(company, 'registryDate', '');

  if (!registryDate) return false;

  const parsedDate = dayjs(registryDate);

  if (country === COUNTRIES.MEXICO && parsedDate.isBefore('2023-06-31')) {
    return true;
  }

  return false;
};

const planAvailabilityRules = {};

export const calculateAvailabilityByPlanKeyword = ({ membership, country }) => {
  const planName =
    get(membership, 'plan.keyword', null) ||
    get(membership, 'planKeyword', null);

  const rules = planAvailabilityRules[country];

  return rules ? rules.includes(planName) : false;
};

const useDecimalsVersionsGroup = () => {
  const [isDecimalActive, setIsDecimalActive] = useState(false);
  const company = useSelector(companySelector);
  const membership = useSelector(membershipSelector);
  const membershipPlan = useSelector(membershipPlanSelector);
  const country = useSelector(countrySelector);

  const dateValidation = useMemo(() => {
    return calculateAvailabilityByRegistryDate({ company, country });
  }, [company, country]);

  const planValidation = useMemo(() => {
    return calculateAvailabilityByPlanKeyword({
      membership: Object.keys(membership) > 0 ? membership : membershipPlan,
      country,
    });
  }, [membership, membershipPlan, country]);

  useEffect(() => {
    if (
      DECIMALS_ACTIVE_COUNTRIES.includes(country) ||
      DECIMALS_ACTIVE_IDS.includes(company?.id) ||
      dateValidation ||
      planValidation
    ) {
      setIsDecimalActive(true);
    }
  }, [company, country]);
  
  return {
    isDecimalActive,
  };
};

export default useDecimalsVersionsGroup;
