import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../common/Modal';
import Typography from '../../common/Typography';
import { I18n } from 'aws-amplify';
import { Form } from 'react-final-form';
import { useEffect, useMemo, useState } from 'react';
import { add, get } from 'lodash';

import { closeModal, openModal } from '../../../reducers/modals';
import { checkFeatureLimit } from '../../../reducers/membership';
import { getElectronicNumerations } from '../../../selectors/numerations';
import { setSettings } from '../../../reducers/activeInvoice';
import alegraAPI from '../../../reducers/alegraAPI';
import { replaceOfflineClient } from '../../../reducers/clients';
import { client as clientSelector } from '../../../selectors/activeInvoice';

import Body from './FormBody';
import Bottom from './Bottom';

import { COUNTRIES } from '../../../utils/enums/countries';
import spainAddresses from '../../countriesData/spain/municipalityProvince.json';
import { spainTransformer } from '../../../pages/contacts/contact/form/utils/spain/transformer';

const useSimplifiedNumeration = (numerations) => {
  const simplifiedNumeration = useMemo(() => {
    if (!Array.isArray(numerations)) return null;

    const numerationDefault = numerations.find(
      (numeration) =>
        numeration.documentType === 'saleTicket' && numeration.isDefault
    );
    return numerationDefault || numerations.find(
      (numeration) => numeration.documentType === 'saleTicket'
    ) || null;
  }, [numerations]);

  return simplifiedNumeration;
};

const UpdateSpainClient = () => {
  const dispatch = useDispatch();

  // Selectors
  const numerations = useSelector(getElectronicNumerations);
  const isOpen = useSelector((state) => get(state, 'modals.updateSpainClient.isOpen', false));
  const params = useSelector((state) => get(state, 'modals.updateSpainClient.params', {}));
  const activeInvoiceClient = useSelector(clientSelector);

  // Derived data
  const contact = useMemo(() => ({ id: params?.id, address: params?.address }), [params]);
  const simplifiedNumeration = useSimplifiedNumeration(numerations);

  // Handlers
  const handleClose = () => dispatch(closeModal({ modal: 'updateSpainClient' }));

  const onContinue = () => {
    dispatch(checkFeatureLimit(['income', 'invoices'], () => {
      handleClose();
      dispatch(openModal({ modal: 'invoice' }));
    }));
  };

  const onChangeNumeration = () => {
    if (simplifiedNumeration) {
      dispatch(setSettings({ numeration: simplifiedNumeration }));
      handleClose();
      onContinue();
    }
  };

  const handleSubmit = async (values) => {
    const transformedValues = spainTransformer(values, { country: COUNTRIES.SPAIN, contact });

    try {
      await dispatch(
        replaceOfflineClient(contact.id, { address: transformedValues?.address }, "basicNewClient", true, true)
      );

      await alegraAPI.put(`/contacts/${contact.id}`, { ...activeInvoiceClient, address: transformedValues?.address });

      handleClose();
      onContinue();
    } catch (error) {
      console.error('Error updating client:', error);
    }
  };

  // Prepare initialValues for the form
  const initialValues = useMemo(() => ({
    address: {
      combined: spainAddresses.find(option =>
        option.value === `${get(contact, 'address.city', '')},${get(contact, 'address.province', '')}`)?.value || '',
      address: get(contact, 'address.address', ''),
    },
  }), [contact]);

  return (
    <Modal
      isOpen={isOpen}
      title={
        <Typography
          text={I18n.get('modals.updateSpainClient.title', 'Datos del cliente incompletos')}
          type="heading-4"
          variant="primary"
        />
      }
      className='modal__update-spain-client'
      onRequestClose={handleClose}
      borderlessHeader
      hideClose
    >
      <Typography
        type='label-3'
        variant='secondary'
        text={I18n.get('modals.updateSpainClient.description', 'La factura actual no incluye toda la información fiscal del cliente necesaria para emitir una Factura Ordinaria. Puedes completar los siguientes datos o cambiar a una Factura Simplificada.')}
      />

      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, values, form, submitting, invalid }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
          >
            <Body
              values={values}
              form={form}
              contactCreated={contact}
              country={COUNTRIES.SPAIN}
            />
            <Bottom
              disabled={invalid || submitting}
              submitting={submitting}
              onChangeNumeration={onChangeNumeration}
            />
          </form>
        )}
      </Form>
    </Modal>
  );
};

export default UpdateSpainClient;
