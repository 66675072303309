import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { capitalize, get } from 'lodash';
import { useHistory, NavLink } from 'react-router-dom';

import * as queries from '../../../graphql/queries'
import { handleError } from '../../../utils/errors'
import { openModal } from '../../../reducers/modals'
import { isOnlyInvoicingPlan } from '../../../selectors/company'
import { getNumerationById } from '../../../selectors/numerations';
import { getWarehouseById } from '../../../selectors/warehouses';
import { hasPermissionTo } from '../../../selectors/auth';
import StationModal from '../../modals/station/Station'
import Table from '../../common/Table';
import Header from '../common/Header';
import Actions from './Actions'
import { APIGraphqlSelector } from '../../../selectors/app';
import { Tooltip } from '@alegradev/smile-ui-react';

const Stations = () => {
  const dispatch = useDispatch()
  const can = useSelector(hasPermissionTo)
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan)
  const APIGraphql = useSelector(APIGraphqlSelector);
  const ref = useRef(null)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [tableIndicators, setTableIndicators] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  const [metadata, setMetadata] = useState({
    from: 0,
    hasNextPage: false,
    limit: 0,
    to: 0,
    total: 0
  });
  const [totalPages, setTotalPages] = useState(0);
  const history = useHistory();

  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      'event': 'VirtualPageview',
      'virtualPageURL': '/settings/stations',
      'virtualPageTitle': 'Stations Settings'
    });

    return () => ref.current = false
  }, []);

  const startLoading = useCallback(() => {
    if (ref.current) {
      setLoading(true);
    }
  }, [])

  const stopLoading = useCallback(() => {
    if (ref.current) {
      setLoading(false);
    }
  }, [])

  const fetchData = useCallback(async ({ pageIndex, pageSize }) => {
    startLoading()
    setError(null)
    try {
      if (can('index', 'pos-station')) {
        const response = await APIGraphql(graphqlOperation(queries.getStationsWithBatch, {
          batch: { limit: pageSize, start: pageIndex * pageSize },
          includeMetadata: true
        }))

        stopLoading()
        if (ref.current) {
          setData(get(response, 'data.getStationsWithBatch.data', []))
          setMetadata(get(response, 'data.getStationsWithBatch.metadata', metadata))

          setTotalPages(Math.ceil(get(response, 'data.getStationsWithBatch.metadata.total', 0) / pageSize))
        }
      }

    } catch (error) {
      stopLoading(error)
      setError(handleError(error, I18n.get('stationsLoadError', 'Sucedió un error cargando las terminales')))
    }
    if (ref.current) {
      setTableIndicators({ pageIndex, pageSize })
    }
  }, [stopLoading, startLoading, can, APIGraphql])

  useEffect(() => {
    fetchData({
      pageIndex: 0,
      pageSize: 10,
    })
  }, [fetchData])

  return (
    <div className="container p-5">
      <div className="d-flex flex-column">
        <Header
          title={I18n.get('stationsSettingTitle', 'terminales POS')}
          subtitle={
            <p className="h5 text-muted text-capitalize-first">
              {I18n.get('stationsSettingSubtitle', 'Administra las terminales que usas en tus puntos de venta')}{' '}
              <a className="text-capitalize-first btn-link" href="https://ayuda.alegra.com/es/configura-terminales-para-facturar-en-el-punto-de-venta" target="_blank" rel="noreferrer">
                {capitalize(I18n.get('seeMore', 'ver más'))}
              </a>
            </p>
          }
          actions={(
            <div className="d-flex">
              <Tooltip
                visible={!can('add', 'pos-station') || onlyInvoicing}
                overlay={!onlyInvoicing
                  ? (
                    I18n.get('userNotAllowed.pos-station.view', 'no tienes permisos para ver detalle de terminales')
                  ) : (
                    <p className="text-capitalize-first text-white">
                      {I18n.get('onlyInvoicingTooltip', 'Para disfrutar de esta funcionalidad, conoce los planes todo incluido')}{' '}
                      <NavLink
                        to={{ pathname: `${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans` }}
                        className="btn-link"
                        target="_blank"
                      >
                        {I18n.get('here', 'aquí')}
                      </NavLink>
                    </p>
                  )
                }
              >
                <button
                  type="button"
                  disabled={!can('add', 'pos-station')}
                  className="btn btn-submit mr-3"
                  onClick={() => dispatch(openModal({ modal: 'station' }))}
                >
                  {I18n.get('newStation', 'Nueva terminal')}
                </button>
              </Tooltip>
              <button
                type="button"
                className="btn btn-cancel"
                onClick={() => history.push('/settings')}
              >
                {I18n.get('goBack', 'Volver')}
              </button>
            </div>
          )}
        />

        <div className="card bg-white shadowp-4 rounded-lg">
          <Table
            loading={loading}
            data={can('index', 'pos-station') ? data : []}
            error={error}
            onRefresh={({ pageIndex, pageSize }) => fetchData({ pageIndex, pageSize })}
            noDataText={can('index', 'pos-station')
              ? capitalize(I18n.get('youDontHaveCreatedStations', 'aún no tienes terminales creadas'))
              : capitalize(I18n.get('userNotAllowed.pos-station.index', 'no tienes permiso para ver las terminales'))
            }
            columns={[
              {
                Header: capitalize(I18n.get('name', 'Nombre')),
                sortable: false,
                accessor: 'name',
                Cell: ({ value, row }) => (
                  <div
                    className={can('view', 'pos-station') ? 'text-primary pointer' : ''}
                    onClick={() => can('view', 'pos-station') || onlyInvoicing
                      ? dispatch(openModal({ modal: 'station', params: { station: row.original } }))
                      : null
                    }
                  >
                    {value}
                  </div>
                )
              },
              {
                Header: capitalize(I18n.get('numeration', 'Numeración')),
                sortable: false,
                accessor: 'idNumberTemplate',
                Cell: ({ value }) => get(useSelector(getNumerationById(value)), 'name', '')
              },
              {
                Header: capitalize(I18n.get('warehouse', 'Bodega')),
                sortable: false,
                accessor: 'idWarehouse',
                Cell: ({ value }) => get(useSelector(getWarehouseById(value)), 'name', '')
              },
              {
                Header: capitalize(I18n.get('actions', 'Acciones')),
                id: 'actions',
                sortable: false,
                minWidth: 110,
                maxWidth: 150,
                Cell: ({ row }) => (
                  <Actions
                    station={row.original}
                    onRefresh={() => fetchData(tableIndicators)}
                    onView={() => dispatch(openModal({ modal: 'station', params: { station: row.original } }))}
                    startLoading={startLoading}
                    stopLoading={stopLoading}
                  />
                )
              },
            ]}
            handlePage={({ pageIndex, pageSize }) => fetchData({ pageIndex, pageSize })}
            hasPreviousPage={metadata.from > 0}
            currentPage={tableIndicators.pageIndex}
            totalPages={totalPages}
          />
        </div>
      </div>

      <StationModal onRefresh={() => fetchData(tableIndicators)} />
    </div>
  )
}

export default Stations;