import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import { companySelector, country as countrySelector } from '../../selectors/company';

import { openModal } from '../../reducers/modals';
import { hasPermissionTo } from '../../selectors/auth';
import { isUserElegibleForNewRefund } from './utils';
import { Tooltip } from '@alegradev/smile-ui-react';

const PaginationHeader = () => {
  const dispatch = useDispatch()
  const can = useSelector(hasPermissionTo)
  const country = useSelector(countrySelector)
  const company = useSelector(companySelector)

  return (
    <div className="d-flex p-4 justify-content-center align-items-center d-sm-none d-block w-100">
      <Tooltip
        visible={!can('add', 'credit-notes')}
        overlay={I18n.get('userNotAllowed.credit-notes.add', 'no tienes permisos para agregar devoluciones')}
      >
        <button
          type="button"
          disabled={!can('add', 'credit-notes')}
          className="btn btn-submit"
          onClick={() => {
            isUserElegibleForNewRefund(country, company)
              ? dispatch(openModal({ modal: 'newRefunds' }))
              : dispatch(openModal({ modal: 'refunds' }))
          }}
        >
          {I18n.get('newRefund', 'nueva devolución')}
        </button>
      </Tooltip>
    </div>
  )
}

export default PaginationHeader;