import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';

import { externalProviderState } from '../../../selectors/company';

import ConfirmationModal from '../../modals/confirmation/Confirmation';
import Wompi from '../../../assets/images/wompi.png';
import Bancolombia from '../../../assets/images/bancolombia.png'
import NequiInline from '../../../assets/images/nequi_inline.png'
import { sendGTMEvent } from '../../../reducers/company';

const methods = {
  'nequi': {
    name: 'Nequi',
    message: I18n.get('configuredPaymentMethodNequiMessage', 'Tus clientes te pagarán en segundos leyendo el QR con su app.'),
  },
  'wompi': {
    name: 'Wompi',
    message: I18n.get('configuredPaymentMethodWompiMessage', 'Conecta tu cuenta de Wompi y valida el estado de cada transacción al vender.'),
  },
}

const renderTitle = (state, method) => {
  if (method === 'wompi')
    return I18n.get("integrateYourPaymentMethods", "Integra tus métodos de pago")
  return `${I18n.get('connectYourPointOfSaleWith', 'Conecta tu punto de venta con')} ${methods[method] && methods[method].name}`;
}

const renderBody = (state, method) => {
  return (
    <>
      <p className="h4 text-capitalize-first p-5">
        {methods[method] && methods[method].message}
      </p>
      {method === 'wompi' && (
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <img src={Wompi} alt="wompi" />
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
            width: "40rem",
            height: "4rem"
          }} className="mb-5">
            <p className='h4 text-muted m-0 p-0'>{I18n.get("receiveYourPaymentsFrom", "Recibe pagos desde")}</p>
            <img src={Bancolombia} alt="bancolombia" />
            <img src={NequiInline} alt="nequi" />
          </div>
        </div>
      )}
    </>
  )
}

const NewExternalProvidersMethodsModals = ({ method, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const methodState = useSelector(externalProviderState(method));

  const onRedirectPaymentMethod = (method) => {
    window.open(`${process.env.REACT_APP_ALEGRA_SMILE}integrations/e-payments/${method}/configuration`, '_blank');
  }

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onConfirm={() => {
        dispatch(sendGTMEvent('customer-payment-method-activation-attempted', {
          customerPaymentMethod: method
        }))
        onRedirectPaymentMethod(method)
      }}
      onClose={() => onClose()}
      confirmText={method === "wompi" ? I18n.get("activateIntegration", "Activar integración") : `${I18n.get('connectWith', 'Conectar con')}  ${methods[method] && methods[method].name}`}
      hideCancel={method === 'wompi' ? false : true}
      title={renderTitle(methodState, method)}
      body={renderBody(methodState, method)}
      closeText={I18n.get("back", "Atrás")}
      hideRequired
    />
  )
}

export default NewExternalProvidersMethodsModals