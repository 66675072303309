import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { get } from 'lodash'

import { handleError } from '../utils/errors'
import alegraAPI from '../reducers/alegraAPI';
import { getInitialState } from '../utils';
import { fetchSellersApi } from '../components/settings/Sellers/queries';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null
}

export const fetchSellers = createAsyncThunk(
  'sellers/fetch',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      if (!!preloaded)
        return preloaded

      // ex queries.allSellers
      // const data = await alegraAPI.get('/sellers', {
      //   ...params
      // })
      // return get(data, 'data', null);

      return await fetchSellersApi({ ...params });
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'sellers',
  initialState: getInitialState('sellers', initialState),
  reducers: {
    addSeller: adapter.addOne,
    updateSeller: adapter.updateOne,
    removeSeller: adapter.removeOne,
  },
  extraReducers: builder => {
    builder.addCase(fetchSellers.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchSellers.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchSellers.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { reducer, actions } = appSlice;

export const { addSeller, updateSeller, removeSeller } = actions;

export const sellersSelector = adapter.getSelectors(state => state.sellers);

export default reducer;