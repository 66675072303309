import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import dayjs from 'dayjs'
import { I18n } from '@aws-amplify/core'

import { userSelector } from "../../selectors/auth";
import notificationsAPIV2 from '../../reducers/notificationsAPIV2'
import { useDetectOS } from "../../hooks/useDetectOS";
import { country as countrySelector } from '../../selectors/company';
import { showNotificationTopDownloadApp } from "../../selectors/app";
import { setShowNotificationTopDownloadApp } from "../../reducers/app";

const availableInstallers = ['msi'];

const DownloadAppNotification = () => {
  const installerType = useDetectOS();
  const user = useSelector(userSelector);
  const country = useSelector(countrySelector);
  const location = useLocation();
  const history = useHistory();
  const showNotificationDownloadApp = useSelector(showNotificationTopDownloadApp);
  const dispatch = useDispatch()

  const selectedBody = useCallback(() => {
    return {
      userId: user.idGlobal.toString(),
      title: I18n.get('titleNotificationDownloadApp', '¡Nuevo Alegra POS para Windows!'),
      text: I18n.get('textNotificationDownloadApp', '<b id="downloadApp">¡Nuevo Alegra POS para Windows!</b> 💻⚡️ Agiliza tus ventas y mejora tu experiencia durante intermitencias de internet.'),
      actionLabel: I18n.get('actionLabelDownloadApp', 'Descargar gratis'),
      type: "info_dark",
      actionType: "event",
      actionCall: "downloadApp",
      country,
    }
  }, [user, country])

  const notification = async () => {
    try {
      await notificationsAPIV2.post("/notifications", notificationBody({ ...selectedBody() }));
      dispatch(setShowNotificationTopDownloadApp(true));
    } catch (err) {
      console.log("error while creating notification download app", err);
      dispatch(setShowNotificationTopDownloadApp(false));
    }
  };

  useEffect(() => {
    if (availableInstallers.includes(installerType) && !showNotificationDownloadApp) {
      notification();
    }
  }, [installerType, user, showNotificationDownloadApp]);

  useEffect(() => {
    const { hash } = location;

    if (hash === "#downloadApp") {
      history.replace("/download/desktop");
    }

  }, [location]);

  return <></>;
};

const notificationBody = ({ userId, from = null, to = null, section = null, title, text, actionCall, actionLabel, type, actionType, country }) => ({
  name: `download-app-notification:${country}`,
  teamInCharge: "pos",
  receptors: {
    users: [userId]
  },
  fromDate: from ? from : dayjs().toISOString(),
  toDate: to ? to : dayjs().add(1, 'days').toISOString(),
  messages: [
    {
      channel: "web",
      type: "top",
      category: "new-feature",
      closeable: true,
      section: section ? section : "index-pos-home",
      title,
      text,
      application: "alegra-pos",
      action: {
        actionType,
        actionCall,
        actionLabel
      },
      style: {
        type,
        icon: "input"
      }
    }
  ]
})

export default DownloadAppNotification;