import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { get } from 'lodash'

import { hasPermissionTo } from '../selectors/auth'
import { currency as currencySelector } from '../selectors/company'
import { handleError } from '../utils/errors'
import alegraAPI from '../reducers/alegraAPI';
import { getInitialState } from '../utils';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null
}

export const fetchCurencies = createAsyncThunk(
  'currencies/fetch',
  async ({ preloaded, ...params }, { rejectWithValue, getState }) => {
    try {
      const can = hasPermissionTo(getState())
      if (!can('index', 'currencies'))
        return []

      if (!!preloaded)
        return preloaded
      // ex queries.allCurrencies

      const data = await alegraAPI.get('/currencies', {
        ...params
      })

      let currencies = [currencySelector(getState())]
      if (!!get(data, 'data', null))
        currencies = currencies.concat(get(data, 'data'))

      return currencies;
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const adapter = createEntityAdapter({
  selectId: currency => currency.code
});

const appSlice = createSlice({
  name: 'currencies',
  initialState: getInitialState('currencies', initialState),
  reducers: {
    addCurrency: adapter.addOne,
  },
  extraReducers: builder => {
    builder.addCase(fetchCurencies.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload)
      state.loading = 'idle'
      state.error = null
    })
    builder.addCase(fetchCurencies.pending, (state) => {
      state.loading = 'loading'
      state.error = null
    })
    builder.addCase(fetchCurencies.rejected, (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    })
  }
});

const { reducer, actions } = appSlice;

export const { addCurrency } = actions

export const currenciesSelector = adapter.getSelectors(state => state.currencies);

export default reducer;