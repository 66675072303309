import { I18n } from '@aws-amplify/core';
import { get, has, isNumber, isArray, isNull, isUndefined } from 'lodash'
import BigNumber from 'bignumber.js'
import { FORM_ERROR } from 'final-form'
import dayjs from 'dayjs'

import { calculateItemsValues } from '../../../utils';

export const initialValues = ({ invoice, ...props }) => {
  const payments = {
    cash: 0,
    debit: 0,
    credit: 0,
    transfer: 0,
  }

  if (get(invoice, 'payments.length')) {
    invoice.payments.map(p => {
      switch (get(p, 'paymentMethod')) {
        case 'cash':
          payments.cash = +p.amount
          break;
        case 'debit-card':
          payments.debit = +p.amount
          break;
        case 'credit-card':
          payments.credit = +p.amount
          break;
        case 'transfer':
          payments.transfer = +p.amount
          break;

        default:
          break;
      }
      return null
    })
  }

  return {
    ...invoice,
    date: get(invoice, 'createdAt'),
    items: !!get(invoice, 'items.length')
      ? invoice.items.map(i => ({
        ...i,
        item: i,
        reference: !!i.reference ? JSON.parse(i.reference) : null,
        originalPrice: new BigNumber(+i.price)
          .multipliedBy(new BigNumber(+get(invoice, 'currency.exchangeRate', 1)))
          .decimalPlaces(4).toNumber()
      }))
      : [],
    numberTemplate: get(props, 'numerations.length')
      ? props.numerations.find(n => get(n, 'id') === get(invoice, 'numberTemplate.id'))
      || get(props.numerations, '0', null)
      : null,
    payments,
    currency: get(invoice, 'currency') || props.mainCurrency,
  }
}

export const validate = (values, { decimal, country, company, tip, membership }) => {
  let errors = {}

  const itemsValues = calculateItemsValues({items: values.items, decimal, country, company, tips: tip, membership});

  if (!get(values, 'client.id', null))
    errors.client = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio');

  if (!get(values, 'date', null))
    errors.client = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio');

  if (!get(values, 'dueDate', null))
    errors.client = I18n.get('thisFieldIsRequired', 'Este campo es obligatorio');

  if (get(values, 'items.length') === 1 && !get(values, 'items.0.item.id')) {
    errors = {
      ...errors,
      items: [{
        item: I18n.get('selectAItemAtLeast', 'Seleccione un elemento al menos'),
      }]
    }
  }

  const debit = new BigNumber(!!get(values, 'payments.debit') ? get(values, 'payments.debit') : 0);
  const credit = new BigNumber(!!get(values, 'payments.credit') ? get(values, 'payments.credit') : 0);
  const transfer = new BigNumber(!!get(values, 'payments.transfer') ? get(values, 'payments.transfer') : 0);

  const electronicPayments = debit.plus(credit).plus(transfer);
  if (electronicPayments.gt(itemsValues.total)) {
    errors = {
      ...errors,
      payments: {
        debit: I18n.get('electronicPaymentsTotalMustBeLTETotal', 'el total de los pagos electrónicos debe ser menor o igual al total de la factura'),
        credit: I18n.get('electronicPaymentsTotalMustBeLTETotal', 'el total de los pagos electrónicos debe ser menor o igual al total de la factura'),
        transfer: I18n.get('electronicPaymentsTotalMustBeLTETotal', 'el total de los pagos electrónicos debe ser menor o igual al total de la factura'),
      },
      [FORM_ERROR]: I18n.get('electronicPaymentsTotalMustBeLTETotal', 'el total de los pagos electrónicos debe ser menor o igual al total de la factura'),
    }
  }

  return errors
}

export const transform = (values, { form, invoice, banks, station, decimal, country, company, membership }) => {
  const methods = {
    cash: 'cash',
    debit: 'debit-card',
    credit: 'credit-card',
    transfer: 'transfer'
  };

  const tipAmount = get(invoice, 'additionalCharges[0].amount', 0)
  const tip = {
    include: tipAmount > 0,
    value: tipAmount,
    type: "VALUE"
  }

  const { total, discSubtotal } = calculateItemsValues({items: values.items, decimal, country, company, tips: tip, membership});
  let totalReceived = new BigNumber(0)
  let totalInNotCash = new BigNumber(0)

  Object.keys(values.payments).map(paymentMethod => {
    totalReceived = totalReceived.plus(new BigNumber(get(values, `payments.${paymentMethod}`, 0)).decimalPlaces(decimal))
    if (paymentMethod !== 'cash')
      totalInNotCash = totalInNotCash.plus(new BigNumber(get(values, `payments.${paymentMethod}`, 0)).decimalPlaces(decimal))

    return null
  })

  const numberTemplate = !!form.getFieldState('numberTemplate') && !!form.getFieldState('numberTemplate').dirty
    ? get(values, 'numberTemplate') : null

  let updatedInvoice = {
    id: get(invoice, 'id'),
    idStation: get(station, 'id'),
    date: !!form.getFieldState('date') && !!form.getFieldState('date').dirty
      ? dayjs(get(values, 'date')).format('YYYY-MM-DD') : null,
    dueDate: !!form.getFieldState('dueDate') && !!form.getFieldState('dueDate').dirty
      ? dayjs(get(values, 'dueDate')).format('YYYY-MM-DD') : null,
    numberTemplate: !!numberTemplate ? { id: get(numberTemplate, 'id', null) } : null,
    client: get(values, 'client.id'),
    seller: !!form.getFieldState('seller') && !!form.getFieldState('seller').dirty
      ? { id: get(values, 'seller.id') } : null,
    currency: !!form.getFieldState('currency') && !!form.getFieldState('currency').dirty
      ? {
        code: get(values, 'currency.code'),
        exchangeRate: get(values, 'currency.exchangeRate'),
      }
      : null,
    items: !!form.getFieldState('items')
      ? values.items.map(item => ({
        id: get(item, 'id', null),
        description: get(item, 'description', null),
        discount: !!get(item, 'discount', null) ? JSON.stringify(get(item, 'discount', null)) : null,
        reference: !!get(item, 'reference', null) ? JSON.stringify(get(item, 'reference', null)) : null,
        price: get(item, 'price', null),
        tax: !!get(item, 'tax', null)
          ? isArray(get(item, 'tax'))
            ? item.tax.map(t => ({ id: t.id })) :
            [{ id: get(item, 'tax.id') }]
          : [],
        quantity: has(item, 'quantity') && isNumber(Number(item.quantity)) ? Number(item.quantity).toFixed(2) : null,
      }))
      : null,
    total: total.toNumber(),
    subtotal: discSubtotal.toNumber(),
    totalReceived: totalReceived.toNumber(),
    cashReturned: totalReceived.gt(total) ? total.minus(totalReceived).abs().toNumber() : 0,
    additionalCharges: isArray(get(invoice, 'additionalCharges'))
      ? invoice.additionalCharges.map(charge => {
          return {
            "id": get(charge, "idCharge"),
            "percentage": get(charge, "percetage"),
            "amount": get(charge, "amount")
          }
        }) :
      []
  }

  Object.keys(updatedInvoice).map(key => {
    if (isNull(updatedInvoice[key]))
      delete updatedInvoice[key];
    else if (isUndefined(updatedInvoice[key]))
      updatedInvoice[key] = null;
    return null;
  })

  let updatedPayments = []
  let newPayments = []

  Object.keys(values.payments).map(paymentMethod => {
    if (!!form.getFieldState(`payments.${paymentMethod}`) && !!form.getFieldState(`payments.${paymentMethod}`).dirty) {
      let basePayment = {
        invoices: [{
          id: get(invoice, 'id'),
          amount: paymentMethod === 'cash' && totalReceived.gt(total)
            ? total.decimalPlaces(decimal).minus(totalInNotCash).toNumber()
            : get(values, `payments.${paymentMethod}`),
        }],
        client: !!get(updatedInvoice, 'client')
          ? get(updatedInvoice, 'client')
          : get(invoice, 'client.id'),
        currency: !!get(updatedInvoice, 'currency')
          ? get(updatedInvoice, 'currency')
          : !!get(invoice, 'currency')
            ? {
              code: get(invoice, 'currency.code'),
              exchangeRate: get(invoice, 'currency.exchangeRate'),
            }
            : null,
      }

      if (!invoice.payments || !invoice.payments.find(p => p.paymentMethod === methods[paymentMethod])) {
        newPayments.push({
          ...basePayment,
          idStation: get(station, 'id'),
          date: dayjs().format('YYYY-MM-DD'),
          bankAccount: paymentMethod !== 'cash' && !!get(banks, `${paymentMethod}.id`, null)
            ? { id: get(banks, `${paymentMethod}.id`) } : null,
          paymentMethod: methods[paymentMethod],
        })
      } else {
        const payment = invoice.payments.find(p => p.paymentMethod === methods[paymentMethod])

        updatedPayments.push({
          ...basePayment,
          id: get(payment, 'id'),
        })
      }
    }

    return null
  })

  return { updatedInvoice, updatedPayments, newPayments }
}