import { I18n } from '@aws-amplify/core';

function globalInvoicesEmptyState({ history }) {
  return {
    title: I18n.get(
      'global-empty-invoice',
      'Crea tu primera factura global en segundos. ⚡'
    ),
    buttonText: I18n.get('new-global-invoice', 'Nueva factura global'),
    action: () => history.push('/global-invoices/invoice'),
  };
}

export default globalInvoicesEmptyState;
