import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash'
import { BigNumber } from 'bignumber.js'
import { Chart } from 'chart.js'

import { colors } from '../../../utils';
import { hasPermissionTo } from '../../../selectors/auth';
import { decimalPrecision } from '../../../selectors/company';
import { Icon } from '@alegradev/smile-ui-react';
import { IconLoader2 } from '@tabler/icons-react';

const calculateValues = shift => ({
  cashIn: new BigNumber(0)
    .plus(get(shift, 'cash', 0))
    .plus(get(shift, 'debit', 0))
    .plus(get(shift, 'credit', 0))
    .plus(get(shift, 'transfer', 0))
    .plus(get(shift, 'inPayment', 0))
    .toNumber(),
  cashOut: new BigNumber(0)
    .plus(get(shift, 'outPayment', 0))
    .plus(get(shift, 'refunds', 0))
    .toNumber()
})

const formatNumber = (num) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "k" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "G" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(0).replace(rx, "$1") + si[i].symbol;
}

const ShiftsCharts = ({
  data,
  loading,
  error,
  total,
}) => {
  const chartRef = useRef()
  const can = useSelector(hasPermissionTo)
  const decimal = useSelector(decimalPrecision)

  useEffect(() => {
    let chartWrapper = chartRef.current
    if (!!chartWrapper) {
      var canvas = document.createElement('canvas');

      canvas.width = 350;
      canvas.height = 350;

      chartWrapper.appendChild(canvas)

      new Chart(canvas, {
        type: "bar",
        data: {
          labels: data.map(shift => `${capitalize(I18n.get('closure', 'Cierre'))} ${get(shift, 'idLocal', '')}`),
          datasets: [
            {
              label: capitalize(I18n.get('cashIn', 'ingresos')),
              data: data.map(shift => calculateValues(shift).cashIn),
              backgroundColor: colors[0],
              parsing: {
                yAxisKey: 'cashIn'
              }
            },
            {
              label: capitalize(I18n.get('cashOut', 'gastos')),
              data: data.map(shift => calculateValues(shift).cashOut),
              backgroundColor: colors[14],
              parsing: {
                yAxisKey: 'cashOut'
              }
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
            },
            tooltip: {
              callbacks: {
                title: (props) => {
                  if (!!props.length)
                    return `${capitalize(I18n.get('closure', 'Cierre'))} ${props[0].label}`
                  return ''
                },
                label: ({ dataset: { label }, raw }) => `${label}: ${new BigNumber(raw).toFormat(decimal)}`
              }
            }
          },
          scales: {
            y: {
              ticks: {
                callback: (value) => formatNumber(value)
              }
            }
          }
        },
      });
    }
    return () => {
      if (!!chartWrapper) {
        chartWrapper.removeChild(chartWrapper.childNodes[0])
      }
    }
  }, [data, decimal])

  return (
    <div className="chart-data-wrapper position-relative w-100">

      <div ref={chartRef} className="chart-wrapper" />

      {loading && (
        <div className="chart-data-indicator w-100 h-100 d-flex justify-content-center align-items-center">
          <Icon icon={IconLoader2} animated extraClass=" icon-primary icon x2" />
        </div>
      )}
      {data.length === 0 && !loading && !error && !!total && (
        <div className="chart-data-indicator w-100 h-100 d-flex justify-content-center align-items-center">
          <p className="text-capitalize-first p-5">
            {I18n.get('noShiftInQuery', 'Selecciona un nuevo período de consulta que cuente con turnos cerrados.')}
          </p>
        </div>
      )}
      {data.length === 0 && !loading && !error && !total && (
        <div className="chart-data-indicator w-100 h-100 d-flex justify-content-center align-items-center">
          <p className="text-capitalize-first p-5">
            {I18n.get('noClosedShiftsYet.shifts', 'En este espacio verás el comportamiento de tus ingresos y gastos incluidos en los turnos cerrados.')}
          </p>
        </div>
      )}
      {error && (
        <div className="chart-data-indicator w-100 h-100 d-flex justify-content-center align-items-center flex-column">
          <p className="text-capitalize-first">
            {I18n.get('shiftsLoadError', 'ocurrió un error cargando el historial de turnos de la terminal')}
          </p>
        </div>
      )}
      {(!can('index', 'pos-cashier') || !can('view', 'pos-cashier')) && (
        <div className="chart-data-indicator w-100 h-100 d-flex justify-content-center align-items-center">
          <p className="text-capitalize-first">
            {I18n.get('userNotAllowed.pos-cashier.view', 'no tienes permiso para ver los cierres de turno anteriores')}
          </p>
        </div>
      )}
    </div>
  )
}

export default ShiftsCharts;